/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
(function(global, factory) { /* global define, require, module */

    /* AMD */ if (typeof define === 'function' && define.amd)
        define(["protobufjs/minimal"], factory);

    /* CommonJS */ else if (typeof require === 'function' && typeof module === 'object' && module && module.exports)
        module.exports = factory(require("protobufjs/minimal"));

})(this, function($protobuf) {
    "use strict";

    // Common aliases
    var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;
    
    // Exported root namespace
    var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});
    
    $root.PagingAndSort = (function() {
    
        /**
         * Properties of a PagingAndSort.
         * @exports IPagingAndSort
         * @interface IPagingAndSort
         * @property {number|null} [pageSize] PagingAndSort pageSize
         * @property {number|null} [pageIndex] PagingAndSort pageIndex
         * @property {string|null} [sortColumn] PagingAndSort sortColumn
         * @property {string|null} [sortDirection] PagingAndSort sortDirection
         * @property {string|null} [searchQuery] PagingAndSort searchQuery
         */
    
        /**
         * Constructs a new PagingAndSort.
         * @exports PagingAndSort
         * @classdesc Represents a PagingAndSort.
         * @implements IPagingAndSort
         * @constructor
         * @param {IPagingAndSort=} [properties] Properties to set
         */
        function PagingAndSort(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * PagingAndSort pageSize.
         * @member {number} pageSize
         * @memberof PagingAndSort
         * @instance
         */
        PagingAndSort.prototype.pageSize = 0;
    
        /**
         * PagingAndSort pageIndex.
         * @member {number} pageIndex
         * @memberof PagingAndSort
         * @instance
         */
        PagingAndSort.prototype.pageIndex = 0;
    
        /**
         * PagingAndSort sortColumn.
         * @member {string} sortColumn
         * @memberof PagingAndSort
         * @instance
         */
        PagingAndSort.prototype.sortColumn = "";
    
        /**
         * PagingAndSort sortDirection.
         * @member {string} sortDirection
         * @memberof PagingAndSort
         * @instance
         */
        PagingAndSort.prototype.sortDirection = "";
    
        /**
         * PagingAndSort searchQuery.
         * @member {string} searchQuery
         * @memberof PagingAndSort
         * @instance
         */
        PagingAndSort.prototype.searchQuery = "";
    
        /**
         * Creates a new PagingAndSort instance using the specified properties.
         * @function create
         * @memberof PagingAndSort
         * @static
         * @param {IPagingAndSort=} [properties] Properties to set
         * @returns {PagingAndSort} PagingAndSort instance
         */
        PagingAndSort.create = function create(properties) {
            return new PagingAndSort(properties);
        };
    
        /**
         * Encodes the specified PagingAndSort message. Does not implicitly {@link PagingAndSort.verify|verify} messages.
         * @function encode
         * @memberof PagingAndSort
         * @static
         * @param {IPagingAndSort} message PagingAndSort message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PagingAndSort.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.pageSize != null && Object.hasOwnProperty.call(message, "pageSize"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.pageSize);
            if (message.pageIndex != null && Object.hasOwnProperty.call(message, "pageIndex"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.pageIndex);
            if (message.sortColumn != null && Object.hasOwnProperty.call(message, "sortColumn"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.sortColumn);
            if (message.sortDirection != null && Object.hasOwnProperty.call(message, "sortDirection"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.sortDirection);
            if (message.searchQuery != null && Object.hasOwnProperty.call(message, "searchQuery"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.searchQuery);
            return writer;
        };
    
        /**
         * Encodes the specified PagingAndSort message, length delimited. Does not implicitly {@link PagingAndSort.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PagingAndSort
         * @static
         * @param {IPagingAndSort} message PagingAndSort message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PagingAndSort.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a PagingAndSort message from the specified reader or buffer.
         * @function decode
         * @memberof PagingAndSort
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PagingAndSort} PagingAndSort
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PagingAndSort.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PagingAndSort();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.pageSize = reader.int32();
                    break;
                case 2:
                    message.pageIndex = reader.int32();
                    break;
                case 3:
                    message.sortColumn = reader.string();
                    break;
                case 4:
                    message.sortDirection = reader.string();
                    break;
                case 5:
                    message.searchQuery = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a PagingAndSort message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PagingAndSort
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PagingAndSort} PagingAndSort
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PagingAndSort.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a PagingAndSort message.
         * @function verify
         * @memberof PagingAndSort
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PagingAndSort.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                if (!$util.isInteger(message.pageSize))
                    return "pageSize: integer expected";
            if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                if (!$util.isInteger(message.pageIndex))
                    return "pageIndex: integer expected";
            if (message.sortColumn != null && message.hasOwnProperty("sortColumn"))
                if (!$util.isString(message.sortColumn))
                    return "sortColumn: string expected";
            if (message.sortDirection != null && message.hasOwnProperty("sortDirection"))
                if (!$util.isString(message.sortDirection))
                    return "sortDirection: string expected";
            if (message.searchQuery != null && message.hasOwnProperty("searchQuery"))
                if (!$util.isString(message.searchQuery))
                    return "searchQuery: string expected";
            return null;
        };
    
        /**
         * Creates a PagingAndSort message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PagingAndSort
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PagingAndSort} PagingAndSort
         */
        PagingAndSort.fromObject = function fromObject(object) {
            if (object instanceof $root.PagingAndSort)
                return object;
            var message = new $root.PagingAndSort();
            if (object.pageSize != null)
                message.pageSize = object.pageSize | 0;
            if (object.pageIndex != null)
                message.pageIndex = object.pageIndex | 0;
            if (object.sortColumn != null)
                message.sortColumn = String(object.sortColumn);
            if (object.sortDirection != null)
                message.sortDirection = String(object.sortDirection);
            if (object.searchQuery != null)
                message.searchQuery = String(object.searchQuery);
            return message;
        };
    
        /**
         * Creates a plain object from a PagingAndSort message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PagingAndSort
         * @static
         * @param {PagingAndSort} message PagingAndSort
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PagingAndSort.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.pageSize = 0;
                object.pageIndex = 0;
                object.sortColumn = "";
                object.sortDirection = "";
                object.searchQuery = "";
            }
            if (message.pageSize != null && message.hasOwnProperty("pageSize"))
                object.pageSize = message.pageSize;
            if (message.pageIndex != null && message.hasOwnProperty("pageIndex"))
                object.pageIndex = message.pageIndex;
            if (message.sortColumn != null && message.hasOwnProperty("sortColumn"))
                object.sortColumn = message.sortColumn;
            if (message.sortDirection != null && message.hasOwnProperty("sortDirection"))
                object.sortDirection = message.sortDirection;
            if (message.searchQuery != null && message.hasOwnProperty("searchQuery"))
                object.searchQuery = message.searchQuery;
            return object;
        };
    
        /**
         * Converts this PagingAndSort to JSON.
         * @function toJSON
         * @memberof PagingAndSort
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PagingAndSort.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return PagingAndSort;
    })();
    
    $root.CurrencyForm = (function() {
    
        /**
         * Properties of a CurrencyForm.
         * @exports ICurrencyForm
         * @interface ICurrencyForm
         * @property {Uint8Array|null} [id] CurrencyForm id
         * @property {string|null} [code] CurrencyForm code
         * @property {string|null} [name] CurrencyForm name
         */
    
        /**
         * Constructs a new CurrencyForm.
         * @exports CurrencyForm
         * @classdesc Represents a CurrencyForm.
         * @implements ICurrencyForm
         * @constructor
         * @param {ICurrencyForm=} [properties] Properties to set
         */
        function CurrencyForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CurrencyForm id.
         * @member {Uint8Array} id
         * @memberof CurrencyForm
         * @instance
         */
        CurrencyForm.prototype.id = $util.newBuffer([]);
    
        /**
         * CurrencyForm code.
         * @member {string} code
         * @memberof CurrencyForm
         * @instance
         */
        CurrencyForm.prototype.code = "";
    
        /**
         * CurrencyForm name.
         * @member {string} name
         * @memberof CurrencyForm
         * @instance
         */
        CurrencyForm.prototype.name = "";
    
        /**
         * Creates a new CurrencyForm instance using the specified properties.
         * @function create
         * @memberof CurrencyForm
         * @static
         * @param {ICurrencyForm=} [properties] Properties to set
         * @returns {CurrencyForm} CurrencyForm instance
         */
        CurrencyForm.create = function create(properties) {
            return new CurrencyForm(properties);
        };
    
        /**
         * Encodes the specified CurrencyForm message. Does not implicitly {@link CurrencyForm.verify|verify} messages.
         * @function encode
         * @memberof CurrencyForm
         * @static
         * @param {ICurrencyForm} message CurrencyForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrencyForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified CurrencyForm message, length delimited. Does not implicitly {@link CurrencyForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CurrencyForm
         * @static
         * @param {ICurrencyForm} message CurrencyForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrencyForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CurrencyForm message from the specified reader or buffer.
         * @function decode
         * @memberof CurrencyForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CurrencyForm} CurrencyForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrencyForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CurrencyForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CurrencyForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CurrencyForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CurrencyForm} CurrencyForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrencyForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CurrencyForm message.
         * @function verify
         * @memberof CurrencyForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CurrencyForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a CurrencyForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CurrencyForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CurrencyForm} CurrencyForm
         */
        CurrencyForm.fromObject = function fromObject(object) {
            if (object instanceof $root.CurrencyForm)
                return object;
            var message = new $root.CurrencyForm();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.code != null)
                message.code = String(object.code);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a CurrencyForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CurrencyForm
         * @static
         * @param {CurrencyForm} message CurrencyForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CurrencyForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.code = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this CurrencyForm to JSON.
         * @function toJSON
         * @memberof CurrencyForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CurrencyForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CurrencyForm;
    })();
    
    $root.CurrencyList = (function() {
    
        /**
         * Properties of a CurrencyList.
         * @exports ICurrencyList
         * @interface ICurrencyList
         * @property {Uint8Array|null} [id] CurrencyList id
         * @property {string|null} [code] CurrencyList code
         * @property {string|null} [name] CurrencyList name
         */
    
        /**
         * Constructs a new CurrencyList.
         * @exports CurrencyList
         * @classdesc Represents a CurrencyList.
         * @implements ICurrencyList
         * @constructor
         * @param {ICurrencyList=} [properties] Properties to set
         */
        function CurrencyList(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CurrencyList id.
         * @member {Uint8Array} id
         * @memberof CurrencyList
         * @instance
         */
        CurrencyList.prototype.id = $util.newBuffer([]);
    
        /**
         * CurrencyList code.
         * @member {string} code
         * @memberof CurrencyList
         * @instance
         */
        CurrencyList.prototype.code = "";
    
        /**
         * CurrencyList name.
         * @member {string} name
         * @memberof CurrencyList
         * @instance
         */
        CurrencyList.prototype.name = "";
    
        /**
         * Creates a new CurrencyList instance using the specified properties.
         * @function create
         * @memberof CurrencyList
         * @static
         * @param {ICurrencyList=} [properties] Properties to set
         * @returns {CurrencyList} CurrencyList instance
         */
        CurrencyList.create = function create(properties) {
            return new CurrencyList(properties);
        };
    
        /**
         * Encodes the specified CurrencyList message. Does not implicitly {@link CurrencyList.verify|verify} messages.
         * @function encode
         * @memberof CurrencyList
         * @static
         * @param {ICurrencyList} message CurrencyList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrencyList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified CurrencyList message, length delimited. Does not implicitly {@link CurrencyList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CurrencyList
         * @static
         * @param {ICurrencyList} message CurrencyList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CurrencyList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CurrencyList message from the specified reader or buffer.
         * @function decode
         * @memberof CurrencyList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CurrencyList} CurrencyList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrencyList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CurrencyList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                case 3:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CurrencyList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CurrencyList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CurrencyList} CurrencyList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CurrencyList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CurrencyList message.
         * @function verify
         * @memberof CurrencyList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CurrencyList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a CurrencyList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CurrencyList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CurrencyList} CurrencyList
         */
        CurrencyList.fromObject = function fromObject(object) {
            if (object instanceof $root.CurrencyList)
                return object;
            var message = new $root.CurrencyList();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.code != null)
                message.code = String(object.code);
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a CurrencyList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CurrencyList
         * @static
         * @param {CurrencyList} message CurrencyList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CurrencyList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.code = "";
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this CurrencyList to JSON.
         * @function toJSON
         * @memberof CurrencyList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CurrencyList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CurrencyList;
    })();
    
    $root.Currencies = (function() {
    
        /**
         * Properties of a Currencies.
         * @exports ICurrencies
         * @interface ICurrencies
         * @property {number|null} [rowCount] Currencies rowCount
         * @property {Array.<ICurrencyList>|null} [items] Currencies items
         */
    
        /**
         * Constructs a new Currencies.
         * @exports Currencies
         * @classdesc Represents a Currencies.
         * @implements ICurrencies
         * @constructor
         * @param {ICurrencies=} [properties] Properties to set
         */
        function Currencies(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Currencies rowCount.
         * @member {number} rowCount
         * @memberof Currencies
         * @instance
         */
        Currencies.prototype.rowCount = 0;
    
        /**
         * Currencies items.
         * @member {Array.<ICurrencyList>} items
         * @memberof Currencies
         * @instance
         */
        Currencies.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Currencies instance using the specified properties.
         * @function create
         * @memberof Currencies
         * @static
         * @param {ICurrencies=} [properties] Properties to set
         * @returns {Currencies} Currencies instance
         */
        Currencies.create = function create(properties) {
            return new Currencies(properties);
        };
    
        /**
         * Encodes the specified Currencies message. Does not implicitly {@link Currencies.verify|verify} messages.
         * @function encode
         * @memberof Currencies
         * @static
         * @param {ICurrencies} message Currencies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Currencies.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.CurrencyList.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Currencies message, length delimited. Does not implicitly {@link Currencies.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Currencies
         * @static
         * @param {ICurrencies} message Currencies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Currencies.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Currencies message from the specified reader or buffer.
         * @function decode
         * @memberof Currencies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Currencies} Currencies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Currencies.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Currencies();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.CurrencyList.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Currencies message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Currencies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Currencies} Currencies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Currencies.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Currencies message.
         * @function verify
         * @memberof Currencies
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Currencies.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.CurrencyList.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Currencies message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Currencies
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Currencies} Currencies
         */
        Currencies.fromObject = function fromObject(object) {
            if (object instanceof $root.Currencies)
                return object;
            var message = new $root.Currencies();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Currencies.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Currencies.items: object expected");
                    message.items[i] = $root.CurrencyList.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Currencies message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Currencies
         * @static
         * @param {Currencies} message Currencies
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Currencies.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.CurrencyList.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Currencies to JSON.
         * @function toJSON
         * @memberof Currencies
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Currencies.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Currencies;
    })();
    
    $root.CountryCallingCode = (function() {
    
        /**
         * Properties of a CountryCallingCode.
         * @exports ICountryCallingCode
         * @interface ICountryCallingCode
         * @property {Uint8Array|null} [id] CountryCallingCode id
         * @property {string|null} [callingCode] CountryCallingCode callingCode
         * @property {Uint8Array|null} [countryNameId] CountryCallingCode countryNameId
         * @property {string|null} [countryName] CountryCallingCode countryName
         */
    
        /**
         * Constructs a new CountryCallingCode.
         * @exports CountryCallingCode
         * @classdesc Represents a CountryCallingCode.
         * @implements ICountryCallingCode
         * @constructor
         * @param {ICountryCallingCode=} [properties] Properties to set
         */
        function CountryCallingCode(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CountryCallingCode id.
         * @member {Uint8Array} id
         * @memberof CountryCallingCode
         * @instance
         */
        CountryCallingCode.prototype.id = $util.newBuffer([]);
    
        /**
         * CountryCallingCode callingCode.
         * @member {string} callingCode
         * @memberof CountryCallingCode
         * @instance
         */
        CountryCallingCode.prototype.callingCode = "";
    
        /**
         * CountryCallingCode countryNameId.
         * @member {Uint8Array} countryNameId
         * @memberof CountryCallingCode
         * @instance
         */
        CountryCallingCode.prototype.countryNameId = $util.newBuffer([]);
    
        /**
         * CountryCallingCode countryName.
         * @member {string} countryName
         * @memberof CountryCallingCode
         * @instance
         */
        CountryCallingCode.prototype.countryName = "";
    
        /**
         * Creates a new CountryCallingCode instance using the specified properties.
         * @function create
         * @memberof CountryCallingCode
         * @static
         * @param {ICountryCallingCode=} [properties] Properties to set
         * @returns {CountryCallingCode} CountryCallingCode instance
         */
        CountryCallingCode.create = function create(properties) {
            return new CountryCallingCode(properties);
        };
    
        /**
         * Encodes the specified CountryCallingCode message. Does not implicitly {@link CountryCallingCode.verify|verify} messages.
         * @function encode
         * @memberof CountryCallingCode
         * @static
         * @param {ICountryCallingCode} message CountryCallingCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountryCallingCode.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.callingCode != null && Object.hasOwnProperty.call(message, "callingCode"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.callingCode);
            if (message.countryNameId != null && Object.hasOwnProperty.call(message, "countryNameId"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.countryNameId);
            if (message.countryName != null && Object.hasOwnProperty.call(message, "countryName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.countryName);
            return writer;
        };
    
        /**
         * Encodes the specified CountryCallingCode message, length delimited. Does not implicitly {@link CountryCallingCode.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CountryCallingCode
         * @static
         * @param {ICountryCallingCode} message CountryCallingCode message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountryCallingCode.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CountryCallingCode message from the specified reader or buffer.
         * @function decode
         * @memberof CountryCallingCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CountryCallingCode} CountryCallingCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountryCallingCode.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CountryCallingCode();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.callingCode = reader.string();
                    break;
                case 3:
                    message.countryNameId = reader.bytes();
                    break;
                case 4:
                    message.countryName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CountryCallingCode message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CountryCallingCode
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CountryCallingCode} CountryCallingCode
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountryCallingCode.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CountryCallingCode message.
         * @function verify
         * @memberof CountryCallingCode
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CountryCallingCode.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                if (!$util.isString(message.callingCode))
                    return "callingCode: string expected";
            if (message.countryNameId != null && message.hasOwnProperty("countryNameId"))
                if (!(message.countryNameId && typeof message.countryNameId.length === "number" || $util.isString(message.countryNameId)))
                    return "countryNameId: buffer expected";
            if (message.countryName != null && message.hasOwnProperty("countryName"))
                if (!$util.isString(message.countryName))
                    return "countryName: string expected";
            return null;
        };
    
        /**
         * Creates a CountryCallingCode message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CountryCallingCode
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CountryCallingCode} CountryCallingCode
         */
        CountryCallingCode.fromObject = function fromObject(object) {
            if (object instanceof $root.CountryCallingCode)
                return object;
            var message = new $root.CountryCallingCode();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.callingCode != null)
                message.callingCode = String(object.callingCode);
            if (object.countryNameId != null)
                if (typeof object.countryNameId === "string")
                    $util.base64.decode(object.countryNameId, message.countryNameId = $util.newBuffer($util.base64.length(object.countryNameId)), 0);
                else if (object.countryNameId.length)
                    message.countryNameId = object.countryNameId;
            if (object.countryName != null)
                message.countryName = String(object.countryName);
            return message;
        };
    
        /**
         * Creates a plain object from a CountryCallingCode message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CountryCallingCode
         * @static
         * @param {CountryCallingCode} message CountryCallingCode
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CountryCallingCode.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.callingCode = "";
                if (options.bytes === String)
                    object.countryNameId = "";
                else {
                    object.countryNameId = [];
                    if (options.bytes !== Array)
                        object.countryNameId = $util.newBuffer(object.countryNameId);
                }
                object.countryName = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.callingCode != null && message.hasOwnProperty("callingCode"))
                object.callingCode = message.callingCode;
            if (message.countryNameId != null && message.hasOwnProperty("countryNameId"))
                object.countryNameId = options.bytes === String ? $util.base64.encode(message.countryNameId, 0, message.countryNameId.length) : options.bytes === Array ? Array.prototype.slice.call(message.countryNameId) : message.countryNameId;
            if (message.countryName != null && message.hasOwnProperty("countryName"))
                object.countryName = message.countryName;
            return object;
        };
    
        /**
         * Converts this CountryCallingCode to JSON.
         * @function toJSON
         * @memberof CountryCallingCode
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CountryCallingCode.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CountryCallingCode;
    })();
    
    $root.CountryCallingCodes = (function() {
    
        /**
         * Properties of a CountryCallingCodes.
         * @exports ICountryCallingCodes
         * @interface ICountryCallingCodes
         * @property {number|null} [rowCount] CountryCallingCodes rowCount
         * @property {Array.<ICountryCallingCode>|null} [items] CountryCallingCodes items
         */
    
        /**
         * Constructs a new CountryCallingCodes.
         * @exports CountryCallingCodes
         * @classdesc Represents a CountryCallingCodes.
         * @implements ICountryCallingCodes
         * @constructor
         * @param {ICountryCallingCodes=} [properties] Properties to set
         */
        function CountryCallingCodes(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CountryCallingCodes rowCount.
         * @member {number} rowCount
         * @memberof CountryCallingCodes
         * @instance
         */
        CountryCallingCodes.prototype.rowCount = 0;
    
        /**
         * CountryCallingCodes items.
         * @member {Array.<ICountryCallingCode>} items
         * @memberof CountryCallingCodes
         * @instance
         */
        CountryCallingCodes.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new CountryCallingCodes instance using the specified properties.
         * @function create
         * @memberof CountryCallingCodes
         * @static
         * @param {ICountryCallingCodes=} [properties] Properties to set
         * @returns {CountryCallingCodes} CountryCallingCodes instance
         */
        CountryCallingCodes.create = function create(properties) {
            return new CountryCallingCodes(properties);
        };
    
        /**
         * Encodes the specified CountryCallingCodes message. Does not implicitly {@link CountryCallingCodes.verify|verify} messages.
         * @function encode
         * @memberof CountryCallingCodes
         * @static
         * @param {ICountryCallingCodes} message CountryCallingCodes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountryCallingCodes.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.CountryCallingCode.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified CountryCallingCodes message, length delimited. Does not implicitly {@link CountryCallingCodes.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CountryCallingCodes
         * @static
         * @param {ICountryCallingCodes} message CountryCallingCodes message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CountryCallingCodes.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CountryCallingCodes message from the specified reader or buffer.
         * @function decode
         * @memberof CountryCallingCodes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CountryCallingCodes} CountryCallingCodes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountryCallingCodes.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CountryCallingCodes();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.CountryCallingCode.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CountryCallingCodes message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CountryCallingCodes
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CountryCallingCodes} CountryCallingCodes
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CountryCallingCodes.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CountryCallingCodes message.
         * @function verify
         * @memberof CountryCallingCodes
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CountryCallingCodes.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.CountryCallingCode.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a CountryCallingCodes message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CountryCallingCodes
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CountryCallingCodes} CountryCallingCodes
         */
        CountryCallingCodes.fromObject = function fromObject(object) {
            if (object instanceof $root.CountryCallingCodes)
                return object;
            var message = new $root.CountryCallingCodes();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".CountryCallingCodes.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".CountryCallingCodes.items: object expected");
                    message.items[i] = $root.CountryCallingCode.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a CountryCallingCodes message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CountryCallingCodes
         * @static
         * @param {CountryCallingCodes} message CountryCallingCodes
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CountryCallingCodes.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.CountryCallingCode.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this CountryCallingCodes to JSON.
         * @function toJSON
         * @memberof CountryCallingCodes
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CountryCallingCodes.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CountryCallingCodes;
    })();
    
    $root.LanguageForm = (function() {
    
        /**
         * Properties of a LanguageForm.
         * @exports ILanguageForm
         * @interface ILanguageForm
         * @property {Uint8Array|null} [id] LanguageForm id
         * @property {string|null} [internationalName] LanguageForm internationalName
         * @property {string|null} [nativeName] LanguageForm nativeName
         * @property {string|null} [code] LanguageForm code
         */
    
        /**
         * Constructs a new LanguageForm.
         * @exports LanguageForm
         * @classdesc Represents a LanguageForm.
         * @implements ILanguageForm
         * @constructor
         * @param {ILanguageForm=} [properties] Properties to set
         */
        function LanguageForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * LanguageForm id.
         * @member {Uint8Array} id
         * @memberof LanguageForm
         * @instance
         */
        LanguageForm.prototype.id = $util.newBuffer([]);
    
        /**
         * LanguageForm internationalName.
         * @member {string} internationalName
         * @memberof LanguageForm
         * @instance
         */
        LanguageForm.prototype.internationalName = "";
    
        /**
         * LanguageForm nativeName.
         * @member {string} nativeName
         * @memberof LanguageForm
         * @instance
         */
        LanguageForm.prototype.nativeName = "";
    
        /**
         * LanguageForm code.
         * @member {string} code
         * @memberof LanguageForm
         * @instance
         */
        LanguageForm.prototype.code = "";
    
        /**
         * Creates a new LanguageForm instance using the specified properties.
         * @function create
         * @memberof LanguageForm
         * @static
         * @param {ILanguageForm=} [properties] Properties to set
         * @returns {LanguageForm} LanguageForm instance
         */
        LanguageForm.create = function create(properties) {
            return new LanguageForm(properties);
        };
    
        /**
         * Encodes the specified LanguageForm message. Does not implicitly {@link LanguageForm.verify|verify} messages.
         * @function encode
         * @memberof LanguageForm
         * @static
         * @param {ILanguageForm} message LanguageForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LanguageForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.internationalName != null && Object.hasOwnProperty.call(message, "internationalName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.internationalName);
            if (message.nativeName != null && Object.hasOwnProperty.call(message, "nativeName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.nativeName);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.code);
            return writer;
        };
    
        /**
         * Encodes the specified LanguageForm message, length delimited. Does not implicitly {@link LanguageForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof LanguageForm
         * @static
         * @param {ILanguageForm} message LanguageForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LanguageForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a LanguageForm message from the specified reader or buffer.
         * @function decode
         * @memberof LanguageForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {LanguageForm} LanguageForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LanguageForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.LanguageForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.internationalName = reader.string();
                    break;
                case 3:
                    message.nativeName = reader.string();
                    break;
                case 4:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a LanguageForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof LanguageForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {LanguageForm} LanguageForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LanguageForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a LanguageForm message.
         * @function verify
         * @memberof LanguageForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LanguageForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.internationalName != null && message.hasOwnProperty("internationalName"))
                if (!$util.isString(message.internationalName))
                    return "internationalName: string expected";
            if (message.nativeName != null && message.hasOwnProperty("nativeName"))
                if (!$util.isString(message.nativeName))
                    return "nativeName: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };
    
        /**
         * Creates a LanguageForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof LanguageForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {LanguageForm} LanguageForm
         */
        LanguageForm.fromObject = function fromObject(object) {
            if (object instanceof $root.LanguageForm)
                return object;
            var message = new $root.LanguageForm();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.internationalName != null)
                message.internationalName = String(object.internationalName);
            if (object.nativeName != null)
                message.nativeName = String(object.nativeName);
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };
    
        /**
         * Creates a plain object from a LanguageForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof LanguageForm
         * @static
         * @param {LanguageForm} message LanguageForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LanguageForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.internationalName = "";
                object.nativeName = "";
                object.code = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.internationalName != null && message.hasOwnProperty("internationalName"))
                object.internationalName = message.internationalName;
            if (message.nativeName != null && message.hasOwnProperty("nativeName"))
                object.nativeName = message.nativeName;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };
    
        /**
         * Converts this LanguageForm to JSON.
         * @function toJSON
         * @memberof LanguageForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LanguageForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return LanguageForm;
    })();
    
    $root.LanguageList = (function() {
    
        /**
         * Properties of a LanguageList.
         * @exports ILanguageList
         * @interface ILanguageList
         * @property {Uint8Array|null} [id] LanguageList id
         * @property {string|null} [internationalName] LanguageList internationalName
         * @property {string|null} [nativeName] LanguageList nativeName
         * @property {string|null} [code] LanguageList code
         */
    
        /**
         * Constructs a new LanguageList.
         * @exports LanguageList
         * @classdesc Represents a LanguageList.
         * @implements ILanguageList
         * @constructor
         * @param {ILanguageList=} [properties] Properties to set
         */
        function LanguageList(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * LanguageList id.
         * @member {Uint8Array} id
         * @memberof LanguageList
         * @instance
         */
        LanguageList.prototype.id = $util.newBuffer([]);
    
        /**
         * LanguageList internationalName.
         * @member {string} internationalName
         * @memberof LanguageList
         * @instance
         */
        LanguageList.prototype.internationalName = "";
    
        /**
         * LanguageList nativeName.
         * @member {string} nativeName
         * @memberof LanguageList
         * @instance
         */
        LanguageList.prototype.nativeName = "";
    
        /**
         * LanguageList code.
         * @member {string} code
         * @memberof LanguageList
         * @instance
         */
        LanguageList.prototype.code = "";
    
        /**
         * Creates a new LanguageList instance using the specified properties.
         * @function create
         * @memberof LanguageList
         * @static
         * @param {ILanguageList=} [properties] Properties to set
         * @returns {LanguageList} LanguageList instance
         */
        LanguageList.create = function create(properties) {
            return new LanguageList(properties);
        };
    
        /**
         * Encodes the specified LanguageList message. Does not implicitly {@link LanguageList.verify|verify} messages.
         * @function encode
         * @memberof LanguageList
         * @static
         * @param {ILanguageList} message LanguageList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LanguageList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.internationalName != null && Object.hasOwnProperty.call(message, "internationalName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.internationalName);
            if (message.nativeName != null && Object.hasOwnProperty.call(message, "nativeName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.nativeName);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.code);
            return writer;
        };
    
        /**
         * Encodes the specified LanguageList message, length delimited. Does not implicitly {@link LanguageList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof LanguageList
         * @static
         * @param {ILanguageList} message LanguageList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LanguageList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a LanguageList message from the specified reader or buffer.
         * @function decode
         * @memberof LanguageList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {LanguageList} LanguageList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LanguageList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.LanguageList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.internationalName = reader.string();
                    break;
                case 3:
                    message.nativeName = reader.string();
                    break;
                case 4:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a LanguageList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof LanguageList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {LanguageList} LanguageList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LanguageList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a LanguageList message.
         * @function verify
         * @memberof LanguageList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        LanguageList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.internationalName != null && message.hasOwnProperty("internationalName"))
                if (!$util.isString(message.internationalName))
                    return "internationalName: string expected";
            if (message.nativeName != null && message.hasOwnProperty("nativeName"))
                if (!$util.isString(message.nativeName))
                    return "nativeName: string expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };
    
        /**
         * Creates a LanguageList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof LanguageList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {LanguageList} LanguageList
         */
        LanguageList.fromObject = function fromObject(object) {
            if (object instanceof $root.LanguageList)
                return object;
            var message = new $root.LanguageList();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.internationalName != null)
                message.internationalName = String(object.internationalName);
            if (object.nativeName != null)
                message.nativeName = String(object.nativeName);
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };
    
        /**
         * Creates a plain object from a LanguageList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof LanguageList
         * @static
         * @param {LanguageList} message LanguageList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        LanguageList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.internationalName = "";
                object.nativeName = "";
                object.code = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.internationalName != null && message.hasOwnProperty("internationalName"))
                object.internationalName = message.internationalName;
            if (message.nativeName != null && message.hasOwnProperty("nativeName"))
                object.nativeName = message.nativeName;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };
    
        /**
         * Converts this LanguageList to JSON.
         * @function toJSON
         * @memberof LanguageList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        LanguageList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return LanguageList;
    })();
    
    $root.Languages = (function() {
    
        /**
         * Properties of a Languages.
         * @exports ILanguages
         * @interface ILanguages
         * @property {number|null} [rowCount] Languages rowCount
         * @property {Array.<ILanguageList>|null} [items] Languages items
         */
    
        /**
         * Constructs a new Languages.
         * @exports Languages
         * @classdesc Represents a Languages.
         * @implements ILanguages
         * @constructor
         * @param {ILanguages=} [properties] Properties to set
         */
        function Languages(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Languages rowCount.
         * @member {number} rowCount
         * @memberof Languages
         * @instance
         */
        Languages.prototype.rowCount = 0;
    
        /**
         * Languages items.
         * @member {Array.<ILanguageList>} items
         * @memberof Languages
         * @instance
         */
        Languages.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Languages instance using the specified properties.
         * @function create
         * @memberof Languages
         * @static
         * @param {ILanguages=} [properties] Properties to set
         * @returns {Languages} Languages instance
         */
        Languages.create = function create(properties) {
            return new Languages(properties);
        };
    
        /**
         * Encodes the specified Languages message. Does not implicitly {@link Languages.verify|verify} messages.
         * @function encode
         * @memberof Languages
         * @static
         * @param {ILanguages} message Languages message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Languages.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.LanguageList.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Languages message, length delimited. Does not implicitly {@link Languages.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Languages
         * @static
         * @param {ILanguages} message Languages message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Languages.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Languages message from the specified reader or buffer.
         * @function decode
         * @memberof Languages
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Languages} Languages
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Languages.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Languages();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.LanguageList.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Languages message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Languages
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Languages} Languages
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Languages.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Languages message.
         * @function verify
         * @memberof Languages
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Languages.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.LanguageList.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Languages message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Languages
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Languages} Languages
         */
        Languages.fromObject = function fromObject(object) {
            if (object instanceof $root.Languages)
                return object;
            var message = new $root.Languages();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Languages.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Languages.items: object expected");
                    message.items[i] = $root.LanguageList.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Languages message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Languages
         * @static
         * @param {Languages} message Languages
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Languages.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.LanguageList.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Languages to JSON.
         * @function toJSON
         * @memberof Languages
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Languages.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Languages;
    })();
    
    $root.CategoryForm = (function() {
    
        /**
         * Properties of a CategoryForm.
         * @exports ICategoryForm
         * @interface ICategoryForm
         * @property {Uint8Array|null} [id] CategoryForm id
         * @property {string|null} [name] CategoryForm name
         * @property {string|null} [description] CategoryForm description
         */
    
        /**
         * Constructs a new CategoryForm.
         * @exports CategoryForm
         * @classdesc Represents a CategoryForm.
         * @implements ICategoryForm
         * @constructor
         * @param {ICategoryForm=} [properties] Properties to set
         */
        function CategoryForm(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CategoryForm id.
         * @member {Uint8Array} id
         * @memberof CategoryForm
         * @instance
         */
        CategoryForm.prototype.id = $util.newBuffer([]);
    
        /**
         * CategoryForm name.
         * @member {string} name
         * @memberof CategoryForm
         * @instance
         */
        CategoryForm.prototype.name = "";
    
        /**
         * CategoryForm description.
         * @member {string} description
         * @memberof CategoryForm
         * @instance
         */
        CategoryForm.prototype.description = "";
    
        /**
         * Creates a new CategoryForm instance using the specified properties.
         * @function create
         * @memberof CategoryForm
         * @static
         * @param {ICategoryForm=} [properties] Properties to set
         * @returns {CategoryForm} CategoryForm instance
         */
        CategoryForm.create = function create(properties) {
            return new CategoryForm(properties);
        };
    
        /**
         * Encodes the specified CategoryForm message. Does not implicitly {@link CategoryForm.verify|verify} messages.
         * @function encode
         * @memberof CategoryForm
         * @static
         * @param {ICategoryForm} message CategoryForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategoryForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            return writer;
        };
    
        /**
         * Encodes the specified CategoryForm message, length delimited. Does not implicitly {@link CategoryForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CategoryForm
         * @static
         * @param {ICategoryForm} message CategoryForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategoryForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CategoryForm message from the specified reader or buffer.
         * @function decode
         * @memberof CategoryForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CategoryForm} CategoryForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategoryForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CategoryForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CategoryForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CategoryForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CategoryForm} CategoryForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategoryForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CategoryForm message.
         * @function verify
         * @memberof CategoryForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CategoryForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };
    
        /**
         * Creates a CategoryForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CategoryForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CategoryForm} CategoryForm
         */
        CategoryForm.fromObject = function fromObject(object) {
            if (object instanceof $root.CategoryForm)
                return object;
            var message = new $root.CategoryForm();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };
    
        /**
         * Creates a plain object from a CategoryForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CategoryForm
         * @static
         * @param {CategoryForm} message CategoryForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CategoryForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
                object.description = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };
    
        /**
         * Converts this CategoryForm to JSON.
         * @function toJSON
         * @memberof CategoryForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CategoryForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CategoryForm;
    })();
    
    $root.CategoryList = (function() {
    
        /**
         * Properties of a CategoryList.
         * @exports ICategoryList
         * @interface ICategoryList
         * @property {Uint8Array|null} [id] CategoryList id
         * @property {string|null} [name] CategoryList name
         * @property {string|null} [description] CategoryList description
         */
    
        /**
         * Constructs a new CategoryList.
         * @exports CategoryList
         * @classdesc Represents a CategoryList.
         * @implements ICategoryList
         * @constructor
         * @param {ICategoryList=} [properties] Properties to set
         */
        function CategoryList(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CategoryList id.
         * @member {Uint8Array} id
         * @memberof CategoryList
         * @instance
         */
        CategoryList.prototype.id = $util.newBuffer([]);
    
        /**
         * CategoryList name.
         * @member {string} name
         * @memberof CategoryList
         * @instance
         */
        CategoryList.prototype.name = "";
    
        /**
         * CategoryList description.
         * @member {string} description
         * @memberof CategoryList
         * @instance
         */
        CategoryList.prototype.description = "";
    
        /**
         * Creates a new CategoryList instance using the specified properties.
         * @function create
         * @memberof CategoryList
         * @static
         * @param {ICategoryList=} [properties] Properties to set
         * @returns {CategoryList} CategoryList instance
         */
        CategoryList.create = function create(properties) {
            return new CategoryList(properties);
        };
    
        /**
         * Encodes the specified CategoryList message. Does not implicitly {@link CategoryList.verify|verify} messages.
         * @function encode
         * @memberof CategoryList
         * @static
         * @param {ICategoryList} message CategoryList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategoryList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
            return writer;
        };
    
        /**
         * Encodes the specified CategoryList message, length delimited. Does not implicitly {@link CategoryList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CategoryList
         * @static
         * @param {ICategoryList} message CategoryList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategoryList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CategoryList message from the specified reader or buffer.
         * @function decode
         * @memberof CategoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CategoryList} CategoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategoryList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CategoryList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.description = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CategoryList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CategoryList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CategoryList} CategoryList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategoryList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CategoryList message.
         * @function verify
         * @memberof CategoryList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CategoryList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            return null;
        };
    
        /**
         * Creates a CategoryList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CategoryList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CategoryList} CategoryList
         */
        CategoryList.fromObject = function fromObject(object) {
            if (object instanceof $root.CategoryList)
                return object;
            var message = new $root.CategoryList();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            if (object.description != null)
                message.description = String(object.description);
            return message;
        };
    
        /**
         * Creates a plain object from a CategoryList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CategoryList
         * @static
         * @param {CategoryList} message CategoryList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CategoryList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
                object.description = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            return object;
        };
    
        /**
         * Converts this CategoryList to JSON.
         * @function toJSON
         * @memberof CategoryList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CategoryList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CategoryList;
    })();
    
    $root.Categories = (function() {
    
        /**
         * Properties of a Categories.
         * @exports ICategories
         * @interface ICategories
         * @property {number|null} [rowCount] Categories rowCount
         * @property {Array.<ICategoryList>|null} [items] Categories items
         */
    
        /**
         * Constructs a new Categories.
         * @exports Categories
         * @classdesc Represents a Categories.
         * @implements ICategories
         * @constructor
         * @param {ICategories=} [properties] Properties to set
         */
        function Categories(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Categories rowCount.
         * @member {number} rowCount
         * @memberof Categories
         * @instance
         */
        Categories.prototype.rowCount = 0;
    
        /**
         * Categories items.
         * @member {Array.<ICategoryList>} items
         * @memberof Categories
         * @instance
         */
        Categories.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Categories instance using the specified properties.
         * @function create
         * @memberof Categories
         * @static
         * @param {ICategories=} [properties] Properties to set
         * @returns {Categories} Categories instance
         */
        Categories.create = function create(properties) {
            return new Categories(properties);
        };
    
        /**
         * Encodes the specified Categories message. Does not implicitly {@link Categories.verify|verify} messages.
         * @function encode
         * @memberof Categories
         * @static
         * @param {ICategories} message Categories message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Categories.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.CategoryList.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Categories message, length delimited. Does not implicitly {@link Categories.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Categories
         * @static
         * @param {ICategories} message Categories message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Categories.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Categories message from the specified reader or buffer.
         * @function decode
         * @memberof Categories
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Categories} Categories
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Categories.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Categories();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.CategoryList.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Categories message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Categories
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Categories} Categories
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Categories.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Categories message.
         * @function verify
         * @memberof Categories
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Categories.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.CategoryList.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Categories message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Categories
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Categories} Categories
         */
        Categories.fromObject = function fromObject(object) {
            if (object instanceof $root.Categories)
                return object;
            var message = new $root.Categories();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Categories.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Categories.items: object expected");
                    message.items[i] = $root.CategoryList.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Categories message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Categories
         * @static
         * @param {Categories} message Categories
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Categories.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.CategoryList.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Categories to JSON.
         * @function toJSON
         * @memberof Categories
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Categories.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Categories;
    })();
    
    $root.CategoryValidation = (function() {
    
        /**
         * Properties of a CategoryValidation.
         * @exports ICategoryValidation
         * @interface ICategoryValidation
         * @property {string|null} [name] CategoryValidation name
         */
    
        /**
         * Constructs a new CategoryValidation.
         * @exports CategoryValidation
         * @classdesc Represents a CategoryValidation.
         * @implements ICategoryValidation
         * @constructor
         * @param {ICategoryValidation=} [properties] Properties to set
         */
        function CategoryValidation(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CategoryValidation name.
         * @member {string} name
         * @memberof CategoryValidation
         * @instance
         */
        CategoryValidation.prototype.name = "";
    
        /**
         * Creates a new CategoryValidation instance using the specified properties.
         * @function create
         * @memberof CategoryValidation
         * @static
         * @param {ICategoryValidation=} [properties] Properties to set
         * @returns {CategoryValidation} CategoryValidation instance
         */
        CategoryValidation.create = function create(properties) {
            return new CategoryValidation(properties);
        };
    
        /**
         * Encodes the specified CategoryValidation message. Does not implicitly {@link CategoryValidation.verify|verify} messages.
         * @function encode
         * @memberof CategoryValidation
         * @static
         * @param {ICategoryValidation} message CategoryValidation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategoryValidation.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified CategoryValidation message, length delimited. Does not implicitly {@link CategoryValidation.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CategoryValidation
         * @static
         * @param {ICategoryValidation} message CategoryValidation message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategoryValidation.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CategoryValidation message from the specified reader or buffer.
         * @function decode
         * @memberof CategoryValidation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CategoryValidation} CategoryValidation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategoryValidation.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CategoryValidation();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CategoryValidation message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CategoryValidation
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CategoryValidation} CategoryValidation
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategoryValidation.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CategoryValidation message.
         * @function verify
         * @memberof CategoryValidation
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CategoryValidation.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a CategoryValidation message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CategoryValidation
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CategoryValidation} CategoryValidation
         */
        CategoryValidation.fromObject = function fromObject(object) {
            if (object instanceof $root.CategoryValidation)
                return object;
            var message = new $root.CategoryValidation();
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a CategoryValidation message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CategoryValidation
         * @static
         * @param {CategoryValidation} message CategoryValidation
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CategoryValidation.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                object.name = "";
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this CategoryValidation to JSON.
         * @function toJSON
         * @memberof CategoryValidation
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CategoryValidation.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CategoryValidation;
    })();
    
    $root.NosologyForm = (function() {
    
        /**
         * Properties of a NosologyForm.
         * @exports INosologyForm
         * @interface INosologyForm
         * @property {Uint8Array|null} [id] NosologyForm id
         * @property {ICategorySearch|null} [category] NosologyForm category
         * @property {string|null} [nosology] NosologyForm nosology
         * @property {string|null} [errorMessage] NosologyForm errorMessage
         * @property {Array.<string>|null} [synonyms] NosologyForm synonyms
         */
    
        /**
         * Constructs a new NosologyForm.
         * @exports NosologyForm
         * @classdesc Represents a NosologyForm.
         * @implements INosologyForm
         * @constructor
         * @param {INosologyForm=} [properties] Properties to set
         */
        function NosologyForm(properties) {
            this.synonyms = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * NosologyForm id.
         * @member {Uint8Array} id
         * @memberof NosologyForm
         * @instance
         */
        NosologyForm.prototype.id = $util.newBuffer([]);
    
        /**
         * NosologyForm category.
         * @member {ICategorySearch|null|undefined} category
         * @memberof NosologyForm
         * @instance
         */
        NosologyForm.prototype.category = null;
    
        /**
         * NosologyForm nosology.
         * @member {string} nosology
         * @memberof NosologyForm
         * @instance
         */
        NosologyForm.prototype.nosology = "";
    
        /**
         * NosologyForm errorMessage.
         * @member {string} errorMessage
         * @memberof NosologyForm
         * @instance
         */
        NosologyForm.prototype.errorMessage = "";
    
        /**
         * NosologyForm synonyms.
         * @member {Array.<string>} synonyms
         * @memberof NosologyForm
         * @instance
         */
        NosologyForm.prototype.synonyms = $util.emptyArray;
    
        /**
         * Creates a new NosologyForm instance using the specified properties.
         * @function create
         * @memberof NosologyForm
         * @static
         * @param {INosologyForm=} [properties] Properties to set
         * @returns {NosologyForm} NosologyForm instance
         */
        NosologyForm.create = function create(properties) {
            return new NosologyForm(properties);
        };
    
        /**
         * Encodes the specified NosologyForm message. Does not implicitly {@link NosologyForm.verify|verify} messages.
         * @function encode
         * @memberof NosologyForm
         * @static
         * @param {INosologyForm} message NosologyForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NosologyForm.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.category != null && Object.hasOwnProperty.call(message, "category"))
                $root.CategorySearch.encode(message.category, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.nosology != null && Object.hasOwnProperty.call(message, "nosology"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.nosology);
            if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.errorMessage);
            if (message.synonyms != null && message.synonyms.length)
                for (var i = 0; i < message.synonyms.length; ++i)
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.synonyms[i]);
            return writer;
        };
    
        /**
         * Encodes the specified NosologyForm message, length delimited. Does not implicitly {@link NosologyForm.verify|verify} messages.
         * @function encodeDelimited
         * @memberof NosologyForm
         * @static
         * @param {INosologyForm} message NosologyForm message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NosologyForm.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a NosologyForm message from the specified reader or buffer.
         * @function decode
         * @memberof NosologyForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {NosologyForm} NosologyForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NosologyForm.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.NosologyForm();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.category = $root.CategorySearch.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.nosology = reader.string();
                    break;
                case 4:
                    message.errorMessage = reader.string();
                    break;
                case 5:
                    if (!(message.synonyms && message.synonyms.length))
                        message.synonyms = [];
                    message.synonyms.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a NosologyForm message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof NosologyForm
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {NosologyForm} NosologyForm
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NosologyForm.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a NosologyForm message.
         * @function verify
         * @memberof NosologyForm
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NosologyForm.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.category != null && message.hasOwnProperty("category")) {
                var error = $root.CategorySearch.verify(message.category);
                if (error)
                    return "category." + error;
            }
            if (message.nosology != null && message.hasOwnProperty("nosology"))
                if (!$util.isString(message.nosology))
                    return "nosology: string expected";
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                if (!$util.isString(message.errorMessage))
                    return "errorMessage: string expected";
            if (message.synonyms != null && message.hasOwnProperty("synonyms")) {
                if (!Array.isArray(message.synonyms))
                    return "synonyms: array expected";
                for (var i = 0; i < message.synonyms.length; ++i)
                    if (!$util.isString(message.synonyms[i]))
                        return "synonyms: string[] expected";
            }
            return null;
        };
    
        /**
         * Creates a NosologyForm message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof NosologyForm
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {NosologyForm} NosologyForm
         */
        NosologyForm.fromObject = function fromObject(object) {
            if (object instanceof $root.NosologyForm)
                return object;
            var message = new $root.NosologyForm();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.category != null) {
                if (typeof object.category !== "object")
                    throw TypeError(".NosologyForm.category: object expected");
                message.category = $root.CategorySearch.fromObject(object.category);
            }
            if (object.nosology != null)
                message.nosology = String(object.nosology);
            if (object.errorMessage != null)
                message.errorMessage = String(object.errorMessage);
            if (object.synonyms) {
                if (!Array.isArray(object.synonyms))
                    throw TypeError(".NosologyForm.synonyms: array expected");
                message.synonyms = [];
                for (var i = 0; i < object.synonyms.length; ++i)
                    message.synonyms[i] = String(object.synonyms[i]);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a NosologyForm message. Also converts values to other types if specified.
         * @function toObject
         * @memberof NosologyForm
         * @static
         * @param {NosologyForm} message NosologyForm
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NosologyForm.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.synonyms = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.category = null;
                object.nosology = "";
                object.errorMessage = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.category != null && message.hasOwnProperty("category"))
                object.category = $root.CategorySearch.toObject(message.category, options);
            if (message.nosology != null && message.hasOwnProperty("nosology"))
                object.nosology = message.nosology;
            if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                object.errorMessage = message.errorMessage;
            if (message.synonyms && message.synonyms.length) {
                object.synonyms = [];
                for (var j = 0; j < message.synonyms.length; ++j)
                    object.synonyms[j] = message.synonyms[j];
            }
            return object;
        };
    
        /**
         * Converts this NosologyForm to JSON.
         * @function toJSON
         * @memberof NosologyForm
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NosologyForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return NosologyForm;
    })();
    
    $root.NosologyList = (function() {
    
        /**
         * Properties of a NosologyList.
         * @exports INosologyList
         * @interface INosologyList
         * @property {Uint8Array|null} [id] NosologyList id
         * @property {string|null} [name] NosologyList name
         * @property {string|null} [categoryName] NosologyList categoryName
         */
    
        /**
         * Constructs a new NosologyList.
         * @exports NosologyList
         * @classdesc Represents a NosologyList.
         * @implements INosologyList
         * @constructor
         * @param {INosologyList=} [properties] Properties to set
         */
        function NosologyList(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * NosologyList id.
         * @member {Uint8Array} id
         * @memberof NosologyList
         * @instance
         */
        NosologyList.prototype.id = $util.newBuffer([]);
    
        /**
         * NosologyList name.
         * @member {string} name
         * @memberof NosologyList
         * @instance
         */
        NosologyList.prototype.name = "";
    
        /**
         * NosologyList categoryName.
         * @member {string} categoryName
         * @memberof NosologyList
         * @instance
         */
        NosologyList.prototype.categoryName = "";
    
        /**
         * Creates a new NosologyList instance using the specified properties.
         * @function create
         * @memberof NosologyList
         * @static
         * @param {INosologyList=} [properties] Properties to set
         * @returns {NosologyList} NosologyList instance
         */
        NosologyList.create = function create(properties) {
            return new NosologyList(properties);
        };
    
        /**
         * Encodes the specified NosologyList message. Does not implicitly {@link NosologyList.verify|verify} messages.
         * @function encode
         * @memberof NosologyList
         * @static
         * @param {INosologyList} message NosologyList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NosologyList.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.categoryName != null && Object.hasOwnProperty.call(message, "categoryName"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.categoryName);
            return writer;
        };
    
        /**
         * Encodes the specified NosologyList message, length delimited. Does not implicitly {@link NosologyList.verify|verify} messages.
         * @function encodeDelimited
         * @memberof NosologyList
         * @static
         * @param {INosologyList} message NosologyList message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        NosologyList.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a NosologyList message from the specified reader or buffer.
         * @function decode
         * @memberof NosologyList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {NosologyList} NosologyList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NosologyList.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.NosologyList();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.categoryName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a NosologyList message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof NosologyList
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {NosologyList} NosologyList
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        NosologyList.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a NosologyList message.
         * @function verify
         * @memberof NosologyList
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        NosologyList.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                if (!$util.isString(message.categoryName))
                    return "categoryName: string expected";
            return null;
        };
    
        /**
         * Creates a NosologyList message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof NosologyList
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {NosologyList} NosologyList
         */
        NosologyList.fromObject = function fromObject(object) {
            if (object instanceof $root.NosologyList)
                return object;
            var message = new $root.NosologyList();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            if (object.categoryName != null)
                message.categoryName = String(object.categoryName);
            return message;
        };
    
        /**
         * Creates a plain object from a NosologyList message. Also converts values to other types if specified.
         * @function toObject
         * @memberof NosologyList
         * @static
         * @param {NosologyList} message NosologyList
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        NosologyList.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
                object.categoryName = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.categoryName != null && message.hasOwnProperty("categoryName"))
                object.categoryName = message.categoryName;
            return object;
        };
    
        /**
         * Converts this NosologyList to JSON.
         * @function toJSON
         * @memberof NosologyList
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        NosologyList.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return NosologyList;
    })();
    
    $root.Nosologies = (function() {
    
        /**
         * Properties of a Nosologies.
         * @exports INosologies
         * @interface INosologies
         * @property {number|null} [rowCount] Nosologies rowCount
         * @property {Array.<INosologyList>|null} [items] Nosologies items
         */
    
        /**
         * Constructs a new Nosologies.
         * @exports Nosologies
         * @classdesc Represents a Nosologies.
         * @implements INosologies
         * @constructor
         * @param {INosologies=} [properties] Properties to set
         */
        function Nosologies(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Nosologies rowCount.
         * @member {number} rowCount
         * @memberof Nosologies
         * @instance
         */
        Nosologies.prototype.rowCount = 0;
    
        /**
         * Nosologies items.
         * @member {Array.<INosologyList>} items
         * @memberof Nosologies
         * @instance
         */
        Nosologies.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Nosologies instance using the specified properties.
         * @function create
         * @memberof Nosologies
         * @static
         * @param {INosologies=} [properties] Properties to set
         * @returns {Nosologies} Nosologies instance
         */
        Nosologies.create = function create(properties) {
            return new Nosologies(properties);
        };
    
        /**
         * Encodes the specified Nosologies message. Does not implicitly {@link Nosologies.verify|verify} messages.
         * @function encode
         * @memberof Nosologies
         * @static
         * @param {INosologies} message Nosologies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Nosologies.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.NosologyList.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Nosologies message, length delimited. Does not implicitly {@link Nosologies.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Nosologies
         * @static
         * @param {INosologies} message Nosologies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Nosologies.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Nosologies message from the specified reader or buffer.
         * @function decode
         * @memberof Nosologies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Nosologies} Nosologies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Nosologies.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Nosologies();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.NosologyList.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Nosologies message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Nosologies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Nosologies} Nosologies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Nosologies.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Nosologies message.
         * @function verify
         * @memberof Nosologies
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Nosologies.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.NosologyList.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Nosologies message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Nosologies
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Nosologies} Nosologies
         */
        Nosologies.fromObject = function fromObject(object) {
            if (object instanceof $root.Nosologies)
                return object;
            var message = new $root.Nosologies();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Nosologies.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Nosologies.items: object expected");
                    message.items[i] = $root.NosologyList.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Nosologies message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Nosologies
         * @static
         * @param {Nosologies} message Nosologies
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Nosologies.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.NosologyList.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Nosologies to JSON.
         * @function toJSON
         * @memberof Nosologies
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Nosologies.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Nosologies;
    })();
    
    $root.CategorySearch = (function() {
    
        /**
         * Properties of a CategorySearch.
         * @exports ICategorySearch
         * @interface ICategorySearch
         * @property {Uint8Array|null} [id] CategorySearch id
         * @property {string|null} [name] CategorySearch name
         */
    
        /**
         * Constructs a new CategorySearch.
         * @exports CategorySearch
         * @classdesc Represents a CategorySearch.
         * @implements ICategorySearch
         * @constructor
         * @param {ICategorySearch=} [properties] Properties to set
         */
        function CategorySearch(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CategorySearch id.
         * @member {Uint8Array} id
         * @memberof CategorySearch
         * @instance
         */
        CategorySearch.prototype.id = $util.newBuffer([]);
    
        /**
         * CategorySearch name.
         * @member {string} name
         * @memberof CategorySearch
         * @instance
         */
        CategorySearch.prototype.name = "";
    
        /**
         * Creates a new CategorySearch instance using the specified properties.
         * @function create
         * @memberof CategorySearch
         * @static
         * @param {ICategorySearch=} [properties] Properties to set
         * @returns {CategorySearch} CategorySearch instance
         */
        CategorySearch.create = function create(properties) {
            return new CategorySearch(properties);
        };
    
        /**
         * Encodes the specified CategorySearch message. Does not implicitly {@link CategorySearch.verify|verify} messages.
         * @function encode
         * @memberof CategorySearch
         * @static
         * @param {ICategorySearch} message CategorySearch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategorySearch.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified CategorySearch message, length delimited. Does not implicitly {@link CategorySearch.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CategorySearch
         * @static
         * @param {ICategorySearch} message CategorySearch message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategorySearch.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CategorySearch message from the specified reader or buffer.
         * @function decode
         * @memberof CategorySearch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CategorySearch} CategorySearch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategorySearch.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CategorySearch();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CategorySearch message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CategorySearch
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CategorySearch} CategorySearch
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategorySearch.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CategorySearch message.
         * @function verify
         * @memberof CategorySearch
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CategorySearch.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a CategorySearch message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CategorySearch
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CategorySearch} CategorySearch
         */
        CategorySearch.fromObject = function fromObject(object) {
            if (object instanceof $root.CategorySearch)
                return object;
            var message = new $root.CategorySearch();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a CategorySearch message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CategorySearch
         * @static
         * @param {CategorySearch} message CategorySearch
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CategorySearch.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this CategorySearch to JSON.
         * @function toJSON
         * @memberof CategorySearch
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CategorySearch.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CategorySearch;
    })();
    
    $root.CategorySearchResult = (function() {
    
        /**
         * Properties of a CategorySearchResult.
         * @exports ICategorySearchResult
         * @interface ICategorySearchResult
         * @property {number|null} [rowCount] CategorySearchResult rowCount
         * @property {Array.<ICategorySearch>|null} [items] CategorySearchResult items
         */
    
        /**
         * Constructs a new CategorySearchResult.
         * @exports CategorySearchResult
         * @classdesc Represents a CategorySearchResult.
         * @implements ICategorySearchResult
         * @constructor
         * @param {ICategorySearchResult=} [properties] Properties to set
         */
        function CategorySearchResult(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * CategorySearchResult rowCount.
         * @member {number} rowCount
         * @memberof CategorySearchResult
         * @instance
         */
        CategorySearchResult.prototype.rowCount = 0;
    
        /**
         * CategorySearchResult items.
         * @member {Array.<ICategorySearch>} items
         * @memberof CategorySearchResult
         * @instance
         */
        CategorySearchResult.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new CategorySearchResult instance using the specified properties.
         * @function create
         * @memberof CategorySearchResult
         * @static
         * @param {ICategorySearchResult=} [properties] Properties to set
         * @returns {CategorySearchResult} CategorySearchResult instance
         */
        CategorySearchResult.create = function create(properties) {
            return new CategorySearchResult(properties);
        };
    
        /**
         * Encodes the specified CategorySearchResult message. Does not implicitly {@link CategorySearchResult.verify|verify} messages.
         * @function encode
         * @memberof CategorySearchResult
         * @static
         * @param {ICategorySearchResult} message CategorySearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategorySearchResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.CategorySearch.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified CategorySearchResult message, length delimited. Does not implicitly {@link CategorySearchResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof CategorySearchResult
         * @static
         * @param {ICategorySearchResult} message CategorySearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        CategorySearchResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a CategorySearchResult message from the specified reader or buffer.
         * @function decode
         * @memberof CategorySearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {CategorySearchResult} CategorySearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategorySearchResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CategorySearchResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.CategorySearch.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a CategorySearchResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof CategorySearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {CategorySearchResult} CategorySearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        CategorySearchResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a CategorySearchResult message.
         * @function verify
         * @memberof CategorySearchResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        CategorySearchResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.CategorySearch.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a CategorySearchResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof CategorySearchResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {CategorySearchResult} CategorySearchResult
         */
        CategorySearchResult.fromObject = function fromObject(object) {
            if (object instanceof $root.CategorySearchResult)
                return object;
            var message = new $root.CategorySearchResult();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".CategorySearchResult.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".CategorySearchResult.items: object expected");
                    message.items[i] = $root.CategorySearch.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a CategorySearchResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof CategorySearchResult
         * @static
         * @param {CategorySearchResult} message CategorySearchResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        CategorySearchResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.CategorySearch.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this CategorySearchResult to JSON.
         * @function toJSON
         * @memberof CategorySearchResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        CategorySearchResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return CategorySearchResult;
    })();
    
    $root.DrugListDto = (function() {
    
        /**
         * Properties of a DrugListDto.
         * @exports IDrugListDto
         * @interface IDrugListDto
         * @property {Uint8Array|null} [drugNameId] DrugListDto drugNameId
         * @property {string|null} [drugName] DrugListDto drugName
         */
    
        /**
         * Constructs a new DrugListDto.
         * @exports DrugListDto
         * @classdesc Represents a DrugListDto.
         * @implements IDrugListDto
         * @constructor
         * @param {IDrugListDto=} [properties] Properties to set
         */
        function DrugListDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugListDto drugNameId.
         * @member {Uint8Array} drugNameId
         * @memberof DrugListDto
         * @instance
         */
        DrugListDto.prototype.drugNameId = $util.newBuffer([]);
    
        /**
         * DrugListDto drugName.
         * @member {string} drugName
         * @memberof DrugListDto
         * @instance
         */
        DrugListDto.prototype.drugName = "";
    
        /**
         * Creates a new DrugListDto instance using the specified properties.
         * @function create
         * @memberof DrugListDto
         * @static
         * @param {IDrugListDto=} [properties] Properties to set
         * @returns {DrugListDto} DrugListDto instance
         */
        DrugListDto.create = function create(properties) {
            return new DrugListDto(properties);
        };
    
        /**
         * Encodes the specified DrugListDto message. Does not implicitly {@link DrugListDto.verify|verify} messages.
         * @function encode
         * @memberof DrugListDto
         * @static
         * @param {IDrugListDto} message DrugListDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugListDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.drugNameId != null && Object.hasOwnProperty.call(message, "drugNameId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.drugNameId);
            if (message.drugName != null && Object.hasOwnProperty.call(message, "drugName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.drugName);
            return writer;
        };
    
        /**
         * Encodes the specified DrugListDto message, length delimited. Does not implicitly {@link DrugListDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugListDto
         * @static
         * @param {IDrugListDto} message DrugListDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugListDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugListDto message from the specified reader or buffer.
         * @function decode
         * @memberof DrugListDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugListDto} DrugListDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugListDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugListDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.drugNameId = reader.bytes();
                    break;
                case 2:
                    message.drugName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugListDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugListDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugListDto} DrugListDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugListDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugListDto message.
         * @function verify
         * @memberof DrugListDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugListDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.drugNameId != null && message.hasOwnProperty("drugNameId"))
                if (!(message.drugNameId && typeof message.drugNameId.length === "number" || $util.isString(message.drugNameId)))
                    return "drugNameId: buffer expected";
            if (message.drugName != null && message.hasOwnProperty("drugName"))
                if (!$util.isString(message.drugName))
                    return "drugName: string expected";
            return null;
        };
    
        /**
         * Creates a DrugListDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugListDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugListDto} DrugListDto
         */
        DrugListDto.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugListDto)
                return object;
            var message = new $root.DrugListDto();
            if (object.drugNameId != null)
                if (typeof object.drugNameId === "string")
                    $util.base64.decode(object.drugNameId, message.drugNameId = $util.newBuffer($util.base64.length(object.drugNameId)), 0);
                else if (object.drugNameId.length)
                    message.drugNameId = object.drugNameId;
            if (object.drugName != null)
                message.drugName = String(object.drugName);
            return message;
        };
    
        /**
         * Creates a plain object from a DrugListDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugListDto
         * @static
         * @param {DrugListDto} message DrugListDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugListDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.drugNameId = "";
                else {
                    object.drugNameId = [];
                    if (options.bytes !== Array)
                        object.drugNameId = $util.newBuffer(object.drugNameId);
                }
                object.drugName = "";
            }
            if (message.drugNameId != null && message.hasOwnProperty("drugNameId"))
                object.drugNameId = options.bytes === String ? $util.base64.encode(message.drugNameId, 0, message.drugNameId.length) : options.bytes === Array ? Array.prototype.slice.call(message.drugNameId) : message.drugNameId;
            if (message.drugName != null && message.hasOwnProperty("drugName"))
                object.drugName = message.drugName;
            return object;
        };
    
        /**
         * Converts this DrugListDto to JSON.
         * @function toJSON
         * @memberof DrugListDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugListDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugListDto;
    })();
    
    $root.DrugListDtos = (function() {
    
        /**
         * Properties of a DrugListDtos.
         * @exports IDrugListDtos
         * @interface IDrugListDtos
         * @property {number|null} [rowCount] DrugListDtos rowCount
         * @property {Array.<IDrugListDto>|null} [items] DrugListDtos items
         */
    
        /**
         * Constructs a new DrugListDtos.
         * @exports DrugListDtos
         * @classdesc Represents a DrugListDtos.
         * @implements IDrugListDtos
         * @constructor
         * @param {IDrugListDtos=} [properties] Properties to set
         */
        function DrugListDtos(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugListDtos rowCount.
         * @member {number} rowCount
         * @memberof DrugListDtos
         * @instance
         */
        DrugListDtos.prototype.rowCount = 0;
    
        /**
         * DrugListDtos items.
         * @member {Array.<IDrugListDto>} items
         * @memberof DrugListDtos
         * @instance
         */
        DrugListDtos.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new DrugListDtos instance using the specified properties.
         * @function create
         * @memberof DrugListDtos
         * @static
         * @param {IDrugListDtos=} [properties] Properties to set
         * @returns {DrugListDtos} DrugListDtos instance
         */
        DrugListDtos.create = function create(properties) {
            return new DrugListDtos(properties);
        };
    
        /**
         * Encodes the specified DrugListDtos message. Does not implicitly {@link DrugListDtos.verify|verify} messages.
         * @function encode
         * @memberof DrugListDtos
         * @static
         * @param {IDrugListDtos} message DrugListDtos message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugListDtos.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.DrugListDto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified DrugListDtos message, length delimited. Does not implicitly {@link DrugListDtos.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugListDtos
         * @static
         * @param {IDrugListDtos} message DrugListDtos message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugListDtos.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugListDtos message from the specified reader or buffer.
         * @function decode
         * @memberof DrugListDtos
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugListDtos} DrugListDtos
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugListDtos.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugListDtos();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.DrugListDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugListDtos message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugListDtos
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugListDtos} DrugListDtos
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugListDtos.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugListDtos message.
         * @function verify
         * @memberof DrugListDtos
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugListDtos.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.DrugListDto.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a DrugListDtos message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugListDtos
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugListDtos} DrugListDtos
         */
        DrugListDtos.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugListDtos)
                return object;
            var message = new $root.DrugListDtos();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".DrugListDtos.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".DrugListDtos.items: object expected");
                    message.items[i] = $root.DrugListDto.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a DrugListDtos message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugListDtos
         * @static
         * @param {DrugListDtos} message DrugListDtos
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugListDtos.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.DrugListDto.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this DrugListDtos to JSON.
         * @function toJSON
         * @memberof DrugListDtos
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugListDtos.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugListDtos;
    })();
    
    $root.DrugFormDto = (function() {
    
        /**
         * Properties of a DrugFormDto.
         * @exports IDrugFormDto
         * @interface IDrugFormDto
         * @property {Uint8Array|null} [drugNameId] DrugFormDto drugNameId
         * @property {string|null} [drugName] DrugFormDto drugName
         * @property {Array.<IDrugDto>|null} [drugs] DrugFormDto drugs
         * @property {Array.<string>|null} [drugNameSynonyms] DrugFormDto drugNameSynonyms
         */
    
        /**
         * Constructs a new DrugFormDto.
         * @exports DrugFormDto
         * @classdesc Represents a DrugFormDto.
         * @implements IDrugFormDto
         * @constructor
         * @param {IDrugFormDto=} [properties] Properties to set
         */
        function DrugFormDto(properties) {
            this.drugs = [];
            this.drugNameSynonyms = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugFormDto drugNameId.
         * @member {Uint8Array} drugNameId
         * @memberof DrugFormDto
         * @instance
         */
        DrugFormDto.prototype.drugNameId = $util.newBuffer([]);
    
        /**
         * DrugFormDto drugName.
         * @member {string} drugName
         * @memberof DrugFormDto
         * @instance
         */
        DrugFormDto.prototype.drugName = "";
    
        /**
         * DrugFormDto drugs.
         * @member {Array.<IDrugDto>} drugs
         * @memberof DrugFormDto
         * @instance
         */
        DrugFormDto.prototype.drugs = $util.emptyArray;
    
        /**
         * DrugFormDto drugNameSynonyms.
         * @member {Array.<string>} drugNameSynonyms
         * @memberof DrugFormDto
         * @instance
         */
        DrugFormDto.prototype.drugNameSynonyms = $util.emptyArray;
    
        /**
         * Creates a new DrugFormDto instance using the specified properties.
         * @function create
         * @memberof DrugFormDto
         * @static
         * @param {IDrugFormDto=} [properties] Properties to set
         * @returns {DrugFormDto} DrugFormDto instance
         */
        DrugFormDto.create = function create(properties) {
            return new DrugFormDto(properties);
        };
    
        /**
         * Encodes the specified DrugFormDto message. Does not implicitly {@link DrugFormDto.verify|verify} messages.
         * @function encode
         * @memberof DrugFormDto
         * @static
         * @param {IDrugFormDto} message DrugFormDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugFormDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.drugNameId != null && Object.hasOwnProperty.call(message, "drugNameId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.drugNameId);
            if (message.drugName != null && Object.hasOwnProperty.call(message, "drugName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.drugName);
            if (message.drugs != null && message.drugs.length)
                for (var i = 0; i < message.drugs.length; ++i)
                    $root.DrugDto.encode(message.drugs[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.drugNameSynonyms != null && message.drugNameSynonyms.length)
                for (var i = 0; i < message.drugNameSynonyms.length; ++i)
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.drugNameSynonyms[i]);
            return writer;
        };
    
        /**
         * Encodes the specified DrugFormDto message, length delimited. Does not implicitly {@link DrugFormDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugFormDto
         * @static
         * @param {IDrugFormDto} message DrugFormDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugFormDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugFormDto message from the specified reader or buffer.
         * @function decode
         * @memberof DrugFormDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugFormDto} DrugFormDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugFormDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugFormDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.drugNameId = reader.bytes();
                    break;
                case 2:
                    message.drugName = reader.string();
                    break;
                case 3:
                    if (!(message.drugs && message.drugs.length))
                        message.drugs = [];
                    message.drugs.push($root.DrugDto.decode(reader, reader.uint32()));
                    break;
                case 4:
                    if (!(message.drugNameSynonyms && message.drugNameSynonyms.length))
                        message.drugNameSynonyms = [];
                    message.drugNameSynonyms.push(reader.string());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugFormDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugFormDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugFormDto} DrugFormDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugFormDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugFormDto message.
         * @function verify
         * @memberof DrugFormDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugFormDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.drugNameId != null && message.hasOwnProperty("drugNameId"))
                if (!(message.drugNameId && typeof message.drugNameId.length === "number" || $util.isString(message.drugNameId)))
                    return "drugNameId: buffer expected";
            if (message.drugName != null && message.hasOwnProperty("drugName"))
                if (!$util.isString(message.drugName))
                    return "drugName: string expected";
            if (message.drugs != null && message.hasOwnProperty("drugs")) {
                if (!Array.isArray(message.drugs))
                    return "drugs: array expected";
                for (var i = 0; i < message.drugs.length; ++i) {
                    var error = $root.DrugDto.verify(message.drugs[i]);
                    if (error)
                        return "drugs." + error;
                }
            }
            if (message.drugNameSynonyms != null && message.hasOwnProperty("drugNameSynonyms")) {
                if (!Array.isArray(message.drugNameSynonyms))
                    return "drugNameSynonyms: array expected";
                for (var i = 0; i < message.drugNameSynonyms.length; ++i)
                    if (!$util.isString(message.drugNameSynonyms[i]))
                        return "drugNameSynonyms: string[] expected";
            }
            return null;
        };
    
        /**
         * Creates a DrugFormDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugFormDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugFormDto} DrugFormDto
         */
        DrugFormDto.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugFormDto)
                return object;
            var message = new $root.DrugFormDto();
            if (object.drugNameId != null)
                if (typeof object.drugNameId === "string")
                    $util.base64.decode(object.drugNameId, message.drugNameId = $util.newBuffer($util.base64.length(object.drugNameId)), 0);
                else if (object.drugNameId.length)
                    message.drugNameId = object.drugNameId;
            if (object.drugName != null)
                message.drugName = String(object.drugName);
            if (object.drugs) {
                if (!Array.isArray(object.drugs))
                    throw TypeError(".DrugFormDto.drugs: array expected");
                message.drugs = [];
                for (var i = 0; i < object.drugs.length; ++i) {
                    if (typeof object.drugs[i] !== "object")
                        throw TypeError(".DrugFormDto.drugs: object expected");
                    message.drugs[i] = $root.DrugDto.fromObject(object.drugs[i]);
                }
            }
            if (object.drugNameSynonyms) {
                if (!Array.isArray(object.drugNameSynonyms))
                    throw TypeError(".DrugFormDto.drugNameSynonyms: array expected");
                message.drugNameSynonyms = [];
                for (var i = 0; i < object.drugNameSynonyms.length; ++i)
                    message.drugNameSynonyms[i] = String(object.drugNameSynonyms[i]);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a DrugFormDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugFormDto
         * @static
         * @param {DrugFormDto} message DrugFormDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugFormDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults) {
                object.drugs = [];
                object.drugNameSynonyms = [];
            }
            if (options.defaults) {
                if (options.bytes === String)
                    object.drugNameId = "";
                else {
                    object.drugNameId = [];
                    if (options.bytes !== Array)
                        object.drugNameId = $util.newBuffer(object.drugNameId);
                }
                object.drugName = "";
            }
            if (message.drugNameId != null && message.hasOwnProperty("drugNameId"))
                object.drugNameId = options.bytes === String ? $util.base64.encode(message.drugNameId, 0, message.drugNameId.length) : options.bytes === Array ? Array.prototype.slice.call(message.drugNameId) : message.drugNameId;
            if (message.drugName != null && message.hasOwnProperty("drugName"))
                object.drugName = message.drugName;
            if (message.drugs && message.drugs.length) {
                object.drugs = [];
                for (var j = 0; j < message.drugs.length; ++j)
                    object.drugs[j] = $root.DrugDto.toObject(message.drugs[j], options);
            }
            if (message.drugNameSynonyms && message.drugNameSynonyms.length) {
                object.drugNameSynonyms = [];
                for (var j = 0; j < message.drugNameSynonyms.length; ++j)
                    object.drugNameSynonyms[j] = message.drugNameSynonyms[j];
            }
            return object;
        };
    
        /**
         * Converts this DrugFormDto to JSON.
         * @function toJSON
         * @memberof DrugFormDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugFormDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugFormDto;
    })();
    
    $root.DrugDto = (function() {
    
        /**
         * Properties of a DrugDto.
         * @exports IDrugDto
         * @interface IDrugDto
         * @property {Uint8Array|null} [drugId] DrugDto drugId
         * @property {IAtcGroupDto|null} [atcGroup] DrugDto atcGroup
         * @property {IPtgDto|null} [ptg] DrugDto ptg
         * @property {IDrugFormFullDto|null} [drugFormFull] DrugDto drugFormFull
         * @property {Uint8Array|null} [drugDosageId] DrugDto drugDosageId
         * @property {Uint8Array|null} [innId] DrugDto innId
         * @property {Array.<IDosageDto>|null} [dosages] DrugDto dosages
         */
    
        /**
         * Constructs a new DrugDto.
         * @exports DrugDto
         * @classdesc Represents a DrugDto.
         * @implements IDrugDto
         * @constructor
         * @param {IDrugDto=} [properties] Properties to set
         */
        function DrugDto(properties) {
            this.dosages = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugDto drugId.
         * @member {Uint8Array} drugId
         * @memberof DrugDto
         * @instance
         */
        DrugDto.prototype.drugId = $util.newBuffer([]);
    
        /**
         * DrugDto atcGroup.
         * @member {IAtcGroupDto|null|undefined} atcGroup
         * @memberof DrugDto
         * @instance
         */
        DrugDto.prototype.atcGroup = null;
    
        /**
         * DrugDto ptg.
         * @member {IPtgDto|null|undefined} ptg
         * @memberof DrugDto
         * @instance
         */
        DrugDto.prototype.ptg = null;
    
        /**
         * DrugDto drugFormFull.
         * @member {IDrugFormFullDto|null|undefined} drugFormFull
         * @memberof DrugDto
         * @instance
         */
        DrugDto.prototype.drugFormFull = null;
    
        /**
         * DrugDto drugDosageId.
         * @member {Uint8Array} drugDosageId
         * @memberof DrugDto
         * @instance
         */
        DrugDto.prototype.drugDosageId = $util.newBuffer([]);
    
        /**
         * DrugDto innId.
         * @member {Uint8Array} innId
         * @memberof DrugDto
         * @instance
         */
        DrugDto.prototype.innId = $util.newBuffer([]);
    
        /**
         * DrugDto dosages.
         * @member {Array.<IDosageDto>} dosages
         * @memberof DrugDto
         * @instance
         */
        DrugDto.prototype.dosages = $util.emptyArray;
    
        /**
         * Creates a new DrugDto instance using the specified properties.
         * @function create
         * @memberof DrugDto
         * @static
         * @param {IDrugDto=} [properties] Properties to set
         * @returns {DrugDto} DrugDto instance
         */
        DrugDto.create = function create(properties) {
            return new DrugDto(properties);
        };
    
        /**
         * Encodes the specified DrugDto message. Does not implicitly {@link DrugDto.verify|verify} messages.
         * @function encode
         * @memberof DrugDto
         * @static
         * @param {IDrugDto} message DrugDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.drugId != null && Object.hasOwnProperty.call(message, "drugId"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.drugId);
            if (message.atcGroup != null && Object.hasOwnProperty.call(message, "atcGroup"))
                $root.AtcGroupDto.encode(message.atcGroup, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.ptg != null && Object.hasOwnProperty.call(message, "ptg"))
                $root.PtgDto.encode(message.ptg, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            if (message.drugFormFull != null && Object.hasOwnProperty.call(message, "drugFormFull"))
                $root.DrugFormFullDto.encode(message.drugFormFull, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
            if (message.drugDosageId != null && Object.hasOwnProperty.call(message, "drugDosageId"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.drugDosageId);
            if (message.innId != null && Object.hasOwnProperty.call(message, "innId"))
                writer.uint32(/* id 6, wireType 2 =*/50).bytes(message.innId);
            if (message.dosages != null && message.dosages.length)
                for (var i = 0; i < message.dosages.length; ++i)
                    $root.DosageDto.encode(message.dosages[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified DrugDto message, length delimited. Does not implicitly {@link DrugDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugDto
         * @static
         * @param {IDrugDto} message DrugDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugDto message from the specified reader or buffer.
         * @function decode
         * @memberof DrugDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugDto} DrugDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.drugId = reader.bytes();
                    break;
                case 2:
                    message.atcGroup = $root.AtcGroupDto.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.ptg = $root.PtgDto.decode(reader, reader.uint32());
                    break;
                case 4:
                    message.drugFormFull = $root.DrugFormFullDto.decode(reader, reader.uint32());
                    break;
                case 5:
                    message.drugDosageId = reader.bytes();
                    break;
                case 6:
                    message.innId = reader.bytes();
                    break;
                case 7:
                    if (!(message.dosages && message.dosages.length))
                        message.dosages = [];
                    message.dosages.push($root.DosageDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugDto} DrugDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugDto message.
         * @function verify
         * @memberof DrugDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.drugId != null && message.hasOwnProperty("drugId"))
                if (!(message.drugId && typeof message.drugId.length === "number" || $util.isString(message.drugId)))
                    return "drugId: buffer expected";
            if (message.atcGroup != null && message.hasOwnProperty("atcGroup")) {
                var error = $root.AtcGroupDto.verify(message.atcGroup);
                if (error)
                    return "atcGroup." + error;
            }
            if (message.ptg != null && message.hasOwnProperty("ptg")) {
                var error = $root.PtgDto.verify(message.ptg);
                if (error)
                    return "ptg." + error;
            }
            if (message.drugFormFull != null && message.hasOwnProperty("drugFormFull")) {
                var error = $root.DrugFormFullDto.verify(message.drugFormFull);
                if (error)
                    return "drugFormFull." + error;
            }
            if (message.drugDosageId != null && message.hasOwnProperty("drugDosageId"))
                if (!(message.drugDosageId && typeof message.drugDosageId.length === "number" || $util.isString(message.drugDosageId)))
                    return "drugDosageId: buffer expected";
            if (message.innId != null && message.hasOwnProperty("innId"))
                if (!(message.innId && typeof message.innId.length === "number" || $util.isString(message.innId)))
                    return "innId: buffer expected";
            if (message.dosages != null && message.hasOwnProperty("dosages")) {
                if (!Array.isArray(message.dosages))
                    return "dosages: array expected";
                for (var i = 0; i < message.dosages.length; ++i) {
                    var error = $root.DosageDto.verify(message.dosages[i]);
                    if (error)
                        return "dosages." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a DrugDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugDto} DrugDto
         */
        DrugDto.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugDto)
                return object;
            var message = new $root.DrugDto();
            if (object.drugId != null)
                if (typeof object.drugId === "string")
                    $util.base64.decode(object.drugId, message.drugId = $util.newBuffer($util.base64.length(object.drugId)), 0);
                else if (object.drugId.length)
                    message.drugId = object.drugId;
            if (object.atcGroup != null) {
                if (typeof object.atcGroup !== "object")
                    throw TypeError(".DrugDto.atcGroup: object expected");
                message.atcGroup = $root.AtcGroupDto.fromObject(object.atcGroup);
            }
            if (object.ptg != null) {
                if (typeof object.ptg !== "object")
                    throw TypeError(".DrugDto.ptg: object expected");
                message.ptg = $root.PtgDto.fromObject(object.ptg);
            }
            if (object.drugFormFull != null) {
                if (typeof object.drugFormFull !== "object")
                    throw TypeError(".DrugDto.drugFormFull: object expected");
                message.drugFormFull = $root.DrugFormFullDto.fromObject(object.drugFormFull);
            }
            if (object.drugDosageId != null)
                if (typeof object.drugDosageId === "string")
                    $util.base64.decode(object.drugDosageId, message.drugDosageId = $util.newBuffer($util.base64.length(object.drugDosageId)), 0);
                else if (object.drugDosageId.length)
                    message.drugDosageId = object.drugDosageId;
            if (object.innId != null)
                if (typeof object.innId === "string")
                    $util.base64.decode(object.innId, message.innId = $util.newBuffer($util.base64.length(object.innId)), 0);
                else if (object.innId.length)
                    message.innId = object.innId;
            if (object.dosages) {
                if (!Array.isArray(object.dosages))
                    throw TypeError(".DrugDto.dosages: array expected");
                message.dosages = [];
                for (var i = 0; i < object.dosages.length; ++i) {
                    if (typeof object.dosages[i] !== "object")
                        throw TypeError(".DrugDto.dosages: object expected");
                    message.dosages[i] = $root.DosageDto.fromObject(object.dosages[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a DrugDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugDto
         * @static
         * @param {DrugDto} message DrugDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.dosages = [];
            if (options.defaults) {
                if (options.bytes === String)
                    object.drugId = "";
                else {
                    object.drugId = [];
                    if (options.bytes !== Array)
                        object.drugId = $util.newBuffer(object.drugId);
                }
                object.atcGroup = null;
                object.ptg = null;
                object.drugFormFull = null;
                if (options.bytes === String)
                    object.drugDosageId = "";
                else {
                    object.drugDosageId = [];
                    if (options.bytes !== Array)
                        object.drugDosageId = $util.newBuffer(object.drugDosageId);
                }
                if (options.bytes === String)
                    object.innId = "";
                else {
                    object.innId = [];
                    if (options.bytes !== Array)
                        object.innId = $util.newBuffer(object.innId);
                }
            }
            if (message.drugId != null && message.hasOwnProperty("drugId"))
                object.drugId = options.bytes === String ? $util.base64.encode(message.drugId, 0, message.drugId.length) : options.bytes === Array ? Array.prototype.slice.call(message.drugId) : message.drugId;
            if (message.atcGroup != null && message.hasOwnProperty("atcGroup"))
                object.atcGroup = $root.AtcGroupDto.toObject(message.atcGroup, options);
            if (message.ptg != null && message.hasOwnProperty("ptg"))
                object.ptg = $root.PtgDto.toObject(message.ptg, options);
            if (message.drugFormFull != null && message.hasOwnProperty("drugFormFull"))
                object.drugFormFull = $root.DrugFormFullDto.toObject(message.drugFormFull, options);
            if (message.drugDosageId != null && message.hasOwnProperty("drugDosageId"))
                object.drugDosageId = options.bytes === String ? $util.base64.encode(message.drugDosageId, 0, message.drugDosageId.length) : options.bytes === Array ? Array.prototype.slice.call(message.drugDosageId) : message.drugDosageId;
            if (message.innId != null && message.hasOwnProperty("innId"))
                object.innId = options.bytes === String ? $util.base64.encode(message.innId, 0, message.innId.length) : options.bytes === Array ? Array.prototype.slice.call(message.innId) : message.innId;
            if (message.dosages && message.dosages.length) {
                object.dosages = [];
                for (var j = 0; j < message.dosages.length; ++j)
                    object.dosages[j] = $root.DosageDto.toObject(message.dosages[j], options);
            }
            return object;
        };
    
        /**
         * Converts this DrugDto to JSON.
         * @function toJSON
         * @memberof DrugDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugDto;
    })();
    
    $root.DosageDto = (function() {
    
        /**
         * Properties of a DosageDto.
         * @exports IDosageDto
         * @interface IDosageDto
         * @property {IInnPartDto|null} [innPart] DosageDto innPart
         * @property {IDrugDosagePartDto|null} [drugDosagePart] DosageDto drugDosagePart
         */
    
        /**
         * Constructs a new DosageDto.
         * @exports DosageDto
         * @classdesc Represents a DosageDto.
         * @implements IDosageDto
         * @constructor
         * @param {IDosageDto=} [properties] Properties to set
         */
        function DosageDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DosageDto innPart.
         * @member {IInnPartDto|null|undefined} innPart
         * @memberof DosageDto
         * @instance
         */
        DosageDto.prototype.innPart = null;
    
        /**
         * DosageDto drugDosagePart.
         * @member {IDrugDosagePartDto|null|undefined} drugDosagePart
         * @memberof DosageDto
         * @instance
         */
        DosageDto.prototype.drugDosagePart = null;
    
        /**
         * Creates a new DosageDto instance using the specified properties.
         * @function create
         * @memberof DosageDto
         * @static
         * @param {IDosageDto=} [properties] Properties to set
         * @returns {DosageDto} DosageDto instance
         */
        DosageDto.create = function create(properties) {
            return new DosageDto(properties);
        };
    
        /**
         * Encodes the specified DosageDto message. Does not implicitly {@link DosageDto.verify|verify} messages.
         * @function encode
         * @memberof DosageDto
         * @static
         * @param {IDosageDto} message DosageDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DosageDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.innPart != null && Object.hasOwnProperty.call(message, "innPart"))
                $root.InnPartDto.encode(message.innPart, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            if (message.drugDosagePart != null && Object.hasOwnProperty.call(message, "drugDosagePart"))
                $root.DrugDosagePartDto.encode(message.drugDosagePart, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified DosageDto message, length delimited. Does not implicitly {@link DosageDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DosageDto
         * @static
         * @param {IDosageDto} message DosageDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DosageDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DosageDto message from the specified reader or buffer.
         * @function decode
         * @memberof DosageDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DosageDto} DosageDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DosageDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DosageDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.innPart = $root.InnPartDto.decode(reader, reader.uint32());
                    break;
                case 2:
                    message.drugDosagePart = $root.DrugDosagePartDto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DosageDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DosageDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DosageDto} DosageDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DosageDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DosageDto message.
         * @function verify
         * @memberof DosageDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DosageDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.innPart != null && message.hasOwnProperty("innPart")) {
                var error = $root.InnPartDto.verify(message.innPart);
                if (error)
                    return "innPart." + error;
            }
            if (message.drugDosagePart != null && message.hasOwnProperty("drugDosagePart")) {
                var error = $root.DrugDosagePartDto.verify(message.drugDosagePart);
                if (error)
                    return "drugDosagePart." + error;
            }
            return null;
        };
    
        /**
         * Creates a DosageDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DosageDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DosageDto} DosageDto
         */
        DosageDto.fromObject = function fromObject(object) {
            if (object instanceof $root.DosageDto)
                return object;
            var message = new $root.DosageDto();
            if (object.innPart != null) {
                if (typeof object.innPart !== "object")
                    throw TypeError(".DosageDto.innPart: object expected");
                message.innPart = $root.InnPartDto.fromObject(object.innPart);
            }
            if (object.drugDosagePart != null) {
                if (typeof object.drugDosagePart !== "object")
                    throw TypeError(".DosageDto.drugDosagePart: object expected");
                message.drugDosagePart = $root.DrugDosagePartDto.fromObject(object.drugDosagePart);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a DosageDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DosageDto
         * @static
         * @param {DosageDto} message DosageDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DosageDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.innPart = null;
                object.drugDosagePart = null;
            }
            if (message.innPart != null && message.hasOwnProperty("innPart"))
                object.innPart = $root.InnPartDto.toObject(message.innPart, options);
            if (message.drugDosagePart != null && message.hasOwnProperty("drugDosagePart"))
                object.drugDosagePart = $root.DrugDosagePartDto.toObject(message.drugDosagePart, options);
            return object;
        };
    
        /**
         * Converts this DosageDto to JSON.
         * @function toJSON
         * @memberof DosageDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DosageDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DosageDto;
    })();
    
    $root.InnPartDto = (function() {
    
        /**
         * Properties of an InnPartDto.
         * @exports IInnPartDto
         * @interface IInnPartDto
         * @property {Uint8Array|null} [id] InnPartDto id
         * @property {string|null} [name] InnPartDto name
         */
    
        /**
         * Constructs a new InnPartDto.
         * @exports InnPartDto
         * @classdesc Represents an InnPartDto.
         * @implements IInnPartDto
         * @constructor
         * @param {IInnPartDto=} [properties] Properties to set
         */
        function InnPartDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * InnPartDto id.
         * @member {Uint8Array} id
         * @memberof InnPartDto
         * @instance
         */
        InnPartDto.prototype.id = $util.newBuffer([]);
    
        /**
         * InnPartDto name.
         * @member {string} name
         * @memberof InnPartDto
         * @instance
         */
        InnPartDto.prototype.name = "";
    
        /**
         * Creates a new InnPartDto instance using the specified properties.
         * @function create
         * @memberof InnPartDto
         * @static
         * @param {IInnPartDto=} [properties] Properties to set
         * @returns {InnPartDto} InnPartDto instance
         */
        InnPartDto.create = function create(properties) {
            return new InnPartDto(properties);
        };
    
        /**
         * Encodes the specified InnPartDto message. Does not implicitly {@link InnPartDto.verify|verify} messages.
         * @function encode
         * @memberof InnPartDto
         * @static
         * @param {IInnPartDto} message InnPartDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InnPartDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified InnPartDto message, length delimited. Does not implicitly {@link InnPartDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof InnPartDto
         * @static
         * @param {IInnPartDto} message InnPartDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InnPartDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an InnPartDto message from the specified reader or buffer.
         * @function decode
         * @memberof InnPartDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {InnPartDto} InnPartDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InnPartDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.InnPartDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an InnPartDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof InnPartDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {InnPartDto} InnPartDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InnPartDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an InnPartDto message.
         * @function verify
         * @memberof InnPartDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InnPartDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates an InnPartDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof InnPartDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {InnPartDto} InnPartDto
         */
        InnPartDto.fromObject = function fromObject(object) {
            if (object instanceof $root.InnPartDto)
                return object;
            var message = new $root.InnPartDto();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from an InnPartDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof InnPartDto
         * @static
         * @param {InnPartDto} message InnPartDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InnPartDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this InnPartDto to JSON.
         * @function toJSON
         * @memberof InnPartDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InnPartDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return InnPartDto;
    })();
    
    $root.InnPartSearchResultDto = (function() {
    
        /**
         * Properties of an InnPartSearchResultDto.
         * @exports IInnPartSearchResultDto
         * @interface IInnPartSearchResultDto
         * @property {number|null} [rowCount] InnPartSearchResultDto rowCount
         * @property {Array.<IInnPartDto>|null} [items] InnPartSearchResultDto items
         */
    
        /**
         * Constructs a new InnPartSearchResultDto.
         * @exports InnPartSearchResultDto
         * @classdesc Represents an InnPartSearchResultDto.
         * @implements IInnPartSearchResultDto
         * @constructor
         * @param {IInnPartSearchResultDto=} [properties] Properties to set
         */
        function InnPartSearchResultDto(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * InnPartSearchResultDto rowCount.
         * @member {number} rowCount
         * @memberof InnPartSearchResultDto
         * @instance
         */
        InnPartSearchResultDto.prototype.rowCount = 0;
    
        /**
         * InnPartSearchResultDto items.
         * @member {Array.<IInnPartDto>} items
         * @memberof InnPartSearchResultDto
         * @instance
         */
        InnPartSearchResultDto.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new InnPartSearchResultDto instance using the specified properties.
         * @function create
         * @memberof InnPartSearchResultDto
         * @static
         * @param {IInnPartSearchResultDto=} [properties] Properties to set
         * @returns {InnPartSearchResultDto} InnPartSearchResultDto instance
         */
        InnPartSearchResultDto.create = function create(properties) {
            return new InnPartSearchResultDto(properties);
        };
    
        /**
         * Encodes the specified InnPartSearchResultDto message. Does not implicitly {@link InnPartSearchResultDto.verify|verify} messages.
         * @function encode
         * @memberof InnPartSearchResultDto
         * @static
         * @param {IInnPartSearchResultDto} message InnPartSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InnPartSearchResultDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.InnPartDto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified InnPartSearchResultDto message, length delimited. Does not implicitly {@link InnPartSearchResultDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof InnPartSearchResultDto
         * @static
         * @param {IInnPartSearchResultDto} message InnPartSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        InnPartSearchResultDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an InnPartSearchResultDto message from the specified reader or buffer.
         * @function decode
         * @memberof InnPartSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {InnPartSearchResultDto} InnPartSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InnPartSearchResultDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.InnPartSearchResultDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.InnPartDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an InnPartSearchResultDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof InnPartSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {InnPartSearchResultDto} InnPartSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        InnPartSearchResultDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an InnPartSearchResultDto message.
         * @function verify
         * @memberof InnPartSearchResultDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        InnPartSearchResultDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.InnPartDto.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates an InnPartSearchResultDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof InnPartSearchResultDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {InnPartSearchResultDto} InnPartSearchResultDto
         */
        InnPartSearchResultDto.fromObject = function fromObject(object) {
            if (object instanceof $root.InnPartSearchResultDto)
                return object;
            var message = new $root.InnPartSearchResultDto();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".InnPartSearchResultDto.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".InnPartSearchResultDto.items: object expected");
                    message.items[i] = $root.InnPartDto.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from an InnPartSearchResultDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof InnPartSearchResultDto
         * @static
         * @param {InnPartSearchResultDto} message InnPartSearchResultDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        InnPartSearchResultDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.InnPartDto.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this InnPartSearchResultDto to JSON.
         * @function toJSON
         * @memberof InnPartSearchResultDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        InnPartSearchResultDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return InnPartSearchResultDto;
    })();
    
    $root.DrugDosagePartDto = (function() {
    
        /**
         * Properties of a DrugDosagePartDto.
         * @exports IDrugDosagePartDto
         * @interface IDrugDosagePartDto
         * @property {Uint8Array|null} [id] DrugDosagePartDto id
         * @property {IMeasureDto|null} [measure] DrugDosagePartDto measure
         * @property {string|null} [value] DrugDosagePartDto value
         */
    
        /**
         * Constructs a new DrugDosagePartDto.
         * @exports DrugDosagePartDto
         * @classdesc Represents a DrugDosagePartDto.
         * @implements IDrugDosagePartDto
         * @constructor
         * @param {IDrugDosagePartDto=} [properties] Properties to set
         */
        function DrugDosagePartDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugDosagePartDto id.
         * @member {Uint8Array} id
         * @memberof DrugDosagePartDto
         * @instance
         */
        DrugDosagePartDto.prototype.id = $util.newBuffer([]);
    
        /**
         * DrugDosagePartDto measure.
         * @member {IMeasureDto|null|undefined} measure
         * @memberof DrugDosagePartDto
         * @instance
         */
        DrugDosagePartDto.prototype.measure = null;
    
        /**
         * DrugDosagePartDto value.
         * @member {string} value
         * @memberof DrugDosagePartDto
         * @instance
         */
        DrugDosagePartDto.prototype.value = "";
    
        /**
         * Creates a new DrugDosagePartDto instance using the specified properties.
         * @function create
         * @memberof DrugDosagePartDto
         * @static
         * @param {IDrugDosagePartDto=} [properties] Properties to set
         * @returns {DrugDosagePartDto} DrugDosagePartDto instance
         */
        DrugDosagePartDto.create = function create(properties) {
            return new DrugDosagePartDto(properties);
        };
    
        /**
         * Encodes the specified DrugDosagePartDto message. Does not implicitly {@link DrugDosagePartDto.verify|verify} messages.
         * @function encode
         * @memberof DrugDosagePartDto
         * @static
         * @param {IDrugDosagePartDto} message DrugDosagePartDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugDosagePartDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.measure != null && Object.hasOwnProperty.call(message, "measure"))
                $root.MeasureDto.encode(message.measure, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.value);
            return writer;
        };
    
        /**
         * Encodes the specified DrugDosagePartDto message, length delimited. Does not implicitly {@link DrugDosagePartDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugDosagePartDto
         * @static
         * @param {IDrugDosagePartDto} message DrugDosagePartDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugDosagePartDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugDosagePartDto message from the specified reader or buffer.
         * @function decode
         * @memberof DrugDosagePartDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugDosagePartDto} DrugDosagePartDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugDosagePartDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugDosagePartDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.measure = $root.MeasureDto.decode(reader, reader.uint32());
                    break;
                case 3:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugDosagePartDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugDosagePartDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugDosagePartDto} DrugDosagePartDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugDosagePartDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugDosagePartDto message.
         * @function verify
         * @memberof DrugDosagePartDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugDosagePartDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.measure != null && message.hasOwnProperty("measure")) {
                var error = $root.MeasureDto.verify(message.measure);
                if (error)
                    return "measure." + error;
            }
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };
    
        /**
         * Creates a DrugDosagePartDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugDosagePartDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugDosagePartDto} DrugDosagePartDto
         */
        DrugDosagePartDto.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugDosagePartDto)
                return object;
            var message = new $root.DrugDosagePartDto();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.measure != null) {
                if (typeof object.measure !== "object")
                    throw TypeError(".DrugDosagePartDto.measure: object expected");
                message.measure = $root.MeasureDto.fromObject(object.measure);
            }
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };
    
        /**
         * Creates a plain object from a DrugDosagePartDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugDosagePartDto
         * @static
         * @param {DrugDosagePartDto} message DrugDosagePartDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugDosagePartDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.measure = null;
                object.value = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.measure != null && message.hasOwnProperty("measure"))
                object.measure = $root.MeasureDto.toObject(message.measure, options);
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };
    
        /**
         * Converts this DrugDosagePartDto to JSON.
         * @function toJSON
         * @memberof DrugDosagePartDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugDosagePartDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugDosagePartDto;
    })();
    
    $root.MeasureDto = (function() {
    
        /**
         * Properties of a MeasureDto.
         * @exports IMeasureDto
         * @interface IMeasureDto
         * @property {Uint8Array|null} [id] MeasureDto id
         * @property {string|null} [name] MeasureDto name
         */
    
        /**
         * Constructs a new MeasureDto.
         * @exports MeasureDto
         * @classdesc Represents a MeasureDto.
         * @implements IMeasureDto
         * @constructor
         * @param {IMeasureDto=} [properties] Properties to set
         */
        function MeasureDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MeasureDto id.
         * @member {Uint8Array} id
         * @memberof MeasureDto
         * @instance
         */
        MeasureDto.prototype.id = $util.newBuffer([]);
    
        /**
         * MeasureDto name.
         * @member {string} name
         * @memberof MeasureDto
         * @instance
         */
        MeasureDto.prototype.name = "";
    
        /**
         * Creates a new MeasureDto instance using the specified properties.
         * @function create
         * @memberof MeasureDto
         * @static
         * @param {IMeasureDto=} [properties] Properties to set
         * @returns {MeasureDto} MeasureDto instance
         */
        MeasureDto.create = function create(properties) {
            return new MeasureDto(properties);
        };
    
        /**
         * Encodes the specified MeasureDto message. Does not implicitly {@link MeasureDto.verify|verify} messages.
         * @function encode
         * @memberof MeasureDto
         * @static
         * @param {IMeasureDto} message MeasureDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeasureDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified MeasureDto message, length delimited. Does not implicitly {@link MeasureDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MeasureDto
         * @static
         * @param {IMeasureDto} message MeasureDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeasureDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MeasureDto message from the specified reader or buffer.
         * @function decode
         * @memberof MeasureDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MeasureDto} MeasureDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeasureDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MeasureDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MeasureDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MeasureDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MeasureDto} MeasureDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeasureDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MeasureDto message.
         * @function verify
         * @memberof MeasureDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MeasureDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a MeasureDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MeasureDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MeasureDto} MeasureDto
         */
        MeasureDto.fromObject = function fromObject(object) {
            if (object instanceof $root.MeasureDto)
                return object;
            var message = new $root.MeasureDto();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a MeasureDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MeasureDto
         * @static
         * @param {MeasureDto} message MeasureDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeasureDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this MeasureDto to JSON.
         * @function toJSON
         * @memberof MeasureDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeasureDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MeasureDto;
    })();
    
    $root.MeasureSearchResultDto = (function() {
    
        /**
         * Properties of a MeasureSearchResultDto.
         * @exports IMeasureSearchResultDto
         * @interface IMeasureSearchResultDto
         * @property {number|null} [rowCount] MeasureSearchResultDto rowCount
         * @property {Array.<IMeasureDto>|null} [items] MeasureSearchResultDto items
         */
    
        /**
         * Constructs a new MeasureSearchResultDto.
         * @exports MeasureSearchResultDto
         * @classdesc Represents a MeasureSearchResultDto.
         * @implements IMeasureSearchResultDto
         * @constructor
         * @param {IMeasureSearchResultDto=} [properties] Properties to set
         */
        function MeasureSearchResultDto(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * MeasureSearchResultDto rowCount.
         * @member {number} rowCount
         * @memberof MeasureSearchResultDto
         * @instance
         */
        MeasureSearchResultDto.prototype.rowCount = 0;
    
        /**
         * MeasureSearchResultDto items.
         * @member {Array.<IMeasureDto>} items
         * @memberof MeasureSearchResultDto
         * @instance
         */
        MeasureSearchResultDto.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new MeasureSearchResultDto instance using the specified properties.
         * @function create
         * @memberof MeasureSearchResultDto
         * @static
         * @param {IMeasureSearchResultDto=} [properties] Properties to set
         * @returns {MeasureSearchResultDto} MeasureSearchResultDto instance
         */
        MeasureSearchResultDto.create = function create(properties) {
            return new MeasureSearchResultDto(properties);
        };
    
        /**
         * Encodes the specified MeasureSearchResultDto message. Does not implicitly {@link MeasureSearchResultDto.verify|verify} messages.
         * @function encode
         * @memberof MeasureSearchResultDto
         * @static
         * @param {IMeasureSearchResultDto} message MeasureSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeasureSearchResultDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.MeasureDto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified MeasureSearchResultDto message, length delimited. Does not implicitly {@link MeasureSearchResultDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof MeasureSearchResultDto
         * @static
         * @param {IMeasureSearchResultDto} message MeasureSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        MeasureSearchResultDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a MeasureSearchResultDto message from the specified reader or buffer.
         * @function decode
         * @memberof MeasureSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {MeasureSearchResultDto} MeasureSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeasureSearchResultDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.MeasureSearchResultDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.MeasureDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a MeasureSearchResultDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof MeasureSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {MeasureSearchResultDto} MeasureSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        MeasureSearchResultDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a MeasureSearchResultDto message.
         * @function verify
         * @memberof MeasureSearchResultDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        MeasureSearchResultDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.MeasureDto.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a MeasureSearchResultDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof MeasureSearchResultDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {MeasureSearchResultDto} MeasureSearchResultDto
         */
        MeasureSearchResultDto.fromObject = function fromObject(object) {
            if (object instanceof $root.MeasureSearchResultDto)
                return object;
            var message = new $root.MeasureSearchResultDto();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".MeasureSearchResultDto.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".MeasureSearchResultDto.items: object expected");
                    message.items[i] = $root.MeasureDto.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a MeasureSearchResultDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof MeasureSearchResultDto
         * @static
         * @param {MeasureSearchResultDto} message MeasureSearchResultDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        MeasureSearchResultDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.MeasureDto.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this MeasureSearchResultDto to JSON.
         * @function toJSON
         * @memberof MeasureSearchResultDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        MeasureSearchResultDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return MeasureSearchResultDto;
    })();
    
    $root.AtcGroupDto = (function() {
    
        /**
         * Properties of an AtcGroupDto.
         * @exports IAtcGroupDto
         * @interface IAtcGroupDto
         * @property {Uint8Array|null} [id] AtcGroupDto id
         * @property {string|null} [code] AtcGroupDto code
         */
    
        /**
         * Constructs a new AtcGroupDto.
         * @exports AtcGroupDto
         * @classdesc Represents an AtcGroupDto.
         * @implements IAtcGroupDto
         * @constructor
         * @param {IAtcGroupDto=} [properties] Properties to set
         */
        function AtcGroupDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AtcGroupDto id.
         * @member {Uint8Array} id
         * @memberof AtcGroupDto
         * @instance
         */
        AtcGroupDto.prototype.id = $util.newBuffer([]);
    
        /**
         * AtcGroupDto code.
         * @member {string} code
         * @memberof AtcGroupDto
         * @instance
         */
        AtcGroupDto.prototype.code = "";
    
        /**
         * Creates a new AtcGroupDto instance using the specified properties.
         * @function create
         * @memberof AtcGroupDto
         * @static
         * @param {IAtcGroupDto=} [properties] Properties to set
         * @returns {AtcGroupDto} AtcGroupDto instance
         */
        AtcGroupDto.create = function create(properties) {
            return new AtcGroupDto(properties);
        };
    
        /**
         * Encodes the specified AtcGroupDto message. Does not implicitly {@link AtcGroupDto.verify|verify} messages.
         * @function encode
         * @memberof AtcGroupDto
         * @static
         * @param {IAtcGroupDto} message AtcGroupDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AtcGroupDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.code);
            return writer;
        };
    
        /**
         * Encodes the specified AtcGroupDto message, length delimited. Does not implicitly {@link AtcGroupDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AtcGroupDto
         * @static
         * @param {IAtcGroupDto} message AtcGroupDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AtcGroupDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AtcGroupDto message from the specified reader or buffer.
         * @function decode
         * @memberof AtcGroupDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AtcGroupDto} AtcGroupDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AtcGroupDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AtcGroupDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.code = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AtcGroupDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AtcGroupDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AtcGroupDto} AtcGroupDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AtcGroupDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AtcGroupDto message.
         * @function verify
         * @memberof AtcGroupDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AtcGroupDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.code != null && message.hasOwnProperty("code"))
                if (!$util.isString(message.code))
                    return "code: string expected";
            return null;
        };
    
        /**
         * Creates an AtcGroupDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AtcGroupDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AtcGroupDto} AtcGroupDto
         */
        AtcGroupDto.fromObject = function fromObject(object) {
            if (object instanceof $root.AtcGroupDto)
                return object;
            var message = new $root.AtcGroupDto();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.code != null)
                message.code = String(object.code);
            return message;
        };
    
        /**
         * Creates a plain object from an AtcGroupDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AtcGroupDto
         * @static
         * @param {AtcGroupDto} message AtcGroupDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AtcGroupDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.code = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.code != null && message.hasOwnProperty("code"))
                object.code = message.code;
            return object;
        };
    
        /**
         * Converts this AtcGroupDto to JSON.
         * @function toJSON
         * @memberof AtcGroupDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AtcGroupDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AtcGroupDto;
    })();
    
    $root.AtcGroupSearchResultDto = (function() {
    
        /**
         * Properties of an AtcGroupSearchResultDto.
         * @exports IAtcGroupSearchResultDto
         * @interface IAtcGroupSearchResultDto
         * @property {number|null} [rowCount] AtcGroupSearchResultDto rowCount
         * @property {Array.<IAtcGroupDto>|null} [items] AtcGroupSearchResultDto items
         */
    
        /**
         * Constructs a new AtcGroupSearchResultDto.
         * @exports AtcGroupSearchResultDto
         * @classdesc Represents an AtcGroupSearchResultDto.
         * @implements IAtcGroupSearchResultDto
         * @constructor
         * @param {IAtcGroupSearchResultDto=} [properties] Properties to set
         */
        function AtcGroupSearchResultDto(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AtcGroupSearchResultDto rowCount.
         * @member {number} rowCount
         * @memberof AtcGroupSearchResultDto
         * @instance
         */
        AtcGroupSearchResultDto.prototype.rowCount = 0;
    
        /**
         * AtcGroupSearchResultDto items.
         * @member {Array.<IAtcGroupDto>} items
         * @memberof AtcGroupSearchResultDto
         * @instance
         */
        AtcGroupSearchResultDto.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new AtcGroupSearchResultDto instance using the specified properties.
         * @function create
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {IAtcGroupSearchResultDto=} [properties] Properties to set
         * @returns {AtcGroupSearchResultDto} AtcGroupSearchResultDto instance
         */
        AtcGroupSearchResultDto.create = function create(properties) {
            return new AtcGroupSearchResultDto(properties);
        };
    
        /**
         * Encodes the specified AtcGroupSearchResultDto message. Does not implicitly {@link AtcGroupSearchResultDto.verify|verify} messages.
         * @function encode
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {IAtcGroupSearchResultDto} message AtcGroupSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AtcGroupSearchResultDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.AtcGroupDto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified AtcGroupSearchResultDto message, length delimited. Does not implicitly {@link AtcGroupSearchResultDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {IAtcGroupSearchResultDto} message AtcGroupSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AtcGroupSearchResultDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AtcGroupSearchResultDto message from the specified reader or buffer.
         * @function decode
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AtcGroupSearchResultDto} AtcGroupSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AtcGroupSearchResultDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AtcGroupSearchResultDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.AtcGroupDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AtcGroupSearchResultDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AtcGroupSearchResultDto} AtcGroupSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AtcGroupSearchResultDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AtcGroupSearchResultDto message.
         * @function verify
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AtcGroupSearchResultDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.AtcGroupDto.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates an AtcGroupSearchResultDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AtcGroupSearchResultDto} AtcGroupSearchResultDto
         */
        AtcGroupSearchResultDto.fromObject = function fromObject(object) {
            if (object instanceof $root.AtcGroupSearchResultDto)
                return object;
            var message = new $root.AtcGroupSearchResultDto();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".AtcGroupSearchResultDto.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".AtcGroupSearchResultDto.items: object expected");
                    message.items[i] = $root.AtcGroupDto.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from an AtcGroupSearchResultDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AtcGroupSearchResultDto
         * @static
         * @param {AtcGroupSearchResultDto} message AtcGroupSearchResultDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AtcGroupSearchResultDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.AtcGroupDto.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this AtcGroupSearchResultDto to JSON.
         * @function toJSON
         * @memberof AtcGroupSearchResultDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AtcGroupSearchResultDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AtcGroupSearchResultDto;
    })();
    
    $root.PtgDto = (function() {
    
        /**
         * Properties of a PtgDto.
         * @exports IPtgDto
         * @interface IPtgDto
         * @property {Uint8Array|null} [id] PtgDto id
         * @property {string|null} [name] PtgDto name
         */
    
        /**
         * Constructs a new PtgDto.
         * @exports PtgDto
         * @classdesc Represents a PtgDto.
         * @implements IPtgDto
         * @constructor
         * @param {IPtgDto=} [properties] Properties to set
         */
        function PtgDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * PtgDto id.
         * @member {Uint8Array} id
         * @memberof PtgDto
         * @instance
         */
        PtgDto.prototype.id = $util.newBuffer([]);
    
        /**
         * PtgDto name.
         * @member {string} name
         * @memberof PtgDto
         * @instance
         */
        PtgDto.prototype.name = "";
    
        /**
         * Creates a new PtgDto instance using the specified properties.
         * @function create
         * @memberof PtgDto
         * @static
         * @param {IPtgDto=} [properties] Properties to set
         * @returns {PtgDto} PtgDto instance
         */
        PtgDto.create = function create(properties) {
            return new PtgDto(properties);
        };
    
        /**
         * Encodes the specified PtgDto message. Does not implicitly {@link PtgDto.verify|verify} messages.
         * @function encode
         * @memberof PtgDto
         * @static
         * @param {IPtgDto} message PtgDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PtgDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified PtgDto message, length delimited. Does not implicitly {@link PtgDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PtgDto
         * @static
         * @param {IPtgDto} message PtgDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PtgDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a PtgDto message from the specified reader or buffer.
         * @function decode
         * @memberof PtgDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PtgDto} PtgDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PtgDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PtgDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a PtgDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PtgDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PtgDto} PtgDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PtgDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a PtgDto message.
         * @function verify
         * @memberof PtgDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PtgDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a PtgDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PtgDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PtgDto} PtgDto
         */
        PtgDto.fromObject = function fromObject(object) {
            if (object instanceof $root.PtgDto)
                return object;
            var message = new $root.PtgDto();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a PtgDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PtgDto
         * @static
         * @param {PtgDto} message PtgDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PtgDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this PtgDto to JSON.
         * @function toJSON
         * @memberof PtgDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PtgDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return PtgDto;
    })();
    
    $root.PtgSearchResultDto = (function() {
    
        /**
         * Properties of a PtgSearchResultDto.
         * @exports IPtgSearchResultDto
         * @interface IPtgSearchResultDto
         * @property {number|null} [rowCount] PtgSearchResultDto rowCount
         * @property {Array.<IPtgDto>|null} [items] PtgSearchResultDto items
         */
    
        /**
         * Constructs a new PtgSearchResultDto.
         * @exports PtgSearchResultDto
         * @classdesc Represents a PtgSearchResultDto.
         * @implements IPtgSearchResultDto
         * @constructor
         * @param {IPtgSearchResultDto=} [properties] Properties to set
         */
        function PtgSearchResultDto(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * PtgSearchResultDto rowCount.
         * @member {number} rowCount
         * @memberof PtgSearchResultDto
         * @instance
         */
        PtgSearchResultDto.prototype.rowCount = 0;
    
        /**
         * PtgSearchResultDto items.
         * @member {Array.<IPtgDto>} items
         * @memberof PtgSearchResultDto
         * @instance
         */
        PtgSearchResultDto.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new PtgSearchResultDto instance using the specified properties.
         * @function create
         * @memberof PtgSearchResultDto
         * @static
         * @param {IPtgSearchResultDto=} [properties] Properties to set
         * @returns {PtgSearchResultDto} PtgSearchResultDto instance
         */
        PtgSearchResultDto.create = function create(properties) {
            return new PtgSearchResultDto(properties);
        };
    
        /**
         * Encodes the specified PtgSearchResultDto message. Does not implicitly {@link PtgSearchResultDto.verify|verify} messages.
         * @function encode
         * @memberof PtgSearchResultDto
         * @static
         * @param {IPtgSearchResultDto} message PtgSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PtgSearchResultDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PtgDto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified PtgSearchResultDto message, length delimited. Does not implicitly {@link PtgSearchResultDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PtgSearchResultDto
         * @static
         * @param {IPtgSearchResultDto} message PtgSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PtgSearchResultDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a PtgSearchResultDto message from the specified reader or buffer.
         * @function decode
         * @memberof PtgSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PtgSearchResultDto} PtgSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PtgSearchResultDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PtgSearchResultDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PtgDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a PtgSearchResultDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PtgSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PtgSearchResultDto} PtgSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PtgSearchResultDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a PtgSearchResultDto message.
         * @function verify
         * @memberof PtgSearchResultDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PtgSearchResultDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.PtgDto.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a PtgSearchResultDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PtgSearchResultDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PtgSearchResultDto} PtgSearchResultDto
         */
        PtgSearchResultDto.fromObject = function fromObject(object) {
            if (object instanceof $root.PtgSearchResultDto)
                return object;
            var message = new $root.PtgSearchResultDto();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".PtgSearchResultDto.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".PtgSearchResultDto.items: object expected");
                    message.items[i] = $root.PtgDto.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a PtgSearchResultDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PtgSearchResultDto
         * @static
         * @param {PtgSearchResultDto} message PtgSearchResultDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PtgSearchResultDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.PtgDto.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this PtgSearchResultDto to JSON.
         * @function toJSON
         * @memberof PtgSearchResultDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PtgSearchResultDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return PtgSearchResultDto;
    })();
    
    $root.DrugFormFullDto = (function() {
    
        /**
         * Properties of a DrugFormFullDto.
         * @exports IDrugFormFullDto
         * @interface IDrugFormFullDto
         * @property {Uint8Array|null} [id] DrugFormFullDto id
         * @property {string|null} [name] DrugFormFullDto name
         */
    
        /**
         * Constructs a new DrugFormFullDto.
         * @exports DrugFormFullDto
         * @classdesc Represents a DrugFormFullDto.
         * @implements IDrugFormFullDto
         * @constructor
         * @param {IDrugFormFullDto=} [properties] Properties to set
         */
        function DrugFormFullDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugFormFullDto id.
         * @member {Uint8Array} id
         * @memberof DrugFormFullDto
         * @instance
         */
        DrugFormFullDto.prototype.id = $util.newBuffer([]);
    
        /**
         * DrugFormFullDto name.
         * @member {string} name
         * @memberof DrugFormFullDto
         * @instance
         */
        DrugFormFullDto.prototype.name = "";
    
        /**
         * Creates a new DrugFormFullDto instance using the specified properties.
         * @function create
         * @memberof DrugFormFullDto
         * @static
         * @param {IDrugFormFullDto=} [properties] Properties to set
         * @returns {DrugFormFullDto} DrugFormFullDto instance
         */
        DrugFormFullDto.create = function create(properties) {
            return new DrugFormFullDto(properties);
        };
    
        /**
         * Encodes the specified DrugFormFullDto message. Does not implicitly {@link DrugFormFullDto.verify|verify} messages.
         * @function encode
         * @memberof DrugFormFullDto
         * @static
         * @param {IDrugFormFullDto} message DrugFormFullDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugFormFullDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified DrugFormFullDto message, length delimited. Does not implicitly {@link DrugFormFullDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugFormFullDto
         * @static
         * @param {IDrugFormFullDto} message DrugFormFullDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugFormFullDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugFormFullDto message from the specified reader or buffer.
         * @function decode
         * @memberof DrugFormFullDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugFormFullDto} DrugFormFullDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugFormFullDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugFormFullDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugFormFullDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugFormFullDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugFormFullDto} DrugFormFullDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugFormFullDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugFormFullDto message.
         * @function verify
         * @memberof DrugFormFullDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugFormFullDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a DrugFormFullDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugFormFullDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugFormFullDto} DrugFormFullDto
         */
        DrugFormFullDto.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugFormFullDto)
                return object;
            var message = new $root.DrugFormFullDto();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a DrugFormFullDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugFormFullDto
         * @static
         * @param {DrugFormFullDto} message DrugFormFullDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugFormFullDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this DrugFormFullDto to JSON.
         * @function toJSON
         * @memberof DrugFormFullDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugFormFullDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugFormFullDto;
    })();
    
    $root.DrugFormFullSearchResultDto = (function() {
    
        /**
         * Properties of a DrugFormFullSearchResultDto.
         * @exports IDrugFormFullSearchResultDto
         * @interface IDrugFormFullSearchResultDto
         * @property {number|null} [rowCount] DrugFormFullSearchResultDto rowCount
         * @property {Array.<IDrugFormFullDto>|null} [items] DrugFormFullSearchResultDto items
         */
    
        /**
         * Constructs a new DrugFormFullSearchResultDto.
         * @exports DrugFormFullSearchResultDto
         * @classdesc Represents a DrugFormFullSearchResultDto.
         * @implements IDrugFormFullSearchResultDto
         * @constructor
         * @param {IDrugFormFullSearchResultDto=} [properties] Properties to set
         */
        function DrugFormFullSearchResultDto(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugFormFullSearchResultDto rowCount.
         * @member {number} rowCount
         * @memberof DrugFormFullSearchResultDto
         * @instance
         */
        DrugFormFullSearchResultDto.prototype.rowCount = 0;
    
        /**
         * DrugFormFullSearchResultDto items.
         * @member {Array.<IDrugFormFullDto>} items
         * @memberof DrugFormFullSearchResultDto
         * @instance
         */
        DrugFormFullSearchResultDto.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new DrugFormFullSearchResultDto instance using the specified properties.
         * @function create
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {IDrugFormFullSearchResultDto=} [properties] Properties to set
         * @returns {DrugFormFullSearchResultDto} DrugFormFullSearchResultDto instance
         */
        DrugFormFullSearchResultDto.create = function create(properties) {
            return new DrugFormFullSearchResultDto(properties);
        };
    
        /**
         * Encodes the specified DrugFormFullSearchResultDto message. Does not implicitly {@link DrugFormFullSearchResultDto.verify|verify} messages.
         * @function encode
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {IDrugFormFullSearchResultDto} message DrugFormFullSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugFormFullSearchResultDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.DrugFormFullDto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified DrugFormFullSearchResultDto message, length delimited. Does not implicitly {@link DrugFormFullSearchResultDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {IDrugFormFullSearchResultDto} message DrugFormFullSearchResultDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugFormFullSearchResultDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugFormFullSearchResultDto message from the specified reader or buffer.
         * @function decode
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugFormFullSearchResultDto} DrugFormFullSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugFormFullSearchResultDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugFormFullSearchResultDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.DrugFormFullDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugFormFullSearchResultDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugFormFullSearchResultDto} DrugFormFullSearchResultDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugFormFullSearchResultDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugFormFullSearchResultDto message.
         * @function verify
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugFormFullSearchResultDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.DrugFormFullDto.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a DrugFormFullSearchResultDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugFormFullSearchResultDto} DrugFormFullSearchResultDto
         */
        DrugFormFullSearchResultDto.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugFormFullSearchResultDto)
                return object;
            var message = new $root.DrugFormFullSearchResultDto();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".DrugFormFullSearchResultDto.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".DrugFormFullSearchResultDto.items: object expected");
                    message.items[i] = $root.DrugFormFullDto.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a DrugFormFullSearchResultDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugFormFullSearchResultDto
         * @static
         * @param {DrugFormFullSearchResultDto} message DrugFormFullSearchResultDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugFormFullSearchResultDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.DrugFormFullDto.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this DrugFormFullSearchResultDto to JSON.
         * @function toJSON
         * @memberof DrugFormFullSearchResultDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugFormFullSearchResultDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugFormFullSearchResultDto;
    })();
    
    $root.SelectedDto = (function() {
    
        /**
         * Properties of a SelectedDto.
         * @exports ISelectedDto
         * @interface ISelectedDto
         * @property {Uint8Array|null} [id] SelectedDto id
         * @property {string|null} [value] SelectedDto value
         */
    
        /**
         * Constructs a new SelectedDto.
         * @exports SelectedDto
         * @classdesc Represents a SelectedDto.
         * @implements ISelectedDto
         * @constructor
         * @param {ISelectedDto=} [properties] Properties to set
         */
        function SelectedDto(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * SelectedDto id.
         * @member {Uint8Array} id
         * @memberof SelectedDto
         * @instance
         */
        SelectedDto.prototype.id = $util.newBuffer([]);
    
        /**
         * SelectedDto value.
         * @member {string} value
         * @memberof SelectedDto
         * @instance
         */
        SelectedDto.prototype.value = "";
    
        /**
         * Creates a new SelectedDto instance using the specified properties.
         * @function create
         * @memberof SelectedDto
         * @static
         * @param {ISelectedDto=} [properties] Properties to set
         * @returns {SelectedDto} SelectedDto instance
         */
        SelectedDto.create = function create(properties) {
            return new SelectedDto(properties);
        };
    
        /**
         * Encodes the specified SelectedDto message. Does not implicitly {@link SelectedDto.verify|verify} messages.
         * @function encode
         * @memberof SelectedDto
         * @static
         * @param {ISelectedDto} message SelectedDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectedDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.value);
            return writer;
        };
    
        /**
         * Encodes the specified SelectedDto message, length delimited. Does not implicitly {@link SelectedDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SelectedDto
         * @static
         * @param {ISelectedDto} message SelectedDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SelectedDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a SelectedDto message from the specified reader or buffer.
         * @function decode
         * @memberof SelectedDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SelectedDto} SelectedDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectedDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SelectedDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.value = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a SelectedDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SelectedDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SelectedDto} SelectedDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SelectedDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a SelectedDto message.
         * @function verify
         * @memberof SelectedDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SelectedDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.value != null && message.hasOwnProperty("value"))
                if (!$util.isString(message.value))
                    return "value: string expected";
            return null;
        };
    
        /**
         * Creates a SelectedDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SelectedDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SelectedDto} SelectedDto
         */
        SelectedDto.fromObject = function fromObject(object) {
            if (object instanceof $root.SelectedDto)
                return object;
            var message = new $root.SelectedDto();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.value != null)
                message.value = String(object.value);
            return message;
        };
    
        /**
         * Creates a plain object from a SelectedDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SelectedDto
         * @static
         * @param {SelectedDto} message SelectedDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SelectedDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.value = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.value != null && message.hasOwnProperty("value"))
                object.value = message.value;
            return object;
        };
    
        /**
         * Converts this SelectedDto to JSON.
         * @function toJSON
         * @memberof SelectedDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SelectedDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return SelectedDto;
    })();
    
    $root.SearchDto = (function() {
    
        /**
         * Properties of a SearchDto.
         * @exports ISearchDto
         * @interface ISearchDto
         * @property {number|null} [rowCount] SearchDto rowCount
         * @property {Array.<ISelectedDto>|null} [items] SearchDto items
         */
    
        /**
         * Constructs a new SearchDto.
         * @exports SearchDto
         * @classdesc Represents a SearchDto.
         * @implements ISearchDto
         * @constructor
         * @param {ISearchDto=} [properties] Properties to set
         */
        function SearchDto(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * SearchDto rowCount.
         * @member {number} rowCount
         * @memberof SearchDto
         * @instance
         */
        SearchDto.prototype.rowCount = 0;
    
        /**
         * SearchDto items.
         * @member {Array.<ISelectedDto>} items
         * @memberof SearchDto
         * @instance
         */
        SearchDto.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new SearchDto instance using the specified properties.
         * @function create
         * @memberof SearchDto
         * @static
         * @param {ISearchDto=} [properties] Properties to set
         * @returns {SearchDto} SearchDto instance
         */
        SearchDto.create = function create(properties) {
            return new SearchDto(properties);
        };
    
        /**
         * Encodes the specified SearchDto message. Does not implicitly {@link SearchDto.verify|verify} messages.
         * @function encode
         * @memberof SearchDto
         * @static
         * @param {ISearchDto} message SearchDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchDto.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.SelectedDto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified SearchDto message, length delimited. Does not implicitly {@link SearchDto.verify|verify} messages.
         * @function encodeDelimited
         * @memberof SearchDto
         * @static
         * @param {ISearchDto} message SearchDto message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        SearchDto.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a SearchDto message from the specified reader or buffer.
         * @function decode
         * @memberof SearchDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {SearchDto} SearchDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchDto.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.SearchDto();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.SelectedDto.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a SearchDto message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof SearchDto
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {SearchDto} SearchDto
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        SearchDto.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a SearchDto message.
         * @function verify
         * @memberof SearchDto
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        SearchDto.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.SelectedDto.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a SearchDto message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof SearchDto
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {SearchDto} SearchDto
         */
        SearchDto.fromObject = function fromObject(object) {
            if (object instanceof $root.SearchDto)
                return object;
            var message = new $root.SearchDto();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".SearchDto.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".SearchDto.items: object expected");
                    message.items[i] = $root.SelectedDto.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a SearchDto message. Also converts values to other types if specified.
         * @function toObject
         * @memberof SearchDto
         * @static
         * @param {SearchDto} message SearchDto
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        SearchDto.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.SelectedDto.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this SearchDto to JSON.
         * @function toJSON
         * @memberof SearchDto
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        SearchDto.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return SearchDto;
    })();
    
    $root.DrugCreateResponse = (function() {
    
        /**
         * Properties of a DrugCreateResponse.
         * @exports IDrugCreateResponse
         * @interface IDrugCreateResponse
         * @property {boolean|null} [success] DrugCreateResponse success
         * @property {string|null} [message] DrugCreateResponse message
         * @property {IDrugFormDto|null} [payload] DrugCreateResponse payload
         */
    
        /**
         * Constructs a new DrugCreateResponse.
         * @exports DrugCreateResponse
         * @classdesc Represents a DrugCreateResponse.
         * @implements IDrugCreateResponse
         * @constructor
         * @param {IDrugCreateResponse=} [properties] Properties to set
         */
        function DrugCreateResponse(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * DrugCreateResponse success.
         * @member {boolean} success
         * @memberof DrugCreateResponse
         * @instance
         */
        DrugCreateResponse.prototype.success = false;
    
        /**
         * DrugCreateResponse message.
         * @member {string} message
         * @memberof DrugCreateResponse
         * @instance
         */
        DrugCreateResponse.prototype.message = "";
    
        /**
         * DrugCreateResponse payload.
         * @member {IDrugFormDto|null|undefined} payload
         * @memberof DrugCreateResponse
         * @instance
         */
        DrugCreateResponse.prototype.payload = null;
    
        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;
    
        /**
         * DrugCreateResponse _payload.
         * @member {"payload"|undefined} _payload
         * @memberof DrugCreateResponse
         * @instance
         */
        Object.defineProperty(DrugCreateResponse.prototype, "_payload", {
            get: $util.oneOfGetter($oneOfFields = ["payload"]),
            set: $util.oneOfSetter($oneOfFields)
        });
    
        /**
         * Creates a new DrugCreateResponse instance using the specified properties.
         * @function create
         * @memberof DrugCreateResponse
         * @static
         * @param {IDrugCreateResponse=} [properties] Properties to set
         * @returns {DrugCreateResponse} DrugCreateResponse instance
         */
        DrugCreateResponse.create = function create(properties) {
            return new DrugCreateResponse(properties);
        };
    
        /**
         * Encodes the specified DrugCreateResponse message. Does not implicitly {@link DrugCreateResponse.verify|verify} messages.
         * @function encode
         * @memberof DrugCreateResponse
         * @static
         * @param {IDrugCreateResponse} message DrugCreateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugCreateResponse.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.success != null && Object.hasOwnProperty.call(message, "success"))
                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.success);
            if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
            if (message.payload != null && Object.hasOwnProperty.call(message, "payload"))
                $root.DrugFormDto.encode(message.payload, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified DrugCreateResponse message, length delimited. Does not implicitly {@link DrugCreateResponse.verify|verify} messages.
         * @function encodeDelimited
         * @memberof DrugCreateResponse
         * @static
         * @param {IDrugCreateResponse} message DrugCreateResponse message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        DrugCreateResponse.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a DrugCreateResponse message from the specified reader or buffer.
         * @function decode
         * @memberof DrugCreateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {DrugCreateResponse} DrugCreateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugCreateResponse.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DrugCreateResponse();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.success = reader.bool();
                    break;
                case 2:
                    message.message = reader.string();
                    break;
                case 3:
                    message.payload = $root.DrugFormDto.decode(reader, reader.uint32());
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a DrugCreateResponse message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof DrugCreateResponse
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {DrugCreateResponse} DrugCreateResponse
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        DrugCreateResponse.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a DrugCreateResponse message.
         * @function verify
         * @memberof DrugCreateResponse
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        DrugCreateResponse.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            var properties = {};
            if (message.success != null && message.hasOwnProperty("success"))
                if (typeof message.success !== "boolean")
                    return "success: boolean expected";
            if (message.message != null && message.hasOwnProperty("message"))
                if (!$util.isString(message.message))
                    return "message: string expected";
            if (message.payload != null && message.hasOwnProperty("payload")) {
                properties._payload = 1;
                {
                    var error = $root.DrugFormDto.verify(message.payload);
                    if (error)
                        return "payload." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a DrugCreateResponse message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof DrugCreateResponse
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {DrugCreateResponse} DrugCreateResponse
         */
        DrugCreateResponse.fromObject = function fromObject(object) {
            if (object instanceof $root.DrugCreateResponse)
                return object;
            var message = new $root.DrugCreateResponse();
            if (object.success != null)
                message.success = Boolean(object.success);
            if (object.message != null)
                message.message = String(object.message);
            if (object.payload != null) {
                if (typeof object.payload !== "object")
                    throw TypeError(".DrugCreateResponse.payload: object expected");
                message.payload = $root.DrugFormDto.fromObject(object.payload);
            }
            return message;
        };
    
        /**
         * Creates a plain object from a DrugCreateResponse message. Also converts values to other types if specified.
         * @function toObject
         * @memberof DrugCreateResponse
         * @static
         * @param {DrugCreateResponse} message DrugCreateResponse
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        DrugCreateResponse.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.success = false;
                object.message = "";
            }
            if (message.success != null && message.hasOwnProperty("success"))
                object.success = message.success;
            if (message.message != null && message.hasOwnProperty("message"))
                object.message = message.message;
            if (message.payload != null && message.hasOwnProperty("payload")) {
                object.payload = $root.DrugFormDto.toObject(message.payload, options);
                if (options.oneofs)
                    object._payload = "payload";
            }
            return object;
        };
    
        /**
         * Converts this DrugCreateResponse to JSON.
         * @function toJSON
         * @memberof DrugCreateResponse
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        DrugCreateResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return DrugCreateResponse;
    })();
    
    $root.Country = (function() {
    
        /**
         * Properties of a Country.
         * @exports ICountry
         * @interface ICountry
         * @property {Uint8Array|null} [id] Country id
         * @property {string|null} [name] Country name
         */
    
        /**
         * Constructs a new Country.
         * @exports Country
         * @classdesc Represents a Country.
         * @implements ICountry
         * @constructor
         * @param {ICountry=} [properties] Properties to set
         */
        function Country(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Country id.
         * @member {Uint8Array} id
         * @memberof Country
         * @instance
         */
        Country.prototype.id = $util.newBuffer([]);
    
        /**
         * Country name.
         * @member {string} name
         * @memberof Country
         * @instance
         */
        Country.prototype.name = "";
    
        /**
         * Creates a new Country instance using the specified properties.
         * @function create
         * @memberof Country
         * @static
         * @param {ICountry=} [properties] Properties to set
         * @returns {Country} Country instance
         */
        Country.create = function create(properties) {
            return new Country(properties);
        };
    
        /**
         * Encodes the specified Country message. Does not implicitly {@link Country.verify|verify} messages.
         * @function encode
         * @memberof Country
         * @static
         * @param {ICountry} message Country message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Country.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified Country message, length delimited. Does not implicitly {@link Country.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Country
         * @static
         * @param {ICountry} message Country message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Country.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Country message from the specified reader or buffer.
         * @function decode
         * @memberof Country
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Country} Country
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Country.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Country();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Country message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Country
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Country} Country
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Country.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Country message.
         * @function verify
         * @memberof Country
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Country.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a Country message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Country
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Country} Country
         */
        Country.fromObject = function fromObject(object) {
            if (object instanceof $root.Country)
                return object;
            var message = new $root.Country();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a Country message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Country
         * @static
         * @param {Country} message Country
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Country.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this Country to JSON.
         * @function toJSON
         * @memberof Country
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Country.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Country;
    })();
    
    $root.Countries = (function() {
    
        /**
         * Properties of a Countries.
         * @exports ICountries
         * @interface ICountries
         * @property {number|null} [rowCount] Countries rowCount
         * @property {Array.<ICountry>|null} [items] Countries items
         */
    
        /**
         * Constructs a new Countries.
         * @exports Countries
         * @classdesc Represents a Countries.
         * @implements ICountries
         * @constructor
         * @param {ICountries=} [properties] Properties to set
         */
        function Countries(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Countries rowCount.
         * @member {number} rowCount
         * @memberof Countries
         * @instance
         */
        Countries.prototype.rowCount = 0;
    
        /**
         * Countries items.
         * @member {Array.<ICountry>} items
         * @memberof Countries
         * @instance
         */
        Countries.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Countries instance using the specified properties.
         * @function create
         * @memberof Countries
         * @static
         * @param {ICountries=} [properties] Properties to set
         * @returns {Countries} Countries instance
         */
        Countries.create = function create(properties) {
            return new Countries(properties);
        };
    
        /**
         * Encodes the specified Countries message. Does not implicitly {@link Countries.verify|verify} messages.
         * @function encode
         * @memberof Countries
         * @static
         * @param {ICountries} message Countries message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Countries.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.Country.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Countries message, length delimited. Does not implicitly {@link Countries.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Countries
         * @static
         * @param {ICountries} message Countries message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Countries.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Countries message from the specified reader or buffer.
         * @function decode
         * @memberof Countries
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Countries} Countries
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Countries.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Countries();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.Country.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Countries message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Countries
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Countries} Countries
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Countries.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Countries message.
         * @function verify
         * @memberof Countries
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Countries.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.Country.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Countries message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Countries
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Countries} Countries
         */
        Countries.fromObject = function fromObject(object) {
            if (object instanceof $root.Countries)
                return object;
            var message = new $root.Countries();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Countries.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Countries.items: object expected");
                    message.items[i] = $root.Country.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Countries message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Countries
         * @static
         * @param {Countries} message Countries
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Countries.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.Country.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Countries to JSON.
         * @function toJSON
         * @memberof Countries
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Countries.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Countries;
    })();
    
    $root.Region = (function() {
    
        /**
         * Properties of a Region.
         * @exports IRegion
         * @interface IRegion
         * @property {Uint8Array|null} [id] Region id
         * @property {string|null} [name] Region name
         */
    
        /**
         * Constructs a new Region.
         * @exports Region
         * @classdesc Represents a Region.
         * @implements IRegion
         * @constructor
         * @param {IRegion=} [properties] Properties to set
         */
        function Region(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Region id.
         * @member {Uint8Array} id
         * @memberof Region
         * @instance
         */
        Region.prototype.id = $util.newBuffer([]);
    
        /**
         * Region name.
         * @member {string} name
         * @memberof Region
         * @instance
         */
        Region.prototype.name = "";
    
        /**
         * Creates a new Region instance using the specified properties.
         * @function create
         * @memberof Region
         * @static
         * @param {IRegion=} [properties] Properties to set
         * @returns {Region} Region instance
         */
        Region.create = function create(properties) {
            return new Region(properties);
        };
    
        /**
         * Encodes the specified Region message. Does not implicitly {@link Region.verify|verify} messages.
         * @function encode
         * @memberof Region
         * @static
         * @param {IRegion} message Region message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Region.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified Region message, length delimited. Does not implicitly {@link Region.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Region
         * @static
         * @param {IRegion} message Region message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Region.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Region message from the specified reader or buffer.
         * @function decode
         * @memberof Region
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Region} Region
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Region.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Region();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Region message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Region
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Region} Region
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Region.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Region message.
         * @function verify
         * @memberof Region
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Region.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a Region message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Region
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Region} Region
         */
        Region.fromObject = function fromObject(object) {
            if (object instanceof $root.Region)
                return object;
            var message = new $root.Region();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a Region message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Region
         * @static
         * @param {Region} message Region
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Region.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this Region to JSON.
         * @function toJSON
         * @memberof Region
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Region.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Region;
    })();
    
    $root.Regions = (function() {
    
        /**
         * Properties of a Regions.
         * @exports IRegions
         * @interface IRegions
         * @property {number|null} [rowCount] Regions rowCount
         * @property {Array.<IRegion>|null} [items] Regions items
         */
    
        /**
         * Constructs a new Regions.
         * @exports Regions
         * @classdesc Represents a Regions.
         * @implements IRegions
         * @constructor
         * @param {IRegions=} [properties] Properties to set
         */
        function Regions(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Regions rowCount.
         * @member {number} rowCount
         * @memberof Regions
         * @instance
         */
        Regions.prototype.rowCount = 0;
    
        /**
         * Regions items.
         * @member {Array.<IRegion>} items
         * @memberof Regions
         * @instance
         */
        Regions.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Regions instance using the specified properties.
         * @function create
         * @memberof Regions
         * @static
         * @param {IRegions=} [properties] Properties to set
         * @returns {Regions} Regions instance
         */
        Regions.create = function create(properties) {
            return new Regions(properties);
        };
    
        /**
         * Encodes the specified Regions message. Does not implicitly {@link Regions.verify|verify} messages.
         * @function encode
         * @memberof Regions
         * @static
         * @param {IRegions} message Regions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Regions.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.Region.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Regions message, length delimited. Does not implicitly {@link Regions.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Regions
         * @static
         * @param {IRegions} message Regions message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Regions.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Regions message from the specified reader or buffer.
         * @function decode
         * @memberof Regions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Regions} Regions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Regions.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Regions();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.Region.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Regions message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Regions
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Regions} Regions
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Regions.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Regions message.
         * @function verify
         * @memberof Regions
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Regions.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.Region.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Regions message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Regions
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Regions} Regions
         */
        Regions.fromObject = function fromObject(object) {
            if (object instanceof $root.Regions)
                return object;
            var message = new $root.Regions();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Regions.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Regions.items: object expected");
                    message.items[i] = $root.Region.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Regions message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Regions
         * @static
         * @param {Regions} message Regions
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Regions.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.Region.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Regions to JSON.
         * @function toJSON
         * @memberof Regions
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Regions.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Regions;
    })();
    
    $root.Settlement = (function() {
    
        /**
         * Properties of a Settlement.
         * @exports ISettlement
         * @interface ISettlement
         * @property {Uint8Array|null} [id] Settlement id
         * @property {string|null} [name] Settlement name
         */
    
        /**
         * Constructs a new Settlement.
         * @exports Settlement
         * @classdesc Represents a Settlement.
         * @implements ISettlement
         * @constructor
         * @param {ISettlement=} [properties] Properties to set
         */
        function Settlement(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Settlement id.
         * @member {Uint8Array} id
         * @memberof Settlement
         * @instance
         */
        Settlement.prototype.id = $util.newBuffer([]);
    
        /**
         * Settlement name.
         * @member {string} name
         * @memberof Settlement
         * @instance
         */
        Settlement.prototype.name = "";
    
        /**
         * Creates a new Settlement instance using the specified properties.
         * @function create
         * @memberof Settlement
         * @static
         * @param {ISettlement=} [properties] Properties to set
         * @returns {Settlement} Settlement instance
         */
        Settlement.create = function create(properties) {
            return new Settlement(properties);
        };
    
        /**
         * Encodes the specified Settlement message. Does not implicitly {@link Settlement.verify|verify} messages.
         * @function encode
         * @memberof Settlement
         * @static
         * @param {ISettlement} message Settlement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settlement.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified Settlement message, length delimited. Does not implicitly {@link Settlement.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Settlement
         * @static
         * @param {ISettlement} message Settlement message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settlement.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Settlement message from the specified reader or buffer.
         * @function decode
         * @memberof Settlement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Settlement} Settlement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settlement.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Settlement();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Settlement message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Settlement
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Settlement} Settlement
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settlement.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Settlement message.
         * @function verify
         * @memberof Settlement
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Settlement.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a Settlement message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Settlement
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Settlement} Settlement
         */
        Settlement.fromObject = function fromObject(object) {
            if (object instanceof $root.Settlement)
                return object;
            var message = new $root.Settlement();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a Settlement message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Settlement
         * @static
         * @param {Settlement} message Settlement
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Settlement.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this Settlement to JSON.
         * @function toJSON
         * @memberof Settlement
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Settlement.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Settlement;
    })();
    
    $root.Settlements = (function() {
    
        /**
         * Properties of a Settlements.
         * @exports ISettlements
         * @interface ISettlements
         * @property {number|null} [rowCount] Settlements rowCount
         * @property {Array.<ISettlement>|null} [items] Settlements items
         */
    
        /**
         * Constructs a new Settlements.
         * @exports Settlements
         * @classdesc Represents a Settlements.
         * @implements ISettlements
         * @constructor
         * @param {ISettlements=} [properties] Properties to set
         */
        function Settlements(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Settlements rowCount.
         * @member {number} rowCount
         * @memberof Settlements
         * @instance
         */
        Settlements.prototype.rowCount = 0;
    
        /**
         * Settlements items.
         * @member {Array.<ISettlement>} items
         * @memberof Settlements
         * @instance
         */
        Settlements.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Settlements instance using the specified properties.
         * @function create
         * @memberof Settlements
         * @static
         * @param {ISettlements=} [properties] Properties to set
         * @returns {Settlements} Settlements instance
         */
        Settlements.create = function create(properties) {
            return new Settlements(properties);
        };
    
        /**
         * Encodes the specified Settlements message. Does not implicitly {@link Settlements.verify|verify} messages.
         * @function encode
         * @memberof Settlements
         * @static
         * @param {ISettlements} message Settlements message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settlements.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.Settlement.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Settlements message, length delimited. Does not implicitly {@link Settlements.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Settlements
         * @static
         * @param {ISettlements} message Settlements message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settlements.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Settlements message from the specified reader or buffer.
         * @function decode
         * @memberof Settlements
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Settlements} Settlements
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settlements.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Settlements();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.Settlement.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Settlements message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Settlements
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Settlements} Settlements
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settlements.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Settlements message.
         * @function verify
         * @memberof Settlements
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Settlements.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.Settlement.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Settlements message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Settlements
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Settlements} Settlements
         */
        Settlements.fromObject = function fromObject(object) {
            if (object instanceof $root.Settlements)
                return object;
            var message = new $root.Settlements();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Settlements.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Settlements.items: object expected");
                    message.items[i] = $root.Settlement.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Settlements message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Settlements
         * @static
         * @param {Settlements} message Settlements
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Settlements.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.Settlement.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Settlements to JSON.
         * @function toJSON
         * @memberof Settlements
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Settlements.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Settlements;
    })();
    
    $root.Street = (function() {
    
        /**
         * Properties of a Street.
         * @exports IStreet
         * @interface IStreet
         * @property {Uint8Array|null} [id] Street id
         * @property {string|null} [name] Street name
         */
    
        /**
         * Constructs a new Street.
         * @exports Street
         * @classdesc Represents a Street.
         * @implements IStreet
         * @constructor
         * @param {IStreet=} [properties] Properties to set
         */
        function Street(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Street id.
         * @member {Uint8Array} id
         * @memberof Street
         * @instance
         */
        Street.prototype.id = $util.newBuffer([]);
    
        /**
         * Street name.
         * @member {string} name
         * @memberof Street
         * @instance
         */
        Street.prototype.name = "";
    
        /**
         * Creates a new Street instance using the specified properties.
         * @function create
         * @memberof Street
         * @static
         * @param {IStreet=} [properties] Properties to set
         * @returns {Street} Street instance
         */
        Street.create = function create(properties) {
            return new Street(properties);
        };
    
        /**
         * Encodes the specified Street message. Does not implicitly {@link Street.verify|verify} messages.
         * @function encode
         * @memberof Street
         * @static
         * @param {IStreet} message Street message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Street.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified Street message, length delimited. Does not implicitly {@link Street.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Street
         * @static
         * @param {IStreet} message Street message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Street.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Street message from the specified reader or buffer.
         * @function decode
         * @memberof Street
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Street} Street
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Street.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Street();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Street message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Street
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Street} Street
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Street.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Street message.
         * @function verify
         * @memberof Street
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Street.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a Street message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Street
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Street} Street
         */
        Street.fromObject = function fromObject(object) {
            if (object instanceof $root.Street)
                return object;
            var message = new $root.Street();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a Street message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Street
         * @static
         * @param {Street} message Street
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Street.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this Street to JSON.
         * @function toJSON
         * @memberof Street
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Street.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Street;
    })();
    
    $root.Streets = (function() {
    
        /**
         * Properties of a Streets.
         * @exports IStreets
         * @interface IStreets
         * @property {number|null} [rowCount] Streets rowCount
         * @property {Array.<IStreet>|null} [items] Streets items
         */
    
        /**
         * Constructs a new Streets.
         * @exports Streets
         * @classdesc Represents a Streets.
         * @implements IStreets
         * @constructor
         * @param {IStreets=} [properties] Properties to set
         */
        function Streets(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Streets rowCount.
         * @member {number} rowCount
         * @memberof Streets
         * @instance
         */
        Streets.prototype.rowCount = 0;
    
        /**
         * Streets items.
         * @member {Array.<IStreet>} items
         * @memberof Streets
         * @instance
         */
        Streets.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Streets instance using the specified properties.
         * @function create
         * @memberof Streets
         * @static
         * @param {IStreets=} [properties] Properties to set
         * @returns {Streets} Streets instance
         */
        Streets.create = function create(properties) {
            return new Streets(properties);
        };
    
        /**
         * Encodes the specified Streets message. Does not implicitly {@link Streets.verify|verify} messages.
         * @function encode
         * @memberof Streets
         * @static
         * @param {IStreets} message Streets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Streets.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.Street.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Streets message, length delimited. Does not implicitly {@link Streets.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Streets
         * @static
         * @param {IStreets} message Streets message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Streets.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Streets message from the specified reader or buffer.
         * @function decode
         * @memberof Streets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Streets} Streets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Streets.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Streets();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.Street.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Streets message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Streets
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Streets} Streets
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Streets.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Streets message.
         * @function verify
         * @memberof Streets
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Streets.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.Street.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Streets message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Streets
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Streets} Streets
         */
        Streets.fromObject = function fromObject(object) {
            if (object instanceof $root.Streets)
                return object;
            var message = new $root.Streets();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Streets.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Streets.items: object expected");
                    message.items[i] = $root.Street.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Streets message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Streets
         * @static
         * @param {Streets} message Streets
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Streets.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.Street.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Streets to JSON.
         * @function toJSON
         * @memberof Streets
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Streets.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Streets;
    })();
    
    $root.House = (function() {
    
        /**
         * Properties of an House.
         * @exports IHouse
         * @interface IHouse
         * @property {Uint8Array|null} [id] House id
         * @property {string|null} [number] House number
         */
    
        /**
         * Constructs a new House.
         * @exports House
         * @classdesc Represents an House.
         * @implements IHouse
         * @constructor
         * @param {IHouse=} [properties] Properties to set
         */
        function House(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * House id.
         * @member {Uint8Array} id
         * @memberof House
         * @instance
         */
        House.prototype.id = $util.newBuffer([]);
    
        /**
         * House number.
         * @member {string} number
         * @memberof House
         * @instance
         */
        House.prototype.number = "";
    
        /**
         * Creates a new House instance using the specified properties.
         * @function create
         * @memberof House
         * @static
         * @param {IHouse=} [properties] Properties to set
         * @returns {House} House instance
         */
        House.create = function create(properties) {
            return new House(properties);
        };
    
        /**
         * Encodes the specified House message. Does not implicitly {@link House.verify|verify} messages.
         * @function encode
         * @memberof House
         * @static
         * @param {IHouse} message House message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        House.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.number);
            return writer;
        };
    
        /**
         * Encodes the specified House message, length delimited. Does not implicitly {@link House.verify|verify} messages.
         * @function encodeDelimited
         * @memberof House
         * @static
         * @param {IHouse} message House message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        House.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an House message from the specified reader or buffer.
         * @function decode
         * @memberof House
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {House} House
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        House.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.House();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.number = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an House message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof House
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {House} House
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        House.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an House message.
         * @function verify
         * @memberof House
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        House.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.number != null && message.hasOwnProperty("number"))
                if (!$util.isString(message.number))
                    return "number: string expected";
            return null;
        };
    
        /**
         * Creates an House message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof House
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {House} House
         */
        House.fromObject = function fromObject(object) {
            if (object instanceof $root.House)
                return object;
            var message = new $root.House();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.number != null)
                message.number = String(object.number);
            return message;
        };
    
        /**
         * Creates a plain object from an House message. Also converts values to other types if specified.
         * @function toObject
         * @memberof House
         * @static
         * @param {House} message House
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        House.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.number = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.number != null && message.hasOwnProperty("number"))
                object.number = message.number;
            return object;
        };
    
        /**
         * Converts this House to JSON.
         * @function toJSON
         * @memberof House
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        House.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return House;
    })();
    
    $root.Houses = (function() {
    
        /**
         * Properties of an Houses.
         * @exports IHouses
         * @interface IHouses
         * @property {number|null} [rowCount] Houses rowCount
         * @property {Array.<IHouse>|null} [items] Houses items
         */
    
        /**
         * Constructs a new Houses.
         * @exports Houses
         * @classdesc Represents an Houses.
         * @implements IHouses
         * @constructor
         * @param {IHouses=} [properties] Properties to set
         */
        function Houses(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Houses rowCount.
         * @member {number} rowCount
         * @memberof Houses
         * @instance
         */
        Houses.prototype.rowCount = 0;
    
        /**
         * Houses items.
         * @member {Array.<IHouse>} items
         * @memberof Houses
         * @instance
         */
        Houses.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Houses instance using the specified properties.
         * @function create
         * @memberof Houses
         * @static
         * @param {IHouses=} [properties] Properties to set
         * @returns {Houses} Houses instance
         */
        Houses.create = function create(properties) {
            return new Houses(properties);
        };
    
        /**
         * Encodes the specified Houses message. Does not implicitly {@link Houses.verify|verify} messages.
         * @function encode
         * @memberof Houses
         * @static
         * @param {IHouses} message Houses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Houses.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.House.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Houses message, length delimited. Does not implicitly {@link Houses.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Houses
         * @static
         * @param {IHouses} message Houses message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Houses.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an Houses message from the specified reader or buffer.
         * @function decode
         * @memberof Houses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Houses} Houses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Houses.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Houses();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.House.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an Houses message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Houses
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Houses} Houses
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Houses.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an Houses message.
         * @function verify
         * @memberof Houses
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Houses.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.House.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates an Houses message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Houses
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Houses} Houses
         */
        Houses.fromObject = function fromObject(object) {
            if (object instanceof $root.Houses)
                return object;
            var message = new $root.Houses();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Houses.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Houses.items: object expected");
                    message.items[i] = $root.House.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from an Houses message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Houses
         * @static
         * @param {Houses} message Houses
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Houses.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.House.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Houses to JSON.
         * @function toJSON
         * @memberof Houses
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Houses.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Houses;
    })();
    
    $root.Pharmacy = (function() {
    
        /**
         * Properties of a Pharmacy.
         * @exports IPharmacy
         * @interface IPharmacy
         * @property {Uint8Array|null} [id] Pharmacy id
         * @property {string|null} [name] Pharmacy name
         * @property {Uint8Array|null} [countryId] Pharmacy countryId
         * @property {string|null} [countryName] Pharmacy countryName
         * @property {Uint8Array|null} [regionId] Pharmacy regionId
         * @property {string|null} [regionName] Pharmacy regionName
         * @property {Uint8Array|null} [settlementId] Pharmacy settlementId
         * @property {string|null} [settlementName] Pharmacy settlementName
         * @property {Uint8Array|null} [streetId] Pharmacy streetId
         * @property {string|null} [streetName] Pharmacy streetName
         * @property {Uint8Array|null} [houseId] Pharmacy houseId
         * @property {string|null} [houseNumber] Pharmacy houseNumber
         * @property {Uint8Array|null} [pharmaciesNetworkId] Pharmacy pharmaciesNetworkId
         * @property {string|null} [pharmaciesNetwork] Pharmacy pharmaciesNetwork
         * @property {Uint8Array|null} [districtId] Pharmacy districtId
         * @property {string|null} [districtName] Pharmacy districtName
         */
    
        /**
         * Constructs a new Pharmacy.
         * @exports Pharmacy
         * @classdesc Represents a Pharmacy.
         * @implements IPharmacy
         * @constructor
         * @param {IPharmacy=} [properties] Properties to set
         */
        function Pharmacy(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Pharmacy id.
         * @member {Uint8Array} id
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.id = $util.newBuffer([]);
    
        /**
         * Pharmacy name.
         * @member {string} name
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.name = "";
    
        /**
         * Pharmacy countryId.
         * @member {Uint8Array} countryId
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.countryId = $util.newBuffer([]);
    
        /**
         * Pharmacy countryName.
         * @member {string} countryName
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.countryName = "";
    
        /**
         * Pharmacy regionId.
         * @member {Uint8Array} regionId
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.regionId = $util.newBuffer([]);
    
        /**
         * Pharmacy regionName.
         * @member {string} regionName
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.regionName = "";
    
        /**
         * Pharmacy settlementId.
         * @member {Uint8Array} settlementId
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.settlementId = $util.newBuffer([]);
    
        /**
         * Pharmacy settlementName.
         * @member {string} settlementName
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.settlementName = "";
    
        /**
         * Pharmacy streetId.
         * @member {Uint8Array} streetId
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.streetId = $util.newBuffer([]);
    
        /**
         * Pharmacy streetName.
         * @member {string} streetName
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.streetName = "";
    
        /**
         * Pharmacy houseId.
         * @member {Uint8Array} houseId
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.houseId = $util.newBuffer([]);
    
        /**
         * Pharmacy houseNumber.
         * @member {string} houseNumber
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.houseNumber = "";
    
        /**
         * Pharmacy pharmaciesNetworkId.
         * @member {Uint8Array} pharmaciesNetworkId
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.pharmaciesNetworkId = $util.newBuffer([]);
    
        /**
         * Pharmacy pharmaciesNetwork.
         * @member {string} pharmaciesNetwork
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.pharmaciesNetwork = "";
    
        /**
         * Pharmacy districtId.
         * @member {Uint8Array} districtId
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.districtId = $util.newBuffer([]);
    
        /**
         * Pharmacy districtName.
         * @member {string} districtName
         * @memberof Pharmacy
         * @instance
         */
        Pharmacy.prototype.districtName = "";
    
        /**
         * Creates a new Pharmacy instance using the specified properties.
         * @function create
         * @memberof Pharmacy
         * @static
         * @param {IPharmacy=} [properties] Properties to set
         * @returns {Pharmacy} Pharmacy instance
         */
        Pharmacy.create = function create(properties) {
            return new Pharmacy(properties);
        };
    
        /**
         * Encodes the specified Pharmacy message. Does not implicitly {@link Pharmacy.verify|verify} messages.
         * @function encode
         * @memberof Pharmacy
         * @static
         * @param {IPharmacy} message Pharmacy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pharmacy.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            if (message.countryId != null && Object.hasOwnProperty.call(message, "countryId"))
                writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.countryId);
            if (message.countryName != null && Object.hasOwnProperty.call(message, "countryName"))
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.countryName);
            if (message.regionId != null && Object.hasOwnProperty.call(message, "regionId"))
                writer.uint32(/* id 5, wireType 2 =*/42).bytes(message.regionId);
            if (message.regionName != null && Object.hasOwnProperty.call(message, "regionName"))
                writer.uint32(/* id 6, wireType 2 =*/50).string(message.regionName);
            if (message.settlementId != null && Object.hasOwnProperty.call(message, "settlementId"))
                writer.uint32(/* id 7, wireType 2 =*/58).bytes(message.settlementId);
            if (message.settlementName != null && Object.hasOwnProperty.call(message, "settlementName"))
                writer.uint32(/* id 8, wireType 2 =*/66).string(message.settlementName);
            if (message.streetId != null && Object.hasOwnProperty.call(message, "streetId"))
                writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.streetId);
            if (message.streetName != null && Object.hasOwnProperty.call(message, "streetName"))
                writer.uint32(/* id 10, wireType 2 =*/82).string(message.streetName);
            if (message.houseId != null && Object.hasOwnProperty.call(message, "houseId"))
                writer.uint32(/* id 11, wireType 2 =*/90).bytes(message.houseId);
            if (message.houseNumber != null && Object.hasOwnProperty.call(message, "houseNumber"))
                writer.uint32(/* id 12, wireType 2 =*/98).string(message.houseNumber);
            if (message.pharmaciesNetworkId != null && Object.hasOwnProperty.call(message, "pharmaciesNetworkId"))
                writer.uint32(/* id 13, wireType 2 =*/106).bytes(message.pharmaciesNetworkId);
            if (message.pharmaciesNetwork != null && Object.hasOwnProperty.call(message, "pharmaciesNetwork"))
                writer.uint32(/* id 14, wireType 2 =*/114).string(message.pharmaciesNetwork);
            if (message.districtId != null && Object.hasOwnProperty.call(message, "districtId"))
                writer.uint32(/* id 15, wireType 2 =*/122).bytes(message.districtId);
            if (message.districtName != null && Object.hasOwnProperty.call(message, "districtName"))
                writer.uint32(/* id 16, wireType 2 =*/130).string(message.districtName);
            return writer;
        };
    
        /**
         * Encodes the specified Pharmacy message, length delimited. Does not implicitly {@link Pharmacy.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Pharmacy
         * @static
         * @param {IPharmacy} message Pharmacy message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pharmacy.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Pharmacy message from the specified reader or buffer.
         * @function decode
         * @memberof Pharmacy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Pharmacy} Pharmacy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pharmacy.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Pharmacy();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                case 3:
                    message.countryId = reader.bytes();
                    break;
                case 4:
                    message.countryName = reader.string();
                    break;
                case 5:
                    message.regionId = reader.bytes();
                    break;
                case 6:
                    message.regionName = reader.string();
                    break;
                case 7:
                    message.settlementId = reader.bytes();
                    break;
                case 8:
                    message.settlementName = reader.string();
                    break;
                case 9:
                    message.streetId = reader.bytes();
                    break;
                case 10:
                    message.streetName = reader.string();
                    break;
                case 11:
                    message.houseId = reader.bytes();
                    break;
                case 12:
                    message.houseNumber = reader.string();
                    break;
                case 13:
                    message.pharmaciesNetworkId = reader.bytes();
                    break;
                case 14:
                    message.pharmaciesNetwork = reader.string();
                    break;
                case 15:
                    message.districtId = reader.bytes();
                    break;
                case 16:
                    message.districtName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Pharmacy message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Pharmacy
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Pharmacy} Pharmacy
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pharmacy.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Pharmacy message.
         * @function verify
         * @memberof Pharmacy
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pharmacy.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            if (message.countryId != null && message.hasOwnProperty("countryId"))
                if (!(message.countryId && typeof message.countryId.length === "number" || $util.isString(message.countryId)))
                    return "countryId: buffer expected";
            if (message.countryName != null && message.hasOwnProperty("countryName"))
                if (!$util.isString(message.countryName))
                    return "countryName: string expected";
            if (message.regionId != null && message.hasOwnProperty("regionId"))
                if (!(message.regionId && typeof message.regionId.length === "number" || $util.isString(message.regionId)))
                    return "regionId: buffer expected";
            if (message.regionName != null && message.hasOwnProperty("regionName"))
                if (!$util.isString(message.regionName))
                    return "regionName: string expected";
            if (message.settlementId != null && message.hasOwnProperty("settlementId"))
                if (!(message.settlementId && typeof message.settlementId.length === "number" || $util.isString(message.settlementId)))
                    return "settlementId: buffer expected";
            if (message.settlementName != null && message.hasOwnProperty("settlementName"))
                if (!$util.isString(message.settlementName))
                    return "settlementName: string expected";
            if (message.streetId != null && message.hasOwnProperty("streetId"))
                if (!(message.streetId && typeof message.streetId.length === "number" || $util.isString(message.streetId)))
                    return "streetId: buffer expected";
            if (message.streetName != null && message.hasOwnProperty("streetName"))
                if (!$util.isString(message.streetName))
                    return "streetName: string expected";
            if (message.houseId != null && message.hasOwnProperty("houseId"))
                if (!(message.houseId && typeof message.houseId.length === "number" || $util.isString(message.houseId)))
                    return "houseId: buffer expected";
            if (message.houseNumber != null && message.hasOwnProperty("houseNumber"))
                if (!$util.isString(message.houseNumber))
                    return "houseNumber: string expected";
            if (message.pharmaciesNetworkId != null && message.hasOwnProperty("pharmaciesNetworkId"))
                if (!(message.pharmaciesNetworkId && typeof message.pharmaciesNetworkId.length === "number" || $util.isString(message.pharmaciesNetworkId)))
                    return "pharmaciesNetworkId: buffer expected";
            if (message.pharmaciesNetwork != null && message.hasOwnProperty("pharmaciesNetwork"))
                if (!$util.isString(message.pharmaciesNetwork))
                    return "pharmaciesNetwork: string expected";
            if (message.districtId != null && message.hasOwnProperty("districtId"))
                if (!(message.districtId && typeof message.districtId.length === "number" || $util.isString(message.districtId)))
                    return "districtId: buffer expected";
            if (message.districtName != null && message.hasOwnProperty("districtName"))
                if (!$util.isString(message.districtName))
                    return "districtName: string expected";
            return null;
        };
    
        /**
         * Creates a Pharmacy message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Pharmacy
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Pharmacy} Pharmacy
         */
        Pharmacy.fromObject = function fromObject(object) {
            if (object instanceof $root.Pharmacy)
                return object;
            var message = new $root.Pharmacy();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            if (object.countryId != null)
                if (typeof object.countryId === "string")
                    $util.base64.decode(object.countryId, message.countryId = $util.newBuffer($util.base64.length(object.countryId)), 0);
                else if (object.countryId.length)
                    message.countryId = object.countryId;
            if (object.countryName != null)
                message.countryName = String(object.countryName);
            if (object.regionId != null)
                if (typeof object.regionId === "string")
                    $util.base64.decode(object.regionId, message.regionId = $util.newBuffer($util.base64.length(object.regionId)), 0);
                else if (object.regionId.length)
                    message.regionId = object.regionId;
            if (object.regionName != null)
                message.regionName = String(object.regionName);
            if (object.settlementId != null)
                if (typeof object.settlementId === "string")
                    $util.base64.decode(object.settlementId, message.settlementId = $util.newBuffer($util.base64.length(object.settlementId)), 0);
                else if (object.settlementId.length)
                    message.settlementId = object.settlementId;
            if (object.settlementName != null)
                message.settlementName = String(object.settlementName);
            if (object.streetId != null)
                if (typeof object.streetId === "string")
                    $util.base64.decode(object.streetId, message.streetId = $util.newBuffer($util.base64.length(object.streetId)), 0);
                else if (object.streetId.length)
                    message.streetId = object.streetId;
            if (object.streetName != null)
                message.streetName = String(object.streetName);
            if (object.houseId != null)
                if (typeof object.houseId === "string")
                    $util.base64.decode(object.houseId, message.houseId = $util.newBuffer($util.base64.length(object.houseId)), 0);
                else if (object.houseId.length)
                    message.houseId = object.houseId;
            if (object.houseNumber != null)
                message.houseNumber = String(object.houseNumber);
            if (object.pharmaciesNetworkId != null)
                if (typeof object.pharmaciesNetworkId === "string")
                    $util.base64.decode(object.pharmaciesNetworkId, message.pharmaciesNetworkId = $util.newBuffer($util.base64.length(object.pharmaciesNetworkId)), 0);
                else if (object.pharmaciesNetworkId.length)
                    message.pharmaciesNetworkId = object.pharmaciesNetworkId;
            if (object.pharmaciesNetwork != null)
                message.pharmaciesNetwork = String(object.pharmaciesNetwork);
            if (object.districtId != null)
                if (typeof object.districtId === "string")
                    $util.base64.decode(object.districtId, message.districtId = $util.newBuffer($util.base64.length(object.districtId)), 0);
                else if (object.districtId.length)
                    message.districtId = object.districtId;
            if (object.districtName != null)
                message.districtName = String(object.districtName);
            return message;
        };
    
        /**
         * Creates a plain object from a Pharmacy message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Pharmacy
         * @static
         * @param {Pharmacy} message Pharmacy
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pharmacy.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
                if (options.bytes === String)
                    object.countryId = "";
                else {
                    object.countryId = [];
                    if (options.bytes !== Array)
                        object.countryId = $util.newBuffer(object.countryId);
                }
                object.countryName = "";
                if (options.bytes === String)
                    object.regionId = "";
                else {
                    object.regionId = [];
                    if (options.bytes !== Array)
                        object.regionId = $util.newBuffer(object.regionId);
                }
                object.regionName = "";
                if (options.bytes === String)
                    object.settlementId = "";
                else {
                    object.settlementId = [];
                    if (options.bytes !== Array)
                        object.settlementId = $util.newBuffer(object.settlementId);
                }
                object.settlementName = "";
                if (options.bytes === String)
                    object.streetId = "";
                else {
                    object.streetId = [];
                    if (options.bytes !== Array)
                        object.streetId = $util.newBuffer(object.streetId);
                }
                object.streetName = "";
                if (options.bytes === String)
                    object.houseId = "";
                else {
                    object.houseId = [];
                    if (options.bytes !== Array)
                        object.houseId = $util.newBuffer(object.houseId);
                }
                object.houseNumber = "";
                if (options.bytes === String)
                    object.pharmaciesNetworkId = "";
                else {
                    object.pharmaciesNetworkId = [];
                    if (options.bytes !== Array)
                        object.pharmaciesNetworkId = $util.newBuffer(object.pharmaciesNetworkId);
                }
                object.pharmaciesNetwork = "";
                if (options.bytes === String)
                    object.districtId = "";
                else {
                    object.districtId = [];
                    if (options.bytes !== Array)
                        object.districtId = $util.newBuffer(object.districtId);
                }
                object.districtName = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            if (message.countryId != null && message.hasOwnProperty("countryId"))
                object.countryId = options.bytes === String ? $util.base64.encode(message.countryId, 0, message.countryId.length) : options.bytes === Array ? Array.prototype.slice.call(message.countryId) : message.countryId;
            if (message.countryName != null && message.hasOwnProperty("countryName"))
                object.countryName = message.countryName;
            if (message.regionId != null && message.hasOwnProperty("regionId"))
                object.regionId = options.bytes === String ? $util.base64.encode(message.regionId, 0, message.regionId.length) : options.bytes === Array ? Array.prototype.slice.call(message.regionId) : message.regionId;
            if (message.regionName != null && message.hasOwnProperty("regionName"))
                object.regionName = message.regionName;
            if (message.settlementId != null && message.hasOwnProperty("settlementId"))
                object.settlementId = options.bytes === String ? $util.base64.encode(message.settlementId, 0, message.settlementId.length) : options.bytes === Array ? Array.prototype.slice.call(message.settlementId) : message.settlementId;
            if (message.settlementName != null && message.hasOwnProperty("settlementName"))
                object.settlementName = message.settlementName;
            if (message.streetId != null && message.hasOwnProperty("streetId"))
                object.streetId = options.bytes === String ? $util.base64.encode(message.streetId, 0, message.streetId.length) : options.bytes === Array ? Array.prototype.slice.call(message.streetId) : message.streetId;
            if (message.streetName != null && message.hasOwnProperty("streetName"))
                object.streetName = message.streetName;
            if (message.houseId != null && message.hasOwnProperty("houseId"))
                object.houseId = options.bytes === String ? $util.base64.encode(message.houseId, 0, message.houseId.length) : options.bytes === Array ? Array.prototype.slice.call(message.houseId) : message.houseId;
            if (message.houseNumber != null && message.hasOwnProperty("houseNumber"))
                object.houseNumber = message.houseNumber;
            if (message.pharmaciesNetworkId != null && message.hasOwnProperty("pharmaciesNetworkId"))
                object.pharmaciesNetworkId = options.bytes === String ? $util.base64.encode(message.pharmaciesNetworkId, 0, message.pharmaciesNetworkId.length) : options.bytes === Array ? Array.prototype.slice.call(message.pharmaciesNetworkId) : message.pharmaciesNetworkId;
            if (message.pharmaciesNetwork != null && message.hasOwnProperty("pharmaciesNetwork"))
                object.pharmaciesNetwork = message.pharmaciesNetwork;
            if (message.districtId != null && message.hasOwnProperty("districtId"))
                object.districtId = options.bytes === String ? $util.base64.encode(message.districtId, 0, message.districtId.length) : options.bytes === Array ? Array.prototype.slice.call(message.districtId) : message.districtId;
            if (message.districtName != null && message.hasOwnProperty("districtName"))
                object.districtName = message.districtName;
            return object;
        };
    
        /**
         * Converts this Pharmacy to JSON.
         * @function toJSON
         * @memberof Pharmacy
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pharmacy.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Pharmacy;
    })();
    
    $root.Pharmacies = (function() {
    
        /**
         * Properties of a Pharmacies.
         * @exports IPharmacies
         * @interface IPharmacies
         * @property {number|null} [rowCount] Pharmacies rowCount
         * @property {Array.<IPharmacy>|null} [items] Pharmacies items
         */
    
        /**
         * Constructs a new Pharmacies.
         * @exports Pharmacies
         * @classdesc Represents a Pharmacies.
         * @implements IPharmacies
         * @constructor
         * @param {IPharmacies=} [properties] Properties to set
         */
        function Pharmacies(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * Pharmacies rowCount.
         * @member {number} rowCount
         * @memberof Pharmacies
         * @instance
         */
        Pharmacies.prototype.rowCount = 0;
    
        /**
         * Pharmacies items.
         * @member {Array.<IPharmacy>} items
         * @memberof Pharmacies
         * @instance
         */
        Pharmacies.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new Pharmacies instance using the specified properties.
         * @function create
         * @memberof Pharmacies
         * @static
         * @param {IPharmacies=} [properties] Properties to set
         * @returns {Pharmacies} Pharmacies instance
         */
        Pharmacies.create = function create(properties) {
            return new Pharmacies(properties);
        };
    
        /**
         * Encodes the specified Pharmacies message. Does not implicitly {@link Pharmacies.verify|verify} messages.
         * @function encode
         * @memberof Pharmacies
         * @static
         * @param {IPharmacies} message Pharmacies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pharmacies.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.Pharmacy.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified Pharmacies message, length delimited. Does not implicitly {@link Pharmacies.verify|verify} messages.
         * @function encodeDelimited
         * @memberof Pharmacies
         * @static
         * @param {IPharmacies} message Pharmacies message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Pharmacies.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a Pharmacies message from the specified reader or buffer.
         * @function decode
         * @memberof Pharmacies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {Pharmacies} Pharmacies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pharmacies.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.Pharmacies();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.Pharmacy.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a Pharmacies message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof Pharmacies
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {Pharmacies} Pharmacies
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Pharmacies.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a Pharmacies message.
         * @function verify
         * @memberof Pharmacies
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        Pharmacies.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.Pharmacy.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a Pharmacies message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof Pharmacies
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {Pharmacies} Pharmacies
         */
        Pharmacies.fromObject = function fromObject(object) {
            if (object instanceof $root.Pharmacies)
                return object;
            var message = new $root.Pharmacies();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".Pharmacies.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".Pharmacies.items: object expected");
                    message.items[i] = $root.Pharmacy.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a Pharmacies message. Also converts values to other types if specified.
         * @function toObject
         * @memberof Pharmacies
         * @static
         * @param {Pharmacies} message Pharmacies
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        Pharmacies.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.Pharmacy.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this Pharmacies to JSON.
         * @function toJSON
         * @memberof Pharmacies
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        Pharmacies.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return Pharmacies;
    })();
    
    $root.AddressesExport = (function() {
    
        /**
         * Properties of an AddressesExport.
         * @exports IAddressesExport
         * @interface IAddressesExport
         * @property {Uint8Array|null} [id] AddressesExport id
         */
    
        /**
         * Constructs a new AddressesExport.
         * @exports AddressesExport
         * @classdesc Represents an AddressesExport.
         * @implements IAddressesExport
         * @constructor
         * @param {IAddressesExport=} [properties] Properties to set
         */
        function AddressesExport(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AddressesExport id.
         * @member {Uint8Array} id
         * @memberof AddressesExport
         * @instance
         */
        AddressesExport.prototype.id = $util.newBuffer([]);
    
        /**
         * Creates a new AddressesExport instance using the specified properties.
         * @function create
         * @memberof AddressesExport
         * @static
         * @param {IAddressesExport=} [properties] Properties to set
         * @returns {AddressesExport} AddressesExport instance
         */
        AddressesExport.create = function create(properties) {
            return new AddressesExport(properties);
        };
    
        /**
         * Encodes the specified AddressesExport message. Does not implicitly {@link AddressesExport.verify|verify} messages.
         * @function encode
         * @memberof AddressesExport
         * @static
         * @param {IAddressesExport} message AddressesExport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressesExport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            return writer;
        };
    
        /**
         * Encodes the specified AddressesExport message, length delimited. Does not implicitly {@link AddressesExport.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AddressesExport
         * @static
         * @param {IAddressesExport} message AddressesExport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AddressesExport.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AddressesExport message from the specified reader or buffer.
         * @function decode
         * @memberof AddressesExport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AddressesExport} AddressesExport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressesExport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AddressesExport();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AddressesExport message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AddressesExport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AddressesExport} AddressesExport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AddressesExport.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AddressesExport message.
         * @function verify
         * @memberof AddressesExport
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AddressesExport.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            return null;
        };
    
        /**
         * Creates an AddressesExport message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AddressesExport
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AddressesExport} AddressesExport
         */
        AddressesExport.fromObject = function fromObject(object) {
            if (object instanceof $root.AddressesExport)
                return object;
            var message = new $root.AddressesExport();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            return message;
        };
    
        /**
         * Creates a plain object from an AddressesExport message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AddressesExport
         * @static
         * @param {AddressesExport} message AddressesExport
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AddressesExport.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults)
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            return object;
        };
    
        /**
         * Converts this AddressesExport to JSON.
         * @function toJSON
         * @memberof AddressesExport
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AddressesExport.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AddressesExport;
    })();
    
    $root.ExportResult = (function() {
    
        /**
         * Properties of an ExportResult.
         * @exports IExportResult
         * @interface IExportResult
         * @property {Uint8Array|null} [data] ExportResult data
         * @property {string|null} [fileName] ExportResult fileName
         */
    
        /**
         * Constructs a new ExportResult.
         * @exports ExportResult
         * @classdesc Represents an ExportResult.
         * @implements IExportResult
         * @constructor
         * @param {IExportResult=} [properties] Properties to set
         */
        function ExportResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * ExportResult data.
         * @member {Uint8Array} data
         * @memberof ExportResult
         * @instance
         */
        ExportResult.prototype.data = $util.newBuffer([]);
    
        /**
         * ExportResult fileName.
         * @member {string} fileName
         * @memberof ExportResult
         * @instance
         */
        ExportResult.prototype.fileName = "";
    
        /**
         * Creates a new ExportResult instance using the specified properties.
         * @function create
         * @memberof ExportResult
         * @static
         * @param {IExportResult=} [properties] Properties to set
         * @returns {ExportResult} ExportResult instance
         */
        ExportResult.create = function create(properties) {
            return new ExportResult(properties);
        };
    
        /**
         * Encodes the specified ExportResult message. Does not implicitly {@link ExportResult.verify|verify} messages.
         * @function encode
         * @memberof ExportResult
         * @static
         * @param {IExportResult} message ExportResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.data != null && Object.hasOwnProperty.call(message, "data"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.data);
            if (message.fileName != null && Object.hasOwnProperty.call(message, "fileName"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.fileName);
            return writer;
        };
    
        /**
         * Encodes the specified ExportResult message, length delimited. Does not implicitly {@link ExportResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof ExportResult
         * @static
         * @param {IExportResult} message ExportResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        ExportResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an ExportResult message from the specified reader or buffer.
         * @function decode
         * @memberof ExportResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {ExportResult} ExportResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExportResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.data = reader.bytes();
                    break;
                case 2:
                    message.fileName = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an ExportResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof ExportResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {ExportResult} ExportResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        ExportResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an ExportResult message.
         * @function verify
         * @memberof ExportResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        ExportResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.data != null && message.hasOwnProperty("data"))
                if (!(message.data && typeof message.data.length === "number" || $util.isString(message.data)))
                    return "data: buffer expected";
            if (message.fileName != null && message.hasOwnProperty("fileName"))
                if (!$util.isString(message.fileName))
                    return "fileName: string expected";
            return null;
        };
    
        /**
         * Creates an ExportResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof ExportResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {ExportResult} ExportResult
         */
        ExportResult.fromObject = function fromObject(object) {
            if (object instanceof $root.ExportResult)
                return object;
            var message = new $root.ExportResult();
            if (object.data != null)
                if (typeof object.data === "string")
                    $util.base64.decode(object.data, message.data = $util.newBuffer($util.base64.length(object.data)), 0);
                else if (object.data.length)
                    message.data = object.data;
            if (object.fileName != null)
                message.fileName = String(object.fileName);
            return message;
        };
    
        /**
         * Creates a plain object from an ExportResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof ExportResult
         * @static
         * @param {ExportResult} message ExportResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        ExportResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.data = "";
                else {
                    object.data = [];
                    if (options.bytes !== Array)
                        object.data = $util.newBuffer(object.data);
                }
                object.fileName = "";
            }
            if (message.data != null && message.hasOwnProperty("data"))
                object.data = options.bytes === String ? $util.base64.encode(message.data, 0, message.data.length) : options.bytes === Array ? Array.prototype.slice.call(message.data) : message.data;
            if (message.fileName != null && message.hasOwnProperty("fileName"))
                object.fileName = message.fileName;
            return object;
        };
    
        /**
         * Converts this ExportResult to JSON.
         * @function toJSON
         * @memberof ExportResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        ExportResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return ExportResult;
    })();
    
    $root.PharmaciesNetwork = (function() {
    
        /**
         * Properties of a PharmaciesNetwork.
         * @exports IPharmaciesNetwork
         * @interface IPharmaciesNetwork
         * @property {Uint8Array|null} [id] PharmaciesNetwork id
         * @property {string|null} [name] PharmaciesNetwork name
         */
    
        /**
         * Constructs a new PharmaciesNetwork.
         * @exports PharmaciesNetwork
         * @classdesc Represents a PharmaciesNetwork.
         * @implements IPharmaciesNetwork
         * @constructor
         * @param {IPharmaciesNetwork=} [properties] Properties to set
         */
        function PharmaciesNetwork(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * PharmaciesNetwork id.
         * @member {Uint8Array} id
         * @memberof PharmaciesNetwork
         * @instance
         */
        PharmaciesNetwork.prototype.id = $util.newBuffer([]);
    
        /**
         * PharmaciesNetwork name.
         * @member {string} name
         * @memberof PharmaciesNetwork
         * @instance
         */
        PharmaciesNetwork.prototype.name = "";
    
        /**
         * Creates a new PharmaciesNetwork instance using the specified properties.
         * @function create
         * @memberof PharmaciesNetwork
         * @static
         * @param {IPharmaciesNetwork=} [properties] Properties to set
         * @returns {PharmaciesNetwork} PharmaciesNetwork instance
         */
        PharmaciesNetwork.create = function create(properties) {
            return new PharmaciesNetwork(properties);
        };
    
        /**
         * Encodes the specified PharmaciesNetwork message. Does not implicitly {@link PharmaciesNetwork.verify|verify} messages.
         * @function encode
         * @memberof PharmaciesNetwork
         * @static
         * @param {IPharmaciesNetwork} message PharmaciesNetwork message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PharmaciesNetwork.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified PharmaciesNetwork message, length delimited. Does not implicitly {@link PharmaciesNetwork.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PharmaciesNetwork
         * @static
         * @param {IPharmaciesNetwork} message PharmaciesNetwork message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PharmaciesNetwork.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a PharmaciesNetwork message from the specified reader or buffer.
         * @function decode
         * @memberof PharmaciesNetwork
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PharmaciesNetwork} PharmaciesNetwork
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PharmaciesNetwork.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PharmaciesNetwork();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a PharmaciesNetwork message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PharmaciesNetwork
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PharmaciesNetwork} PharmaciesNetwork
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PharmaciesNetwork.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a PharmaciesNetwork message.
         * @function verify
         * @memberof PharmaciesNetwork
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PharmaciesNetwork.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates a PharmaciesNetwork message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PharmaciesNetwork
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PharmaciesNetwork} PharmaciesNetwork
         */
        PharmaciesNetwork.fromObject = function fromObject(object) {
            if (object instanceof $root.PharmaciesNetwork)
                return object;
            var message = new $root.PharmaciesNetwork();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from a PharmaciesNetwork message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PharmaciesNetwork
         * @static
         * @param {PharmaciesNetwork} message PharmaciesNetwork
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PharmaciesNetwork.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this PharmaciesNetwork to JSON.
         * @function toJSON
         * @memberof PharmaciesNetwork
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PharmaciesNetwork.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return PharmaciesNetwork;
    })();
    
    $root.PharmaciesNetworks = (function() {
    
        /**
         * Properties of a PharmaciesNetworks.
         * @exports IPharmaciesNetworks
         * @interface IPharmaciesNetworks
         * @property {number|null} [rowCount] PharmaciesNetworks rowCount
         * @property {Array.<IPharmaciesNetwork>|null} [items] PharmaciesNetworks items
         */
    
        /**
         * Constructs a new PharmaciesNetworks.
         * @exports PharmaciesNetworks
         * @classdesc Represents a PharmaciesNetworks.
         * @implements IPharmaciesNetworks
         * @constructor
         * @param {IPharmaciesNetworks=} [properties] Properties to set
         */
        function PharmaciesNetworks(properties) {
            this.items = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * PharmaciesNetworks rowCount.
         * @member {number} rowCount
         * @memberof PharmaciesNetworks
         * @instance
         */
        PharmaciesNetworks.prototype.rowCount = 0;
    
        /**
         * PharmaciesNetworks items.
         * @member {Array.<IPharmaciesNetwork>} items
         * @memberof PharmaciesNetworks
         * @instance
         */
        PharmaciesNetworks.prototype.items = $util.emptyArray;
    
        /**
         * Creates a new PharmaciesNetworks instance using the specified properties.
         * @function create
         * @memberof PharmaciesNetworks
         * @static
         * @param {IPharmaciesNetworks=} [properties] Properties to set
         * @returns {PharmaciesNetworks} PharmaciesNetworks instance
         */
        PharmaciesNetworks.create = function create(properties) {
            return new PharmaciesNetworks(properties);
        };
    
        /**
         * Encodes the specified PharmaciesNetworks message. Does not implicitly {@link PharmaciesNetworks.verify|verify} messages.
         * @function encode
         * @memberof PharmaciesNetworks
         * @static
         * @param {IPharmaciesNetworks} message PharmaciesNetworks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PharmaciesNetworks.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.rowCount != null && Object.hasOwnProperty.call(message, "rowCount"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rowCount);
            if (message.items != null && message.items.length)
                for (var i = 0; i < message.items.length; ++i)
                    $root.PharmaciesNetwork.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified PharmaciesNetworks message, length delimited. Does not implicitly {@link PharmaciesNetworks.verify|verify} messages.
         * @function encodeDelimited
         * @memberof PharmaciesNetworks
         * @static
         * @param {IPharmaciesNetworks} message PharmaciesNetworks message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        PharmaciesNetworks.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a PharmaciesNetworks message from the specified reader or buffer.
         * @function decode
         * @memberof PharmaciesNetworks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {PharmaciesNetworks} PharmaciesNetworks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PharmaciesNetworks.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.PharmaciesNetworks();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.rowCount = reader.int32();
                    break;
                case 2:
                    if (!(message.items && message.items.length))
                        message.items = [];
                    message.items.push($root.PharmaciesNetwork.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a PharmaciesNetworks message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof PharmaciesNetworks
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {PharmaciesNetworks} PharmaciesNetworks
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        PharmaciesNetworks.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a PharmaciesNetworks message.
         * @function verify
         * @memberof PharmaciesNetworks
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        PharmaciesNetworks.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                if (!$util.isInteger(message.rowCount))
                    return "rowCount: integer expected";
            if (message.items != null && message.hasOwnProperty("items")) {
                if (!Array.isArray(message.items))
                    return "items: array expected";
                for (var i = 0; i < message.items.length; ++i) {
                    var error = $root.PharmaciesNetwork.verify(message.items[i]);
                    if (error)
                        return "items." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates a PharmaciesNetworks message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof PharmaciesNetworks
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {PharmaciesNetworks} PharmaciesNetworks
         */
        PharmaciesNetworks.fromObject = function fromObject(object) {
            if (object instanceof $root.PharmaciesNetworks)
                return object;
            var message = new $root.PharmaciesNetworks();
            if (object.rowCount != null)
                message.rowCount = object.rowCount | 0;
            if (object.items) {
                if (!Array.isArray(object.items))
                    throw TypeError(".PharmaciesNetworks.items: array expected");
                message.items = [];
                for (var i = 0; i < object.items.length; ++i) {
                    if (typeof object.items[i] !== "object")
                        throw TypeError(".PharmaciesNetworks.items: object expected");
                    message.items[i] = $root.PharmaciesNetwork.fromObject(object.items[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from a PharmaciesNetworks message. Also converts values to other types if specified.
         * @function toObject
         * @memberof PharmaciesNetworks
         * @static
         * @param {PharmaciesNetworks} message PharmaciesNetworks
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        PharmaciesNetworks.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.items = [];
            if (options.defaults)
                object.rowCount = 0;
            if (message.rowCount != null && message.hasOwnProperty("rowCount"))
                object.rowCount = message.rowCount;
            if (message.items && message.items.length) {
                object.items = [];
                for (var j = 0; j < message.items.length; ++j)
                    object.items[j] = $root.PharmaciesNetwork.toObject(message.items[j], options);
            }
            return object;
        };
    
        /**
         * Converts this PharmaciesNetworks to JSON.
         * @function toJSON
         * @memberof PharmaciesNetworks
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        PharmaciesNetworks.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return PharmaciesNetworks;
    })();
    
    $root.WorkInfo = (function() {
    
        /**
         * Properties of a WorkInfo.
         * @exports IWorkInfo
         * @interface IWorkInfo
         * @property {number|null} [progress] WorkInfo progress
         * @property {string|null} [description] WorkInfo description
         * @property {number|Long|null} [finishedTime] WorkInfo finishedTime
         * @property {EWorkStatus|null} [status] WorkInfo status
         * @property {string|null} [result] WorkInfo result
         */
    
        /**
         * Constructs a new WorkInfo.
         * @exports WorkInfo
         * @classdesc Represents a WorkInfo.
         * @implements IWorkInfo
         * @constructor
         * @param {IWorkInfo=} [properties] Properties to set
         */
        function WorkInfo(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * WorkInfo progress.
         * @member {number} progress
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.progress = 0;
    
        /**
         * WorkInfo description.
         * @member {string} description
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.description = "";
    
        /**
         * WorkInfo finishedTime.
         * @member {number|Long} finishedTime
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.finishedTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
    
        /**
         * WorkInfo status.
         * @member {EWorkStatus} status
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.status = 0;
    
        /**
         * WorkInfo result.
         * @member {string} result
         * @memberof WorkInfo
         * @instance
         */
        WorkInfo.prototype.result = "";
    
        /**
         * Creates a new WorkInfo instance using the specified properties.
         * @function create
         * @memberof WorkInfo
         * @static
         * @param {IWorkInfo=} [properties] Properties to set
         * @returns {WorkInfo} WorkInfo instance
         */
        WorkInfo.create = function create(properties) {
            return new WorkInfo(properties);
        };
    
        /**
         * Encodes the specified WorkInfo message. Does not implicitly {@link WorkInfo.verify|verify} messages.
         * @function encode
         * @memberof WorkInfo
         * @static
         * @param {IWorkInfo} message WorkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkInfo.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.progress != null && Object.hasOwnProperty.call(message, "progress"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.progress);
            if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
            if (message.finishedTime != null && Object.hasOwnProperty.call(message, "finishedTime"))
                writer.uint32(/* id 3, wireType 1 =*/25).sfixed64(message.finishedTime);
            if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.status);
            if (message.result != null && Object.hasOwnProperty.call(message, "result"))
                writer.uint32(/* id 5, wireType 2 =*/42).string(message.result);
            return writer;
        };
    
        /**
         * Encodes the specified WorkInfo message, length delimited. Does not implicitly {@link WorkInfo.verify|verify} messages.
         * @function encodeDelimited
         * @memberof WorkInfo
         * @static
         * @param {IWorkInfo} message WorkInfo message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        WorkInfo.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes a WorkInfo message from the specified reader or buffer.
         * @function decode
         * @memberof WorkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {WorkInfo} WorkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkInfo.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.WorkInfo();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.progress = reader.int32();
                    break;
                case 2:
                    message.description = reader.string();
                    break;
                case 3:
                    message.finishedTime = reader.sfixed64();
                    break;
                case 4:
                    message.status = reader.int32();
                    break;
                case 5:
                    message.result = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes a WorkInfo message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof WorkInfo
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {WorkInfo} WorkInfo
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        WorkInfo.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies a WorkInfo message.
         * @function verify
         * @memberof WorkInfo
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        WorkInfo.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.progress != null && message.hasOwnProperty("progress"))
                if (!$util.isInteger(message.progress))
                    return "progress: integer expected";
            if (message.description != null && message.hasOwnProperty("description"))
                if (!$util.isString(message.description))
                    return "description: string expected";
            if (message.finishedTime != null && message.hasOwnProperty("finishedTime"))
                if (!$util.isInteger(message.finishedTime) && !(message.finishedTime && $util.isInteger(message.finishedTime.low) && $util.isInteger(message.finishedTime.high)))
                    return "finishedTime: integer|Long expected";
            if (message.status != null && message.hasOwnProperty("status"))
                switch (message.status) {
                default:
                    return "status: enum value expected";
                case 0:
                case 10:
                case 20:
                case 30:
                case 40:
                    break;
                }
            if (message.result != null && message.hasOwnProperty("result"))
                if (!$util.isString(message.result))
                    return "result: string expected";
            return null;
        };
    
        /**
         * Creates a WorkInfo message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof WorkInfo
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {WorkInfo} WorkInfo
         */
        WorkInfo.fromObject = function fromObject(object) {
            if (object instanceof $root.WorkInfo)
                return object;
            var message = new $root.WorkInfo();
            if (object.progress != null)
                message.progress = object.progress | 0;
            if (object.description != null)
                message.description = String(object.description);
            if (object.finishedTime != null)
                if ($util.Long)
                    (message.finishedTime = $util.Long.fromValue(object.finishedTime)).unsigned = false;
                else if (typeof object.finishedTime === "string")
                    message.finishedTime = parseInt(object.finishedTime, 10);
                else if (typeof object.finishedTime === "number")
                    message.finishedTime = object.finishedTime;
                else if (typeof object.finishedTime === "object")
                    message.finishedTime = new $util.LongBits(object.finishedTime.low >>> 0, object.finishedTime.high >>> 0).toNumber();
            switch (object.status) {
            case "NotRunned":
            case 0:
                message.status = 0;
                break;
            case "Queue":
            case 10:
                message.status = 10;
                break;
            case "InProgress":
            case 20:
                message.status = 20;
                break;
            case "Error":
            case 30:
                message.status = 30;
                break;
            case "Done":
            case 40:
                message.status = 40;
                break;
            }
            if (object.result != null)
                message.result = String(object.result);
            return message;
        };
    
        /**
         * Creates a plain object from a WorkInfo message. Also converts values to other types if specified.
         * @function toObject
         * @memberof WorkInfo
         * @static
         * @param {WorkInfo} message WorkInfo
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        WorkInfo.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                object.progress = 0;
                object.description = "";
                if ($util.Long) {
                    var long = new $util.Long(0, 0, false);
                    object.finishedTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                } else
                    object.finishedTime = options.longs === String ? "0" : 0;
                object.status = options.enums === String ? "NotRunned" : 0;
                object.result = "";
            }
            if (message.progress != null && message.hasOwnProperty("progress"))
                object.progress = message.progress;
            if (message.description != null && message.hasOwnProperty("description"))
                object.description = message.description;
            if (message.finishedTime != null && message.hasOwnProperty("finishedTime"))
                if (typeof message.finishedTime === "number")
                    object.finishedTime = options.longs === String ? String(message.finishedTime) : message.finishedTime;
                else
                    object.finishedTime = options.longs === String ? $util.Long.prototype.toString.call(message.finishedTime) : options.longs === Number ? new $util.LongBits(message.finishedTime.low >>> 0, message.finishedTime.high >>> 0).toNumber() : message.finishedTime;
            if (message.status != null && message.hasOwnProperty("status"))
                object.status = options.enums === String ? $root.EWorkStatus[message.status] : message.status;
            if (message.result != null && message.hasOwnProperty("result"))
                object.result = message.result;
            return object;
        };
    
        /**
         * Converts this WorkInfo to JSON.
         * @function toJSON
         * @memberof WorkInfo
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        WorkInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return WorkInfo;
    })();
    
    $root.AutocompleteSearchResult = (function() {
    
        /**
         * Properties of an AutocompleteSearchResult.
         * @exports IAutocompleteSearchResult
         * @interface IAutocompleteSearchResult
         * @property {Uint8Array|null} [id] AutocompleteSearchResult id
         * @property {string|null} [name] AutocompleteSearchResult name
         */
    
        /**
         * Constructs a new AutocompleteSearchResult.
         * @exports AutocompleteSearchResult
         * @classdesc Represents an AutocompleteSearchResult.
         * @implements IAutocompleteSearchResult
         * @constructor
         * @param {IAutocompleteSearchResult=} [properties] Properties to set
         */
        function AutocompleteSearchResult(properties) {
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AutocompleteSearchResult id.
         * @member {Uint8Array} id
         * @memberof AutocompleteSearchResult
         * @instance
         */
        AutocompleteSearchResult.prototype.id = $util.newBuffer([]);
    
        /**
         * AutocompleteSearchResult name.
         * @member {string} name
         * @memberof AutocompleteSearchResult
         * @instance
         */
        AutocompleteSearchResult.prototype.name = "";
    
        /**
         * Creates a new AutocompleteSearchResult instance using the specified properties.
         * @function create
         * @memberof AutocompleteSearchResult
         * @static
         * @param {IAutocompleteSearchResult=} [properties] Properties to set
         * @returns {AutocompleteSearchResult} AutocompleteSearchResult instance
         */
        AutocompleteSearchResult.create = function create(properties) {
            return new AutocompleteSearchResult(properties);
        };
    
        /**
         * Encodes the specified AutocompleteSearchResult message. Does not implicitly {@link AutocompleteSearchResult.verify|verify} messages.
         * @function encode
         * @memberof AutocompleteSearchResult
         * @static
         * @param {IAutocompleteSearchResult} message AutocompleteSearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AutocompleteSearchResult.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.id);
            if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
            return writer;
        };
    
        /**
         * Encodes the specified AutocompleteSearchResult message, length delimited. Does not implicitly {@link AutocompleteSearchResult.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AutocompleteSearchResult
         * @static
         * @param {IAutocompleteSearchResult} message AutocompleteSearchResult message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AutocompleteSearchResult.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AutocompleteSearchResult message from the specified reader or buffer.
         * @function decode
         * @memberof AutocompleteSearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AutocompleteSearchResult} AutocompleteSearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AutocompleteSearchResult.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AutocompleteSearchResult();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.id = reader.bytes();
                    break;
                case 2:
                    message.name = reader.string();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AutocompleteSearchResult message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AutocompleteSearchResult
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AutocompleteSearchResult} AutocompleteSearchResult
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AutocompleteSearchResult.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AutocompleteSearchResult message.
         * @function verify
         * @memberof AutocompleteSearchResult
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AutocompleteSearchResult.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.id != null && message.hasOwnProperty("id"))
                if (!(message.id && typeof message.id.length === "number" || $util.isString(message.id)))
                    return "id: buffer expected";
            if (message.name != null && message.hasOwnProperty("name"))
                if (!$util.isString(message.name))
                    return "name: string expected";
            return null;
        };
    
        /**
         * Creates an AutocompleteSearchResult message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AutocompleteSearchResult
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AutocompleteSearchResult} AutocompleteSearchResult
         */
        AutocompleteSearchResult.fromObject = function fromObject(object) {
            if (object instanceof $root.AutocompleteSearchResult)
                return object;
            var message = new $root.AutocompleteSearchResult();
            if (object.id != null)
                if (typeof object.id === "string")
                    $util.base64.decode(object.id, message.id = $util.newBuffer($util.base64.length(object.id)), 0);
                else if (object.id.length)
                    message.id = object.id;
            if (object.name != null)
                message.name = String(object.name);
            return message;
        };
    
        /**
         * Creates a plain object from an AutocompleteSearchResult message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AutocompleteSearchResult
         * @static
         * @param {AutocompleteSearchResult} message AutocompleteSearchResult
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AutocompleteSearchResult.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.defaults) {
                if (options.bytes === String)
                    object.id = "";
                else {
                    object.id = [];
                    if (options.bytes !== Array)
                        object.id = $util.newBuffer(object.id);
                }
                object.name = "";
            }
            if (message.id != null && message.hasOwnProperty("id"))
                object.id = options.bytes === String ? $util.base64.encode(message.id, 0, message.id.length) : options.bytes === Array ? Array.prototype.slice.call(message.id) : message.id;
            if (message.name != null && message.hasOwnProperty("name"))
                object.name = message.name;
            return object;
        };
    
        /**
         * Converts this AutocompleteSearchResult to JSON.
         * @function toJSON
         * @memberof AutocompleteSearchResult
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AutocompleteSearchResult.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AutocompleteSearchResult;
    })();
    
    $root.AutocompleteSearchResults = (function() {
    
        /**
         * Properties of an AutocompleteSearchResults.
         * @exports IAutocompleteSearchResults
         * @interface IAutocompleteSearchResults
         * @property {Array.<IAutocompleteSearchResult>|null} [SearchResults] AutocompleteSearchResults SearchResults
         */
    
        /**
         * Constructs a new AutocompleteSearchResults.
         * @exports AutocompleteSearchResults
         * @classdesc Represents an AutocompleteSearchResults.
         * @implements IAutocompleteSearchResults
         * @constructor
         * @param {IAutocompleteSearchResults=} [properties] Properties to set
         */
        function AutocompleteSearchResults(properties) {
            this.SearchResults = [];
            if (properties)
                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }
    
        /**
         * AutocompleteSearchResults SearchResults.
         * @member {Array.<IAutocompleteSearchResult>} SearchResults
         * @memberof AutocompleteSearchResults
         * @instance
         */
        AutocompleteSearchResults.prototype.SearchResults = $util.emptyArray;
    
        /**
         * Creates a new AutocompleteSearchResults instance using the specified properties.
         * @function create
         * @memberof AutocompleteSearchResults
         * @static
         * @param {IAutocompleteSearchResults=} [properties] Properties to set
         * @returns {AutocompleteSearchResults} AutocompleteSearchResults instance
         */
        AutocompleteSearchResults.create = function create(properties) {
            return new AutocompleteSearchResults(properties);
        };
    
        /**
         * Encodes the specified AutocompleteSearchResults message. Does not implicitly {@link AutocompleteSearchResults.verify|verify} messages.
         * @function encode
         * @memberof AutocompleteSearchResults
         * @static
         * @param {IAutocompleteSearchResults} message AutocompleteSearchResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AutocompleteSearchResults.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.SearchResults != null && message.SearchResults.length)
                for (var i = 0; i < message.SearchResults.length; ++i)
                    $root.AutocompleteSearchResult.encode(message.SearchResults[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
            return writer;
        };
    
        /**
         * Encodes the specified AutocompleteSearchResults message, length delimited. Does not implicitly {@link AutocompleteSearchResults.verify|verify} messages.
         * @function encodeDelimited
         * @memberof AutocompleteSearchResults
         * @static
         * @param {IAutocompleteSearchResults} message AutocompleteSearchResults message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        AutocompleteSearchResults.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };
    
        /**
         * Decodes an AutocompleteSearchResults message from the specified reader or buffer.
         * @function decode
         * @memberof AutocompleteSearchResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {AutocompleteSearchResults} AutocompleteSearchResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AutocompleteSearchResults.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AutocompleteSearchResults();
            while (reader.pos < end) {
                var tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    if (!(message.SearchResults && message.SearchResults.length))
                        message.SearchResults = [];
                    message.SearchResults.push($root.AutocompleteSearchResult.decode(reader, reader.uint32()));
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };
    
        /**
         * Decodes an AutocompleteSearchResults message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof AutocompleteSearchResults
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {AutocompleteSearchResults} AutocompleteSearchResults
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        AutocompleteSearchResults.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };
    
        /**
         * Verifies an AutocompleteSearchResults message.
         * @function verify
         * @memberof AutocompleteSearchResults
         * @static
         * @param {Object.<string,*>} message Plain object to verify
         * @returns {string|null} `null` if valid, otherwise the reason why it is not
         */
        AutocompleteSearchResults.verify = function verify(message) {
            if (typeof message !== "object" || message === null)
                return "object expected";
            if (message.SearchResults != null && message.hasOwnProperty("SearchResults")) {
                if (!Array.isArray(message.SearchResults))
                    return "SearchResults: array expected";
                for (var i = 0; i < message.SearchResults.length; ++i) {
                    var error = $root.AutocompleteSearchResult.verify(message.SearchResults[i]);
                    if (error)
                        return "SearchResults." + error;
                }
            }
            return null;
        };
    
        /**
         * Creates an AutocompleteSearchResults message from a plain object. Also converts values to their respective internal types.
         * @function fromObject
         * @memberof AutocompleteSearchResults
         * @static
         * @param {Object.<string,*>} object Plain object
         * @returns {AutocompleteSearchResults} AutocompleteSearchResults
         */
        AutocompleteSearchResults.fromObject = function fromObject(object) {
            if (object instanceof $root.AutocompleteSearchResults)
                return object;
            var message = new $root.AutocompleteSearchResults();
            if (object.SearchResults) {
                if (!Array.isArray(object.SearchResults))
                    throw TypeError(".AutocompleteSearchResults.SearchResults: array expected");
                message.SearchResults = [];
                for (var i = 0; i < object.SearchResults.length; ++i) {
                    if (typeof object.SearchResults[i] !== "object")
                        throw TypeError(".AutocompleteSearchResults.SearchResults: object expected");
                    message.SearchResults[i] = $root.AutocompleteSearchResult.fromObject(object.SearchResults[i]);
                }
            }
            return message;
        };
    
        /**
         * Creates a plain object from an AutocompleteSearchResults message. Also converts values to other types if specified.
         * @function toObject
         * @memberof AutocompleteSearchResults
         * @static
         * @param {AutocompleteSearchResults} message AutocompleteSearchResults
         * @param {$protobuf.IConversionOptions} [options] Conversion options
         * @returns {Object.<string,*>} Plain object
         */
        AutocompleteSearchResults.toObject = function toObject(message, options) {
            if (!options)
                options = {};
            var object = {};
            if (options.arrays || options.defaults)
                object.SearchResults = [];
            if (message.SearchResults && message.SearchResults.length) {
                object.SearchResults = [];
                for (var j = 0; j < message.SearchResults.length; ++j)
                    object.SearchResults[j] = $root.AutocompleteSearchResult.toObject(message.SearchResults[j], options);
            }
            return object;
        };
    
        /**
         * Converts this AutocompleteSearchResults to JSON.
         * @function toJSON
         * @memberof AutocompleteSearchResults
         * @instance
         * @returns {Object.<string,*>} JSON object
         */
        AutocompleteSearchResults.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };
    
        return AutocompleteSearchResults;
    })();
    
    /**
     * EWorkStatus enum.
     * @exports EWorkStatus
     * @enum {number}
     * @property {number} NotRunned=0 NotRunned value
     * @property {number} Queue=10 Queue value
     * @property {number} InProgress=20 InProgress value
     * @property {number} Error=30 Error value
     * @property {number} Done=40 Done value
     */
    $root.EWorkStatus = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[0] = "NotRunned"] = 0;
        values[valuesById[10] = "Queue"] = 10;
        values[valuesById[20] = "InProgress"] = 20;
        values[valuesById[30] = "Error"] = 30;
        values[valuesById[40] = "Done"] = 40;
        return values;
    })();
    
    /**
     * EAddressPart enum.
     * @exports EAddressPart
     * @enum {number}
     * @property {number} Country=1 Country value
     * @property {number} Region=2 Region value
     * @property {number} Settlement=3 Settlement value
     * @property {number} District=4 District value
     * @property {number} Street=5 Street value
     * @property {number} House=6 House value
     */
    $root.EAddressPart = (function() {
        var valuesById = {}, values = Object.create(valuesById);
        values[valuesById[1] = "Country"] = 1;
        values[valuesById[2] = "Region"] = 2;
        values[valuesById[3] = "Settlement"] = 3;
        values[valuesById[4] = "District"] = 4;
        values[valuesById[5] = "Street"] = 5;
        values[valuesById[6] = "House"] = 6;
        return values;
    })();

    return $root;
});
